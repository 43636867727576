import React from "react";
import { any, array } from "prop-types";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import pdfLogo from "../../../assets/pdf-logo.svg";

const CheckboxTitle = ({ checked, value, onClick }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton role={undefined} onClick={onClick(value)} dense>
        <ListItemIcon>
          <Checkbox
            size="small"
            checked={checked.indexOf(value) !== -1}
            tabIndex={-1}
          />
        </ListItemIcon>
        <IconButton>
          <img style={{ width: "15px" }} src={pdfLogo} />
        </IconButton>
        <ListItemText
          style={{ marginLeft: "1px" }}
          size="small"
          primary={value.name}
        />
      </ListItemButton>
    </ListItem>
  );
};

CheckboxTitle.propTypes = {
  checked: array,
  value: any,
  onClick: any,
};

export default CheckboxTitle;
