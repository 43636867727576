import React, { useEffect } from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./searchBar.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateType } from "../../../redux/pre_processor/actions";
import { MenuItem } from "@mui/material";
import { action } from "../../../constants/action";
export default function SearchBar({ onSearch }) {
  const dispatch = useDispatch();
  const type = useSelector((state) => state?.prePro?.type);

  useEffect(() => {
    dispatch(updateType("project"));
  }, []);

  useEffect(() => {
    document.getElementById("searchInput").value = "";
  }, [type]);

  // const [type, setType] = React.useState('project');
  const handleChange = (event) => {
    // setType(event.target.value);
    dispatch(updateType(event.target.value));
  };
  return (
    <div className="search-container">
      <div>
        <FormControl>
          <Select
            className="input-select"
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={type}
            onChange={handleChange}
            autoWidth
          >
            <MenuItem value="project" selected={true}>
              {action.PROJECT}
            </MenuItem>
            <MenuItem value="batch">{action.BATCH}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="search">
        <div className="search-wrapper">
          <SearchIcon />
        </div>
        <InputBase
          id="searchInput"
          className="input-style"
          placeholder={action.SEARCH}
          inputProps={{ "aria-label": "search" }}
          onChange={(event) => onSearch(event.target.value, type)}
        />
      </div>
    </div>
  );
}
