import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import pdfIcon from "../../assets/Pdf.svg";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import "./pdfCard.scss";
import { bindActionCreators } from "redux";
import * as acts from "../../redux/pre_processor/actions";
import { connect } from "react-redux";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

class PdfCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tagLabel: "",
      tagName: "",
      fileContent: [],
      expanded: false,
      isDataMapped: props.isDataMapped,
      invalidValidationErrorMessage: "",
      fieldColor: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      tagName: this.props.file.tagName ? this.props.file.tagName : "",
    });
  }

  handleExpandClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };
  handleCheckOrEnter = () => {
    if (/[^a-zA-Z0-9\_]/.test(this.state.tagName)) {
      this.setState({
        invalidValidationErrorMessage: "SpecialCharater not allowed",
      });
    }

    let fileNameWithTag = {
      fileName: this.props.file.fileName,
      tagName:
        this.state.tagName.charAt(0).toUpperCase() +
        this.state.tagName.slice(1),
    };

    this.props.addTagName(fileNameWithTag);
  };
  handleTagNameChange = (e) => {
    this.setState({
      tagName: e.target.value,
      invalidValidationErrorMessage: "",
    });
    this.props.handleTagNameChange()
  };
  handleDeletePdf = () => {
    this.props.onRemovePdfAndTag(this.props.file.fileName);
    // this.setState({ tagName: null });
  };
  handleDeleteFile = () => {
    let file = this.props.file.fileName;
    this.props.onClickDeleteFile(file);
    this.props.acts.deletePdf(
      localStorage.batchId,
      this.setState({ tagName: null })
    );
    this.setState({ tagName: null });
  };

  render() {
    return (
      <Card fullWidth className="outerPDf-wrapper">
        <CardHeader
          className="Pdf-headerName"
          avatar={<img src={pdfIcon} alt="pdf"></img>}
          subheader={this.props.file.fileName}
          action={
            <IconButton
              disabled={(localStorage.mapData === "true") && (localStorage.isCloned === "false") ? true : false}
              onClick={(e) => this.handleDeletePdf()}
            >
              <CloseIcon
                className="Pdf-close"
              // onDelete={this.handleDelete}
              />
            </IconButton>
          }
        />
        <CardContent className="card-wrapper">
          <Box className="wrapper">
            <TextField
              className="pdf-textfield"
              disabled={
                this.props.file.isChecked || ((localStorage?.mapData === "true") && (localStorage?.isCloned === "false"))
                  ? true
                  : false
              }
              size="small"
              // error={this.state.invalidValidationErrorMessage}
              // helperText={this.state.invalidValidationErrorMessage}
              sx={{
                background: "this.state.fieldColor",
              }}
              onChange={this.handleTagNameChange}
              // onBlur={this.handleOnBlur}
              value={
                this.state.tagName.charAt(0).toUpperCase() +
                this.state.tagName.slice(1)
              }
              onKeyPress={(e) => e.key === "Enter" && this.handleCheckOrEnter()}
              placeholder="Tag Name"
            />
            {this.props.file.isChecked ? (
              <IconButton
                className="pdfCard-icon"
                disabled={(localStorage.mapData === "true") && (localStorage.isCloned === "false") ? true : false}
                onClick={(e) => {
                  this.props?.handleEditTagName(this.props.file.fileName);
                  this.setState({
                    fieldColor: "#fff",
                  });
                }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <IconButton
                disabled={(localStorage.mapData === "true") && (localStorage.isCloned === "false") ? true : false}
                className="pdfCard-icon"
                onClick={(e) => {
                  this.handleCheckOrEnter(e, true);
                  this.setState({
                    fieldColor: "#ECECEC",
                  });
                }}
              >
                <CheckBoxIcon />
              </IconButton>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    acts: bindActionCreators(acts, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PdfCard);
