import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  InputBase,
  Button,
} from "@mui/material";
import Tables from "../../../components/Table/Table";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as acts from "../../../redux/administrator/actions";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import "./OutPutFormat.scss";
import TablePagination from "@mui/material/TablePagination";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import DateRangePicker from "../../../components/DateRangePicker";
import moment from "moment";
import SearchBar from "../../../components/Controls/searchBar/SearchBar";
import SearchIcon from "@mui/icons-material/Search";
import { action } from "../../../constants/action";
import TableSkeleton from "../../../components/Skeletons/TableSkeleton";
import PostProcessingModal from "../../../components/Modal/PostProcessing/clonModal/PostProcessing";
import ProgressBar from "../../../components/ProgressBar";
const Index = () => {
  const listData = useSelector((state) => state?.admin?.outPutData);
  const Dispatch = useDispatch();
  const history = useHistory();
  const [pageCount, setPageCount] = useState(listData?.total_count || 0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [outputData, setOutputData] = useState([]);
  const [page, setPage] = useState(0);
  const apiStatus = useSelector((state) => state?.admin?.apiStatus);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(false);
  const [input, setInput] = useState("");
  const [fileType, setFileType] = useState("");
  const [status, setStatus] = useState("");
  const [sDate, setSDate] = useState("");
  const [eDate, setEDate] = useState("");
  const [loader,setLoader] = useState(false)
  const [success, setSuccess] = useState(false)
  //const [postProcessingModal, setPostProcessingModal] = useState(false)
  const visualizationTableName = useSelector((state) => state?.admin?.visualizationTableName)
  const [folderName, setFolderName] = useState(visualizationTableName?.data)
  useEffect(() => {
    const data = {
      page: page + 1,
      page_size: rowsPerPage,
      fileType,
      status,
      formattedStartDate: sDate,
      formattedEndDate: eDate,
    };
    Dispatch(acts.getOutPutList(data));
    setLoader(true)
  }, [Dispatch, rowsPerPage]);
  useEffect(() => {
    Dispatch(acts.getVisualizationTableName())
    setLoader(true)
  }, [])
  // useEffect(() => {
  //   if (visualizationTableName?.length > 0) {
  //     setFolderName(visualizationTableName.data)
  //   }

  // }, [visualizationTableName])

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.apiSuccess === true && apiStatus?.next === true){
        setMessage(apiStatus?.message)
        setLoader(false)
        setSuccess(true);
       
        
      }
      else if (apiStatus?.apiError === true && apiStatus?.next === false) {
        setMessage(apiStatus?.message);
        setErr(true);
        setLoader(false)
      }
    }
  }, [apiStatus]);

  useEffect(() => {
    if (listData?.data?.length > 0) {
      setOutputData(listData?.data);
      setLoader(false)
    }
  }, [listData?.data]);

  const handlePageChange = (event, value) => {
    setPage(value);
    Dispatch(
      acts.getOutPutList({
        page: value + 1,
        page_size: rowsPerPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setPageCount(listData?.total_count);
  };

  const newScreenNavigatin = () => {
    Dispatch(acts.getDpDetails());
    history.push("/data-point-details");
  };
  const filterByDate = (startDate, endDate) => {
    const formattedStartDate =
      startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
    const formattedEndDate =
      endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";
    setSDate(formattedStartDate);
    setEDate(formattedEndDate);
    const data = {
      page: page + 1,
      page_size: rowsPerPage,
      fileType,
      status,
      formattedStartDate: formattedStartDate,
      formattedEndDate: formattedEndDate,
    };
    Dispatch(acts.getOutPutList(data));
  };
  const clear = () => {
    // setFileType("")
    // setStatus("")
    // Dispatch(
    //   acts.getDeletedData({
    //     formattedStartDate: "",
    //     formattedEndDate: "",
    //   })
    // );
  };
  const handleFileType = (event) => {
    setFileType(event.target.value);
  };
  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const onSearch = (word, type) => {
    let result;
    let filteredProjects = [];

    if (type === "project") {
      if (word) {
        result = listData?.data?.filter(function (el) {
          return (
            el?.project_name
              .toString()
              .toLowerCase()
              .indexOf(word.toLowerCase()) !== -1
          );
        });
      } else {
        result = listData?.data;
      }
    } else {
      if (word) {
        // const res = listData?.data?.map((item, index) => {
        const filteredBatches = listData?.data?.filter(function (el) {
          return (
            el?.batch_name
              .toString()
              .toLowerCase()
              .indexOf(word.toLowerCase()) !== -1
          );
        });
        result = filteredBatches;
      } else {
        result = listData?.data;
      }
    }
    setOutputData(result);
  };


  return (
    <>
      <Header />
      <Box className="manage-output-files">
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid className="manage-output-conatiner" item md={9} sm={8}>
            <AdministratorHeader
              text="Manage Output Files"
              outPutButton={"Data Point Details"}
              Click={newScreenNavigatin}
            />
            <Grid container className="input-wrap-container">
              <Grid item md={3}>
                <SearchBar onSearch={(value, type) => onSearch(value, type)} />
              </Grid>
              <Grid item md={2}>
                <FormControl fullWidth>
                  <InputLabel
                    className="multiplecontrolsLabel"
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name-label"
                  >
                    File Type
                  </InputLabel>
                  <Select
                    size="small"
                    sx={{ border: "none" }}
                    label="File Type"
                    value={fileType}
                    onChange={handleFileType}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="xlsx">xlsx</MenuItem>
                    <MenuItem value="pdf">Pdf</MenuItem>
                    <MenuItem value="csv">csv</MenuItem>
                    <MenuItem value="json">json</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2} className="input-wrap-container-select">
                <FormControl fullWidth>
                  <InputLabel
                    className="multiplecontrolsLabel"
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name-label"
                  >
                    {" "}
                    Status
                  </InputLabel>
                  <Select
                    sx={{ border: "none" }}
                    label="Status"
                    size="small"
                    value={status}
                    onChange={handleStatus}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="xlsx">Approved</MenuItem>
                    <MenuItem value="csv">Rejected</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} sx={{ zIndex: "999" }}>
                <DateRangePicker filterByDate={filterByDate} clear={clear} />
                {/* <SearchBar 
              // onSearch={(value, type) => onSearch(value, type)}
            /> */}
              </Grid>

            </Grid>
            <Box className="manage-data-list">
              {/* <TableSkeleton /> */}
              {
                apiStatus?.loader ? (
                  <TableSkeleton />
                ) : (
                  <Tables data={outputData} folderName={folderName} type="Manage O/P Files" />
                )
                // <TableSkeleton />
              }
            </Box>
            <Grid className="pagination-wrap">
              <TablePagination
                rowsPerPageOptions={[15, 25, 50, 100]}
                component="div"
                count={pageCount}
                page={parseInt(page)}
                onPageChange={handlePageChange}
                rowsPerPage={parseInt(rowsPerPage)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* {postProcessingModal && <PostProcessingModal
        open={postProcessingModal}
        onCloseClone={() => setPostProcessingModal(false)} 
        folderName={folderName}/>} */}
        {success && (
        <CustomizedSnackbars
          severity="success"
          state={success}
          text={message}
          handleClose={() => setSuccess(false)}
        />
      )}  
      {err && (
        <CustomizedSnackbars
          severity="error"
          state={err}
          text={message}
          handleClose={() => setErr(false)}
        />
      )}
    {loader && <ProgressBar />}
    </>
  );
};

export default Index;
