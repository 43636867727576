import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function TableSkeleton() {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Grid container wrap="nowrap">
        <Box
          sx={{
            width: "100%",
            marginRight: 0.5,
            my: 6,
            height: "800px",
          }}
        >
          <Skeleton width="100%" height={50} animation="wave" />
          <Skeleton
            width="100%"
            variant="rounded "
            height={300}
            animation="wave"
          />
          <Skeleton width="100%" height={50} animation="wave" />
        </Box>
      </Grid>
    </Box>
  );
}
