import AxiosService from "./axiosServices";
const axiosService = new AxiosService();
const configUrl = process.env.REACT_APP_APIBASE + "/";

export default class PreProcessor {
  getHeaders = () => {
    return {
      headers: {
        "content-Type": "Application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    };
  };
  getProjects = (obj) => {
    const { type, formattedStartDate, formattedEndDate, status } = obj;
    let params = {};

    if (type === "project") {
      params = {
        created_project_after: formattedStartDate,
        created_project_before: formattedEndDate,
      };
    } else {
      let boolStatus = "";
      if (status === "draft") {
        boolStatus = false;
      } else if (status === "assigned") {
        boolStatus = true;
      }

      params = {
        created_batch_after: formattedStartDate,
        created_batch_before: formattedEndDate,
        pre_pro_status: boolStatus,
      };
    }

    return axiosService.Get(`${configUrl}api/landing-page/`, {
      ...this.getHeaders(),
      params: params,
    });
  };
  getUserList = () => {
    return axiosService.Get(
      `${configUrl}api/get-users-list/`,
      this.getHeaders()
    );
  };
  esignHandle = (userName, userId, userRole, userPassword) => {
    const data = {
      body: {
        user_name: userName,
        user_id: userId,
        user_role: userRole,
        user_password: userPassword,
      },
    };
    return axiosService.Post(
      `${configUrl}api/verify-user/`,
      data,
      this.getHeaders()
    );
  };
  isSignatureUploaded = () => {
    return axiosService.Get(
      `${configUrl}api/is-signature-uploaded/`,
      this.getHeaders()
    );
  };
  userPermission = (authData) => {
    const data = {
      body: {
        username: authData.userName,
        project_id: authData.projectId,
        batch_id: authData.batchId,
        end_date: authData.endDate,
        subject: authData.subject,
        remark: authData.remark,
      },
    };
    return axiosService.Post(
      `${configUrl}api/assign-validator/`,
      data,
      this.getHeaders()
    );
  };

  getProcessorDashboard = (obj) => {
    const { type, formattedStartDate, formattedEndDate, status } = obj;
    let params = {};
    let boolStatus = "";
    if (type == "project") {
      params = {
        created_project_after: formattedStartDate,
        created_project_before: formattedEndDate,
      };
    } else {
      if (status === "draft") {
        boolStatus = false;
      } else if (status === "assigned") {
        boolStatus = true;
      }
    }
    params = {
      created_batch_after: formattedStartDate,
      created_batch_before: formattedEndDate,
      pro_status: boolStatus,
    };

    return axiosService.Get(`${configUrl}api/landing-page/`, {
      ...this.getHeaders(),
      params: params,
    });
  };

  getProData = (batchId, projectId) => {
    const params = {
      batch_id: batchId,
      project_id: projectId,
    };

    return axiosService.Get(`${configUrl}api/get-extracted-data/`, {
      ...this.getHeaders(),
      params: params,
    });
  };

  dataEntry = (payloadData) => {
    const data = {
      body: payloadData,
    };

    return axiosService.Post(
      `${configUrl}api/save-data-entry/`,
      data,
      this.getHeaders()
    );
  };

  rejectData = (payloadData) => {
    const data = {
      body: payloadData,
    };

    return axiosService.Post(
      `${configUrl}api/reject-batch/`,
      data,
      this.getHeaders()
    );
  };
  getProjectsViewed = (batchId) => {
    const data = {
      body: { batch_id: batchId },
    };
    return axiosService.Post(
      `${configUrl}api/project-batch-viewed/`,
      data,
      this.getHeaders()
    );
  };
}
