import React from "react";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
// import Tables from '../../../components/Table/Table';

export default function PreviewOpFile() {
  const history = useHistory();
  const backToList = () => {
    history.goBack();
  };
  return (
    <>
      <Header />
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8} sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                backgroundColor: "#F5F5F5",
                height: 50,
              }}
            >
              <IconButton onClick={backToList}>
                <ArrowBackIcon />
              </IconButton>
              <p
                style={{
                  marginLeft: 2,
                  // fontFamily: 'Montserrat',
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Project Name/Batch Name
              </p>
            </Box>
            <Box sx={{ marginTop: 2, padding: "15px" }}>{/* <Tables/> */}</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
