import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./rejectModal.scss";
import { useDispatch, useSelector } from "react-redux";
import * as acts from "../../../redux/administrator/actions";
import { AppBar, DialogContent, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Buttons from "../../Controls/buttons/Button";

export default function ModalProcessor(props) {
  const history = useHistory();
  let {
    disableDeleteRejection,
    disableMarkAsResolve,
    modalOpen,
    handleClose,
    handleReject,
    isShowClearButton,
    rejectionContent,
    handleDeleteRejection,
    isReadOnly,
    markAsResolve,
    isShowDeleteButton,
    id,
    heading,
  } = props;

  const [open, setOpen] = useState();
  const [comment, setComment] = useState(rejectionContent);
  const Dispatch = useDispatch();
  const outPutUrl = useSelector((state) => state?.admin?.outPutUrl);

  useEffect(() => {
    setOpen(modalOpen);
    setComment(rejectionContent);
  }, [modalOpen]);

  const clickToReject = (comment) => {
    let data = {
      batch_id: outPutUrl?.batch_id,
      projectId: outPutUrl?.projectId,
      rejection_remark: comment,
      id: id,
    };
    Dispatch(acts.rejectoutputFileBatch(data));
    history.push("/output-list");
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="lg"
        className="reject-modal"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          sx={{
            padding: "15px 0px 0px 24px !important",
          }}
        >
          <AppBar
            sx={{
              position: "relative",
              boxShadow: "none",
              backgroundColor: "white",
              padding: "0px !important",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography className="modalTitle" id="modal-modal-title">
                {heading}
              </Typography>

              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "inherit",
                  margin: "0px 10px",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <p className="comment"> Comment*</p>
          </AppBar>
        </DialogTitle>

        <DialogContent sx={{ padding: "10px 24px !important" }}>
          <textarea
            rows={10}
            className="comment-box"
            value={comment}
            required
            onChange={(event) => setComment(event.target.value)}
            disabled={isReadOnly}
          ></textarea>
          <div className="btn-wrap">
            {!isReadOnly ? (
              <>
                <div>
                  {isShowClearButton && (
                    <Button
                      className="clear-btn"
                      variant="outlined"
                      onClick={(event) => setComment("")}
                    >
                      Clear
                    </Button>
                  )}
                </div>
                <div>
                  <Buttons
                    className="reject-btn"
                    variant="outlined"
                    text="Submit"
                    disabled={comment === undefined || comment === ""}
                    onClick={(e) =>
                      localStorage?.userRole === "administrator"
                        ? clickToReject(comment)
                        : handleReject(comment)
                    }
                  />
                </div>
              </>
            ) : (
              <div style={{ float: "right" }}>
                <Buttons
                  className="reject-btn"
                  variant="outlined"
                  onClick={(e) => markAsResolve()}
                  disabled={disableMarkAsResolve}
                  text={"Resolve"}
                />
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

ModalProcessor.defaultProps = {
  isShowClearButton: false,
  isReadOnly: false,
  isShowDeleteButton: true,
  heading: "Do you really want to reject?",
};
