export const API_STATUS = "API_STATUS";
export const GET_USER_LIST = "GET_USER_LIST";
export const IS_SIGNATURE_UPLOADED_STATUS = "IS_SIGNATURE_UPLOADED_STATUS";
export const ASSIGN_USER_API_STATUS = "ASSIGN_USER_API_STATUS";
export const VALIDATE_USER_STATUS = "VALIDATE_USER_STATUS";
export const GET_PRO_LIST = "GET_PRO_LIST";
export const SET_PRO_PROJECTS = "GET_PRO_PROJECTS";
export const PRO_LOADING = "PRO_LOADING";
export const GET_PRO_DATA = "GET_PRO_DATA";
export const CLEAR_API_STATUS = "CLEAR_API_STATUS";
export const SET_SELECTED_FILES = "SET_SELECTED_FILES";
export const PDF_AND_PAGE_DETAILS = "PDF_AND_PAGE_DETAILS";
export const SET_OCR_VALUE = "SET_OCR_VALUE";
export const UPDATE_VIEW_STATUS = "UPDATE_VIEW_STATUS";
export const SET_SELECTED_FILES_DATA_FOR_PRO_UPDATE = "SET_SELECTED_FILES_DATA_FOR_PRO_UPDATE"
