import AxiosService from "./axiosServices";
const axiosService = new AxiosService();
const configUrl = process.env.REACT_APP_APIBASE + "/";

export default class UploadBatchAPI {
  listBoxFiles = (data) => {
    const header = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.token,
      },
    };
    return axiosService.Get(
      `${configUrl}api/list-box-files/?folder_id=${data?.folderId}&file_type=${data?.fileType}`,
      header
    );
  };

  uploadBoxFiles = (fileType = "", body) => {
    const header = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.token,
      },
    };
    return axiosService.Post(
      `${configUrl}api/list-box-files/?file_type=${fileType}/`,
      body,
      header
    );
  };
}
