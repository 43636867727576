import React from "react";
import "./Stepper.scss";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import TimelineDot from "@mui/lab/TimelineDot";
import { makeStyles, withStyles } from "@mui/styles";
import StepConnector from "@mui/material/StepConnector";
import Check from "@mui/icons-material/Check";
import PropTypes from "prop-types";
import "../../css/utility.scss";
import clsx from "clsx";
import { Box } from "@mui/material";
import { messages } from "../../constants/commonMessages";

function getSteps() {
  return [
    messages.NEW_PROJECT,
    messages.IMPORT_PDF,
    messages.IMPORT_TEMPLATE,
    messages.MAP_DATA,
  ];
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    // top: 22,
  },
  active: {
    "& $line": {
      borderBottom: "3px solid rgba(0,0,0,0.7)",
      fontWeight: 700,
    },
  },
  completed: {
    "& $line": {
      borderColor: "rgba(0,0,0,0.7)",
      borderBottom: "3px solid rgba(0,0,0,0.7)",
      borderTopWidth: "0px!important",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#B9B9B9",
    borderRadius: 1,
    borderTopWidth: "0px!important",
  },
})(StepConnector);

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={active ? classes.active : classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useQontoStepIconStyles = makeStyles({});

class ProjectOnlyStepperCpvAuto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: getSteps(),
      activeStep: 0,
    };
  }

  componentDidMount() {
    this.setState({
      activeStep: this.props.currentStep,
    });
  }

  handleNext = (stepNo) => {
    this.setState({ activeStep: this.state.activeStep + stepNo });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };
  ColorlibStepIcon(props) {
    const { active, completed } = props;
    const icons = {
      1: <TimelineDot variant="outlined" color="#1FC8CC" />,
      2: <TimelineDot variant="outlined" />,
      3: <TimelineDot variant="outlined" />,
      4: <TimelineDot variant="outlined" />,
      // 5: <TimelineDot variant="outlined" />,
    };

    return (
      <div
        className={clsx("stepper-root", {
          ["active-stepper"]: active,
          ["completed-step"]: completed,
        })}
      >
        {completed}
        {icons[String(props.icon)]}
      </div>
    );
  }
  handleProjectNameChange(e) {}
  render() {
    return (
      <Box className="stepper-wrap">
        <Stepper
          activeStep={this.state.activeStep}
          // alternativeLabel
          connector={<ColorlibConnector />}
        >
          {this.state.steps.map((label) => (
            <Step key={label} sx={{ marginTop: 0 }}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }
}
export default ProjectOnlyStepperCpvAuto;
