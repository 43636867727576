import React from "react";
import Header from "../../../components/header/Header";
import { Grid } from "@mui/material";
import Footer from "../../../components/Footer";
import { useEffect, useState } from "react";
import * as acts from "../../../redux/pre_processor/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ProgressBar from "../../../components/ProgressBar";
import "./NewProject.scss";
import CustomizedSnackbar from "../../../components/snackbars/CustomizedSnackbars";
import ProjectStepper from "../../../components/Stepper/ProjectStepperCpvAuto";
import { Select, Box, TextField, MenuItem } from "@mui/material";
import { notification } from "../../../constants/notificationMessages";
import constants from "../../../constants/ConstantValues";

const NewProject = (props) => {
  const [projectName, setProjectName] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const [createProjectSuccess, setCreateProjectSuccess] = useState(false);
  const [createProjectError, setCreateProjectError] = useState(false);
  const [message, setMessage] = useState("");
  const [invalidValidationErrorMessage, setInvalidValidationErrorMessage] =
    useState("");
  const [drugType, setDrugType] = useState("");
  const [buttonType, setButtonType] = useState("");
  const apiStatus = useSelector((state) => state?.prePro?.apiStatus);
  const fileType = useSelector((state) => state?.prePro?.folderType);
  const { PROJECT_NAME, DRUG_TYPE } = constants;

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus.loader === false) {
        if (apiStatus.message != undefined || apiStatus.message != "") {
          if (apiStatus.next === true && buttonType === "continue") {
            setCreateProjectSuccess(true);
            setMessage(apiStatus.message);
            setTimeout(() => {
              history.push("/upload-batch-record");
            }, 2000);
          }
          else if (apiStatus.next === false || apiStatus.message == 'This Project name is already taken') {
            setMessage(apiStatus.message);
            setInvalidValidationErrorMessage(true);
            setCreateProjectError(true);
          }
        }
      }
    }
  }, [apiStatus]);

  useEffect(() => {
    dispatch(acts.getDrugType());
  }, []);

  const handleprojectName = (event, action) => {
    setInvalidValidationErrorMessage("");
    setProjectName(event.target.value);
    setInvalidValidationErrorMessage(false);
  };
  const handleSubmit = async (event, action) => {
    localStorage.setItem("mapData", false);
    setButtonType(action);
    event.preventDefault();
    if (action === "previous") {
      history.push("/pre-processor-dashboard");
    }
    if (/[^a-zA-Z0-9\_ -]/.test(projectName)) {
      setInvalidValidationErrorMessage(
        notification.invalidValidationErrorMessage
      );
    } else if (action === "save") {
      dispatch(acts.createNewProject(projectName, drugType, true));
    } else if (action === "continue") {
      dispatch(acts.createNewProject(projectName, drugType, true));
      setInvalidValidationErrorMessage("");
    }
    localStorage.removeItem("isCloned");
  };
  const handleFolderChange = (event) => {
    setDrugType(event.target.value);
    localStorage.setItem("drugType", event.target.value);
  };

  return (
    <React.Fragment>
      <Header page="new-project" />
      <ProjectStepper currentStep={0} />
      <Box>
        <Grid container className="new-project-wrap">
          <Grid item md={3} className="project-info">
            <h6 className="subtitle2">{PROJECT_NAME}</h6>
            <TextField
              fullWidth
              className="new-project"
              placeholder={PROJECT_NAME}
              helperText={invalidValidationErrorMessage}
              error={invalidValidationErrorMessage}
              onChange={(event, action) => handleprojectName(event, action)}
            />
          </Grid>

          <Grid item md={3} className="project-info">
            <h6 className="subtitle2">{DRUG_TYPE}</h6>
            <Select
              fullWidth
              value={drugType}
              disabled={projectName === ""}
              onChange={handleFolderChange}
            >
              {fileType &&
                fileType.length > 0 &&
                fileType.map((value) => (
                  <MenuItem value={value.name} key={value?.name}>{value.name}</MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      </Box>
      <Footer
        isNextDisable={projectName !== "" && drugType !== "" ? false : true}
        isPrevious={false}
        isNext={false}
        onHandleClick={(event, action) => handleSubmit(event, action)}
      />

      <div>
        {apiStatus && apiStatus.loader ? <ProgressBar /> : ""}

        {createProjectSuccess && (
          <CustomizedSnackbar
            severity="success"
            state={createProjectSuccess}
            text={message}
            handleClose={() => setCreateProjectSuccess(false)}
          />
        )}

        {createProjectError && (
          <CustomizedSnackbar
            severity="error"
            state={createProjectError}
            text={message}
            handleClose={() => setCreateProjectError(false)}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default NewProject;
