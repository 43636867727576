import AxiosService from "./axiosServices";
const axiosService = new AxiosService();
const configUrl = process.env.REACT_APP_APIBASE + "/";
export default class ValidatorService {
  getHeaders = () => {
    return {
      headers: {
        "Content-Type": "Application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    };
  };

  getValidatorDashboard = (obj) => {
    const { type, formattedStartDate, formattedEndDate, status } = obj;
    let params = {};
    let boolStatus = "";
    if (type == "project") {
      params = {
        created_project_after: formattedStartDate,
        created_project_before: formattedEndDate,
      };
    } else {
      if (status === "draft") {
        boolStatus = false;
      } else if (status === "assigned") {
        boolStatus = true;
      }
    }
    params = {
      created_batch_after: formattedStartDate,
      created_batch_before: formattedEndDate,
      vali_status: boolStatus,
    };

    return axiosService.Get(`${configUrl}api/landing-page/`, {
      ...this.getHeaders(),
      params: params,
    });
  };

  getValiData = (projectId, batchId) => {
    const params = {
      project_id: projectId,
      batch_id: batchId,
    };

    return axiosService.Get(`${configUrl}api/get-data-for-validation/`, {
      ...this.getHeaders(),
      params: params,
    });
  };

  validateData = (payloadData) => {
    const data = {
      body: payloadData,
    };

    return axiosService.Post(
      `${configUrl}api/save-validated-data/`,
      data,
      this.getHeaders()
    );
  };

  rejectValidatorData = (payloadData) => {
    const data = {
      body: payloadData,
    };

    return axiosService.Post(
      `${configUrl}api/reject-batch/`,
      data,
      this.getHeaders()
    );
  };
  isSignatureUploaded = () => {
    return axiosService.Get(
      `${configUrl}api/is-signature-uploaded/`,
      this.getHeaders()
    );
  };
}
