import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import "./OutputTable.scss";
import {
  Button,
  Checkbox,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { EditTwoTone } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { action } from "../../constants/action";
import PostProcessingModal from "../Modal/PostProcessing/clonModal/PostProcessing";
import * as acts from "../../redux/administrator/actions";
import ProgressBar from "../ProgressBar";
import TableSkeleton from "../Skeletons/TableSkeleton";
import CustomizedSnackbars from "../snackbars/CustomizedSnackbars";
const Tables = (props) => {
  const { Type } = props;
  const { data } = props;
  const { type } = props;
  const { folderName } = props;

  const history = useHistory();
  const Dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [data1, setData1] = useState("");
  // const [snackBar, setSnackbar] = useState(false);
  // const [severity, setSeverity] = useState("")
  const [checkedItems, setCheckedItems] = useState({}); // State to store checked items
  const [newCheckedItems, setNewCheckedItems] = useState({}); // State to store checked items
  const [postProcessingModal, setPostProcessingModal] = useState(false);
  //  const visualizationTableName = useSelector((state) => state?.admin?.visualizationTableName)
  //   const [folderName, setFolderName] = useState(visualizationTableName?.data)
  const [preview, setPreview] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  // const [dataToSet, setDataToSet] = useState(data);

  useEffect(() => {
    console.log(data?.length);
    data?.map((list, index) => {
      if (list?.post_processed) {
        checkedItems[list?.id] = true;
      } else checkedItems[list?.id] = false;
    });
    setCheckedItems({ ...checkedItems });
  }, [data]);

  // useEffect(() => {
  //   Dispatch(acts.getVisualizationTableName())
  // }, [visualizationTableName])
  // useEffect(() => {
  //   if (visualizationTableName?.length > 0) {
  //     setFolderName(visualizationTableName?.data)
  //   }
  // const apiStatus = useSelector((state) => state?.admin?.apiStatus)
  //   // }, [visualizationTableName])
  //   useEffect(() => {
  //     if (apiStatus && apiStatus !== undefined) {
  //       if (apiStatus?.apiSuccess === true && apiStatus?.next === true){
  //         setMessage(apiStatus?.message)
  //         setLoader(false)
  //         setSnackbar(true);
  //         setSeverity("success")

  //       }
  //      else if (apiStatus?.apiError === true && apiStatus?.next === false) {
  //         setMessage(apiStatus?.message);
  //         setSnackbar(true);
  //         setSeverity("error")
  //         setLoader(false)
  //       }
  //     }
  //   }, [apiStatus]);
  const handleClick = (event, list) => {
    setPreview(list);
    setAnchorEl(event.currentTarget);
    setData1(list?.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const showDetails = (data) => {
    history.push("/excel-output", data);
  };
  const showPreviewDetails = () => {
    history.push("/excel-output", preview);
  };

  const PreviewDataPointCalculation = () => {
    history.push({
      pathname: "/data-point-calculation",
      state: { id: data1 },
    });
  };
  const handleCheckboxChange = (id, checked) => {
    if (checked) {
      checkedItems[id] = true;
      newCheckedItems[id] = true;
    } else {
      checkedItems[id] = false;
      newCheckedItems[id] = false;
    }
    setDisableSubmit(false);
    setNewCheckedItems({ ...checkedItems });
    setNewCheckedItems({ ...newCheckedItems });

    // setCheckedItems((prevCheckedItems) => ({
    //   ...prevCheckedItems,
    //   [id]: !prevCheckedItems[id], // Toggle the checkbox state
    // }));

    // setNewCheckedItems((prevNewCheckedItems) => ({
    //   ...prevNewCheckedItems,
    //   [id]: !prevNewCheckedItems[id], // Toggle the checkbox state
    // }));

    console.log(checkedItems, newCheckedItems);
  };

  //used to enable disable submit btn on length
  // const numSelectedCheckboxes = Object.values(checkedItems).filter(
  //   (value) => value
  // ).length;

  const handlePostProcessing = (selectedOption) => {
    let obj = folderName.find((o) => o.id === selectedOption);
    console.log(obj);
    const resultArray = [];
    for (const key in newCheckedItems) {
      if (newCheckedItems[key] === true) {
        resultArray.push(Number(key)); // Convert the key to a number if needed
      }
    }

    // const name =   folderName.find((id)name);

    const data = {
      body: {
        objects_to_rename: resultArray,
        renaming_guide_details: obj,
      },
    };
    console.log("inside post processing", data);
    Dispatch(acts.submitForPostProcessing(data));
    setLoader(true);
  };
  return (
    <>
      <div>
        {" "}
        {data?.length >= 0 ? (
          <TableContainer
            sx={{ overflowY: "scroll", height: "calc(100vh - 214px)" }}
          >
            <Table className="table-output" aria-label="simple table">
              <TableHead>
                <TableRow>
                  {Type == "Batch" ? (
                    <></>
                  ) : (
                    <TableCell align="left">Project Name</TableCell>
                  )}
                  {Type == "Batch" ? (
                    <></>
                  ) : (
                    <TableCell align="left">Batch Name</TableCell>
                  )}
                  <TableCell align="left">File Name</TableCell>
                  <TableCell align="left">File Type</TableCell>
                  {Type == "Batch" ? (
                    <TableCell align="left">Drug Type</TableCell>
                  ) : (
                    ""
                  )}

                  <TableCell align="left">Status</TableCell>
                  {Type == "Batch" ? (
                    ""
                  ) : (
                    <TableCell align="left">Preview</TableCell>
                  )}
                  {Type == "Batch" ? (
                    ""
                  ) : (
                    <TableCell align="left">Action</TableCell>
                  )}
                  {Type == "Batch" ? (
                    ""
                  ) : (
                    <TableCell align="left">Post Processing</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((list) => {
                  return (
                    <>
                      <TableRow key={list.id}>
                        {Type == "Batch" ? (
                          <></>
                        ) : (
                          <TableCell align="left">
                            {list.project_name}
                          </TableCell>
                        )}
                        {Type == "Batch" ? (
                          <></>
                        ) : (
                          <TableCell align="left">{list.batch_name}</TableCell>
                        )}

                        <TableCell align="left">{list.file_name}</TableCell>
                        <TableCell align="left">{list.file_type}</TableCell>
                        {Type == "Batch" ? (
                          <TableCell align="left">{list.drug_type}</TableCell>
                        ) : (
                          ""
                        )}

                        {Type == "Batch" ? (
                          <TableCell align="left">{list.status}</TableCell>
                        ) : list.rejected === true ? (
                          <TableCell>Rejected</TableCell>
                        ) : (
                          <TableCell align="left">
                            {list.status ? "Approved" : ""}
                          </TableCell>
                        )}
                        {Type == "Batch" ? (
                          ""
                        ) : (
                          <TableCell>
                            {list.disable_action ? (
                              <></>
                            ) : (
                              <IconButton onClick={() => showDetails(list)}>
                                <VisibilityIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        )}
                        {/* {
                      list.file_type === "csv" && */}
                        <TableCell>
                          <IconButton onClick={(e) => handleClick(e, list)}>
                            <MoreVertIcon />
                          </IconButton>
                          {Type == "Batch" ? (
                            ""
                          ) : (
                            <Menu
                              className="action-list"
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                            >
                              <MenuItem
                                className="action-item"
                                onClick={() => PreviewDataPointCalculation()}
                              >
                                {action.DPCALCULATION}
                              </MenuItem>
                              
                              {!preview.rejected && preview.disable_action ? (
                                <MenuItem
                                  className="action-item"
                                  onClick={showPreviewDetails}
                                >
                                  Preview
                                </MenuItem>
                              ) : (
                                ""
                              )}
                            </Menu>
                          )}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={checkedItems[list.id] || false}
                            // onChange={() => handleCheckboxChange(list.id)}
                            onChange={(event) =>
                              handleCheckboxChange(
                                list.id,
                                event.target.checked
                              )
                            }
                          />
                        </TableCell>
                        {/* } */}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableSkeleton />
        )}
      </div>
      <div
        style={{
          position: "absolute",
          top: "120px",
          right: "1%",
        }}
      >
        {type == "Manage O/P Files" ? (
          <Button
            style={{ display: "flex", alignItems: "center" }}
            disabled={disableSubmit}
            variant="outlined"
            onClick={() => setPostProcessingModal(true)}
          >
            Submit
          </Button>
        ) : (
          ""
        )}
      </div>
      {/* {snackBar && <CustomizedSnackbars
          severity={severity}
          state={snackBar}
          text={message}
          handleClose={() => setSnackbar(false)}
        />} */}
      {loader && <ProgressBar />}

      {postProcessingModal && (
        <PostProcessingModal
          open={postProcessingModal}
          onCloseClone={() => setPostProcessingModal(false)}
          folderName={folderName}
          handlePostProcessing={handlePostProcessing}
        />
      )}
    </>
  );
};
export default Tables;
