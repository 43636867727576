import {
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import "./DataMapTable.scss";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box } from "@mui/system";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AddCircleOutlineRounded from "@mui/icons-material/AddCircleOutlineRounded";
import Buttons from "../../Controls/buttons/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { getSheetsNames } from "../../../redux/pre_processor/actions";

const StyledInput = styled(OutlinedInput)({
  background: "#F5F5F5",
  borderRadius: "10px",
  minWidth: "213px",
  border: "1px solid #B9B9B9",
});

const ParameterNameInput = ({
  newParameterIndex,
  newParameter,
  handleNewParameterNameChange,
}) => {
  const [value, setValue] = useState(newParameter?.parameter);

  const onChange = (value) => {
    setValue(value);
  };
  return (
    <div>
      <TextField
        id="outlined-basic"
        variant="outlined"
        // key={newParameterIndex}
        // autoFocus={true}
        placeholder="Parameter"
        value={value}
        onChange={(e) => onChange(e.target.value, newParameterIndex)}
        onBlur={(e) => handleNewParameterNameChange(value, newParameterIndex)}
      />
    </div>
  );
};

const NewParameter = ({ role, handleAddParameter }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    textAlign: "center",
  }));

  const [newParameters, setNewParameters] = useState([]);
  const [newParameterCount, setNewParameterCount] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [validation, setValidation] = useState({
    isError: false,
    errorMessage: "Please Fill All Parameters Names",
  });

  const sheetNames = useSelector((state) => state?.prePro?.sheetNames);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!openModal) {
      setNewParameters([]);
      setNewParameterCount(null);
      setOpenModal(false);
    }
  }, [openModal]);
  const handleAdNewParameterChange = (value) => {
    setNewParameterCount(value);
  };

  // below logic for new parametersData

  const addNewParameterClick = () => {
    dispatch(getSheetsNames(localStorage.projectId));
    // };
    const parametersToAdd = [];
    for (let i = 0; i < newParameterCount; i++) {
      parametersToAdd.push({
        parameter: "",
        sheetName: "",
      });
    }
    setNewParameters([...newParameters, ...parametersToAdd]);
    setNewParameterCount("");
  };

  const handleNewParameterNameChange = (value, index) => {
    newParameters[index].parameter = value;
    setNewParameters(newParameters);
    setValidation({ ...validation, isError: false });
  };

  const handleDeleteParameter = (newParameterIndex) => {
    newParameters.splice(newParameterIndex, 1);
    setNewParameters([...newParameters]);
  };

  const onHandleChangeNewParameterSheetName = (value, newParameterIndex) => {
    newParameters[newParameterIndex].sheetName = value;
    setNewParameters([...newParameters]);
  };

  const onClickAddParameterButton = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const onClickAddParameter = (newParameters) => {
    const isParameterEmpty = (parameter) => parameter.parameter !== "";
    const isAllFilledParamNameInput = newParameters.every(isParameterEmpty);

    if (isAllFilledParamNameInput) {
      handleAddParameter(newParameters);
      setOpenModal(false);
      setNewParameters([]);
    } else {
      setValidation({ ...validation, isError: true });
    }
  };

  return (
    <>
      <Buttons
        className="add_new_parameter"
        text={"Add New Parameters"}
        onClick={onClickAddParameterButton}
      />
      {/* Add new parametersData Button */}

      {openModal && (
        <Dialog
          open={openModal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Add Parameters</DialogTitle>
          <DialogContent className="add-new-para-modal">
            <>
              <Grid
                container
                sx={{
                  padding: "5px 20px",
                  // margin: "25px 40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <StyledInput
                      type="number"
                      // label="Add Parameters"
                      size="small"
                      // placeholder="Number of parameters to add"
                      value={newParameterCount}
                      onChange={(e) =>
                        handleAdNewParameterChange(e.target.value)
                      }
                    />
                    <FormHelperText>
                      {"Number of parameters to add."}
                    </FormHelperText>
                  </div>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => addNewParameterClick()}
                    // ={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    <AddCircleOutlineRounded sx={{ margin: "2px" }} />
                  </IconButton>
                </Box>
                <Typography sx={{ marginTop: "10px" }} variant="subtitle2">
                  *If Sheet Name Is Not Assigned For Newly Added Parameter Then
                  It Will Take Default First Sheet.
                </Typography>
              </Grid>

              {newParameters.length > 0 &&
                newParameters.map((newParameter, newParameterIndex) => (
                  <Grid container className="parameter-add">
                    <Grid xs={5}>
                      <Item>
                        <ParameterNameInput
                          newParameterIndex={newParameterIndex}
                          newParameter={newParameter}
                          handleNewParameterNameChange={
                            handleNewParameterNameChange
                          }
                        />
                      </Item>
                    </Grid>

                    <Grid xs={5}>
                      <Item>
                        <FormControl fullWidth>
                          <Select
                            value={
                              newParameter?.sheetName
                                ? newParameter?.sheetName
                                : ""
                            }
                            onChange={(e) =>
                              onHandleChangeNewParameterSheetName(
                                e.target.value,
                                newParameterIndex
                              )
                            }
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">
                              <em>Select Sheet</em>
                            </MenuItem>
                            {sheetNames?.map((item, index) => {
                              return <MenuItem value={item}>{item}</MenuItem>;
                            })}
                          </Select>
                        </FormControl>
                      </Item>
                    </Grid>

                    <Grid xs={1}>
                      <Item>
                        <IconButton
                          aria-label="delete-parameter"
                          onClick={(e) =>
                            handleDeleteParameter(newParameterIndex)
                          }
                          edge="end"
                        >
                          <DeleteForeverOutlinedIcon />
                        </IconButton>
                      </Item>
                    </Grid>
                  </Grid>
                ))}
            </>
            {validation?.isError && (
              <FormHelperText error variant="outlined">
                {validation?.errorMessage}
              </FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              className="btn-add-para"
              variant="contained"
              onClick={(e) => onClickAddParameter(newParameters)}
              disabled={newParameters.length <= 0}
            >
              Add Parameters
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default NewParameter;
