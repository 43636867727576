import React, { useEffect, useState } from "react";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import Header from "../../../components/header/Header";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import {
  Grid,
  Button,
  Box,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DateRangePicker from "../../../components/DateRangePicker";
import * as acts from "../../../redux/administrator/actions";
import { useDispatch, useSelector } from "react-redux";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { margin } from "@mui/system";
import jsPDF from "jspdf";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import TablePagination from "@mui/material/TablePagination";
import "./OutPutFormat.scss";

export default function DataPointDetails() {
  const Dispatch = useDispatch();
  const dataPoints = useSelector((state) => state?.admin?.dataPointDetail);
  const [dpPoint, setDpPoint] = useState(dataPoints?.data);
  const [file, setFile] = useState("");
  const [pageCount, setPageCount] = useState(dataPoints?.total_count || 0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const apiStatus = useSelector((state) => state?.admin?.dataPointDetail);
  console.log(apiStatus);
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [sDate, setSDate] = useState("");
  const [eDate, setEDate] = useState("");
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet:charset=UTF-8";
  const fileExtension = ".xlsx";
  console.log("show Fies..........", dpPoint);

  useEffect(() => {
    const data = {
      page: page + 1,
      page_size: rowsPerPage,
      formattedStartDate: sDate,
      formattedEndDate: eDate,
      file,
    };
    Dispatch(acts.getDpDetails(data));
  }, [Dispatch, rowsPerPage]);

  useEffect(() => {
    console.log("use 2");
    if (dataPoints?.data?.length > 0) {
      setDpPoint(dataPoints?.data);
    } else {
      setDpPoint([]);
    }
    setRowsPerPage(rowsPerPage);
    setPageCount(dataPoints?.total_count);
  }, [, dataPoints?.data, pageCount, dpPoint]);

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(dataPoints?.data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "DPDetails" + fileExtension);
  };

  const filterByDate = (startDate, endDate, status) => {
    const formattedStartDate =
      startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
    const formattedEndDate =
      endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";
    setSDate(formattedStartDate);
    setEDate(formattedEndDate);
    setPage(0);
    setRowsPerPage(15);
    const data = {
      page: page + 1,
      page_size: rowsPerPage,
      formattedStartDate,
      formattedEndDate,
      file,
    };
    Dispatch(acts.getDpDetails(data));
    setPageCount(dataPoints?.total_count);
  };

  const handleChange = (event) => {
    setPage(0);
    console.log("show events..........", event.target.value);
    setFile(event.target.value);
  };

  const onSearch = (word) => {
    let result;
    if (word) {
      result = dataPoints?.data?.filter(function (el) {
        return (
          el?.file_name.toString().toLowerCase().indexOf(word.toLowerCase()) !==
          -1
        );
      });
    } else {
      result = dataPoints?.data;
    }
    setDpPoint([...result]);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    const data = {
      page: value + 1,
      page_size: rowsPerPage,
      formattedStartDate: sDate,
      formattedEndDate: eDate,
      file,
    };
    Dispatch(acts.getOutPutList(data));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    // setPageCount(listData?.total_count);
  };

  return (
    <>
      <Header page="new-project" />
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <AdministratorHeader text="Data Point Details" />
            <Grid container>
              <Grid item md={9} sx={{ padding: "10px", display: "flex" }}>
                <div className="search">
                  <div className="search-wrapper">
                    <SearchIcon style={{ color: "#B9B9B9", float: "right" }} />
                  </div>
                  <InputBase
                    id="searchInput"
                    className="input-style"
                    placeholder={"Search"}
                    inputProps={{ "aria-label": "search" }}
                    onChange={(event) => onSearch(event.target.value)}
                  />
                </div>

                <FormControl sx={{ width: "250px", marginRight: "14px" }}>
                  <InputLabel
                    sx={{ margin: "-6px 0px 0px 13px" }}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name-label"
                  ></InputLabel>
                  <Select
                    size="small"
                    value={file}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="xlsx">xlsx</MenuItem>
                    <MenuItem value="csv">csv</MenuItem>
                  </Select>
                </FormControl>
                <DateRangePicker filterByDate={filterByDate} />
              </Grid>
              <Grid
                item
                md={3}
                sx={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <Button
                  sx={{ padding: "6.76px 30px", marginRight: "2px" }}
                  onClick={exportToExcel}
                  size="small"
                  variant="outlined"
                  startIcon={<FileDownloadIcon />}
                >
                  download
                </Button>
              </Grid>
            </Grid>
            <Box sx={{ padding: "15px", height: "63vh" }}>
              {/* Table */}
              <TableContainer
                sx={{ overflowY: "scroll", height: "calc(100vh - 214px)" }}
              >
                <Table className="table-output" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>File Name</TableCell>
                      <TableCell>File Extension</TableCell>
                      <TableCell>Data Points</TableCell>
                      {/* <TableCell>Validator</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dpPoint?.map((list) => {
                      return (
                        <>
                          <TableRow key={list.id}>
                            <TableCell align="left">{list.file_name}</TableCell>
                            <TableCell align="left">
                              {list.file_extension}
                            </TableCell>
                            <TableCell align="left">
                              {list.data_points}
                            </TableCell>
                            {/* <TableCell align="left">{list.file_name}</TableCell> */}
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "7px",
                background: "#F8FBFF",
              }}
            >
              <TablePagination
                component="div"
                rowsPerPageOptions={[15, 25, 50, 100]}
                count={parseInt(pageCount)}
                page={parseInt(page)}
                onPageChange={handlePageChange}
                rowsPerPage={parseInt(rowsPerPage)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
