import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Footer from "../../../components/Footer";
import * as acts from "../../../redux/pre_processor/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BatchStepper from "../../../components/Stepper/BatchStepperCpvAuto";
import ProgressBar from "../../../components/ProgressBar";
import CustomizedSnackbar from "../../../components/snackbars/CustomizedSnackbars";
import "./newBatch.scss";
import { notification } from "../../../constants/notificationMessages";
import ConstantValues from "../../../constants/ConstantValues";

const NewProject = () => {
  const [batchName, setBatchName] = useState("");
  const [createBatchSuccess, setCreateBatchSuccess] = useState(false);
  const [createBatchError, setCreateBatchError] = useState(false);
  const [message, setMessage] = useState("");
  const [buttonType, setButtonType] = useState("");
  const [invalidValidationErrorMessage, setInvalidValidationErrorMessage] =
    useState("");
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state?.prePro?.apiStatus);
  const history = useHistory();
  const { CREATE_BATCH, BATCH_NAME } = ConstantValues;
  useEffect(() => {
    if (apiState && apiState !== undefined) {
      if (apiState.loader === false) {
        if (apiState.message != undefined || apiState.message != "") {
          if (apiState.next && buttonType === "continue") {
            setCreateBatchSuccess(true);
            setMessage(apiState.message);
            dispatch(acts.apiStatusClear());
            setTimeout(() => {
              history.push({ pathname: "/upload-pdf-against-batch" });
            }, 2000);
          }
          else if (apiState.next === false || apiState.message == "This Batch name is already taken") {
            setCreateBatchError(true);
            setMessage(apiState.message);
          }
        }
      }
    }
  }, [apiState]);

  const handleChange = (name) => {
    setBatchName(name);
    setInvalidValidationErrorMessage("");
  };

  const onClick = (event, action) => {
    setButtonType(action);
    event.preventDefault();
    if (action === "previous") {
      history.push("/pre-processor-dashboard");
    }
    if (/[^a-zA-Z0-9\_ -]/.test(batchName)) {
      setInvalidValidationErrorMessage(
        notification.invalidValidationErrMsgBatch
      );
    } else if (action === "continue") {
      dispatch(acts.createBatch(batchName, localStorage.projectId));
    }
  };
  return (
    <React.Fragment>
      <Header />
      <BatchStepper currentStep={0} />
      <Box className="new-batch-wrap">
        <div className="new-batch-info">
          <h6>{CREATE_BATCH}</h6>
          <TextField
            fullWidth
            // label={invalidValidationErrorMessage}
            error={invalidValidationErrorMessage}
            placeholder={BATCH_NAME}
            onChange={(e) => handleChange(e.target.value)}
            helperText={invalidValidationErrorMessage}
          />
        </div>
      </Box>
      <Footer
        isPrevious={false}
        isNextDisable={batchName !== "" ? false : true}
        isSaveDisable={batchName !== "" ? false : true}
        onHandleClick={(event, action) => onClick(event, action)}
      />
      {apiState && apiState.loader ? <ProgressBar /> : ""}

      {createBatchSuccess && (
        <CustomizedSnackbar
          severity="success"
          state={createBatchSuccess}
          text={message}
          handleClose={() => setCreateBatchSuccess(false)}
        />
      )}

      {createBatchError && (
        <CustomizedSnackbar
          severity="error"
          state={createBatchError}
          text={message}
          handleClose={() => setCreateBatchError(false)}
        />
      )}
    </React.Fragment>
  );
};
export default NewProject;
