import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import { useHistory } from "react-router";
// import "../components/Table/DashboardTable.scss";
import "./BatchInfoTable.scss";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as acts from "../redux/administrator/actions";
import TablePagination from "@mui/material/TablePagination";
import CustomizedSnackbars from "./snackbars/CustomizedSnackbars";

const useStyles = makeStyles((theme) => ({
  tcontainer: {
    borderRadius: 0,
  },
}));

function Row(props) {
  const { project, i } = props;
  const [open, setOpen] = useState(false);

  const { row } = props;

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.project_name}</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>

      {open &&
        row?.batch_details?.map((list) => {
          return (
            <>
              <TableRow className="hidden-row">
                <TableCell>{}</TableCell>
                <TableCell>{}</TableCell>
                <TableCell>{list.batch_name}</TableCell>
                <TableCell>{list.pre_processor}</TableCell>
                <TableCell>{list.processor}</TableCell>
                <TableCell>{list.validator}</TableCell>
                <TableCell>{list.status} </TableCell>
              </TableRow>
            </>
          );
        })}
    </>
  );
}

export default function DashboardTable(props) {
  const { header } = props;
  const classes = useStyles();
  const batch_details = useSelector((state) => state?.admin?.batchDetails);
  const [pageCount, setPageCount] = useState(batch_details?.total_count || 0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);
  const [projects, setProjects] = useState([]);
  const dispatch = useDispatch();
  const apiStatus = useSelector((state) => state?.admin?.uploadData);
  console.log(apiStatus);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(false);

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.message != null && apiStatus?.next === false) {
        setMessage(apiStatus?.message);
        setErr(true);
      }
    }
  }, [apiStatus]);

  useEffect(() => {
    if (batch_details?.response_list?.length > 0) {
      setProjects(batch_details?.response_list);
    }
    setRowsPerPage(rowsPerPage);
    setPageCount(batch_details?.total_count);
  }, [batch_details?.response_list, projects]);

  useEffect(() => {
    dispatch(
      acts.getBatchDetailsList({
        page: page + 1,
        page_size: rowsPerPage,
      })
    );
  }, [dispatch, rowsPerPage]);

  const handlePageChange = (event, value) => {
    setPage(value);
    dispatch(
      acts.getBatchDetailsList({
        page: value + 1,
        page_size: rowsPerPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <div className="Admin-BatchInfo-wrap">
      <Box
        className="Admin-BatchInfo"
        // className={classes.tcontainer}
      >
        <TableContainer className="dashBoardTh">
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow className="table-row">
                <TableCell>
                  <Checkbox disabled />
                </TableCell>
                {header?.map((list) => {
                  return <TableCell>{list}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody className="tBody">
              {projects &&
                projects?.length > 0 &&
                projects?.map((project, i) => (
                  <Row
                    key={project?.project_name}
                    row={project}
                    i={i}
                    props={props}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {err && (
        <CustomizedSnackbars
          severity="error"
          state={err}
          text={message}
          handleClose={() => setErr(false)}
        />
      )}
      <Grid className="pagination-wrap">
        <TablePagination
          component="div"
          rowsPerPageOptions={[15, 25, 50, 100]}
          count={parseInt(pageCount)}
          page={parseInt(page)}
          onPageChange={handlePageChange}
          rowsPerPage={parseInt(rowsPerPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </div>
  );
}
