import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import {
  Box,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Buttons from "../../../components/Controls/buttons/Button";
import React, { useEffect, useState } from "react";
import * as acts from "../../../redux/administrator/actions";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import "./UserForm.scss";
import Checkbox from '@mui/material/Checkbox';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import {messages} from '../../../constants/commonMessages'
const Index = (props) => {
  let userInfo = props?.location?.state;
  const [firstname, setFirstName] = React.useState(userInfo?.first_name || "");
  const [lastname, setLastName] = React.useState(userInfo?.last_name || "");
  const [username, setUsername] = React.useState(userInfo?.username || "");
  const [email, setEmail] = React.useState(userInfo?.email || "");
  const [password, setPassword] = React.useState("");
  const [group, setGroup] = React.useState(userInfo?.groups[0] || "");
  const [confirmpass, setConfirmPass] = React.useState("");
  const [message, setMsg] = React.useState("");
  const [isPass, setIsPass] = React.useState(userInfo?.id ? false : true);
  const [passwordType, setPasswordType] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState(false);

  const Dispatch = useDispatch();
  const history = useHistory();
  const userId = props?.location?.state?.id;

  useEffect(() => {
    Dispatch(acts.getManageGroupsList());
  }, []);
  const groupData = useSelector((state) => state?.admin?.group);
  const data = useSelector((state) => state?.admin?.userState);

  let isOpen = false;
  let text = "";
  let severity = "";
  if (data?.message == "user added") {
    isOpen = true;
    text = messages.USER_ADDED;
    severity = messages.SUCCESS;
    setTimeout(() => {
      history.push("/manage-user");
    }, 5000);
  } else if (data?.message == "user edited") {
    isOpen = true;
    text = messages.USER_EDIT;
    severity = messages.SUCCESS
    setTimeout(() => {
      history.push("/manage-user");
    }, 5000);
  } else if (data?.message == "error") {
    isOpen = true;
    text = messages.FAIL;
    severity = messages.ERROR;
  }

  const handleSubmit = () => {
    if (password != confirmpass) {
      return setMsg(messages.PASSWORD_NOT_MATCH);
    } else {
      setMsg("");
    }

    let user = {
      first_name: firstname,
      last_name: lastname,
      username: username,
      email: email,
      password: password,
      groups: [group],
    };

    if (userId) {
      Dispatch(acts.editUser(user, userId));
    } else {
      Dispatch(acts.addNewUser(user));
    }
  };

  const [state, setState] = React.useState({});

  const handleChange = (event) => {
    setGroup(event.target.value);
    setState({
      ...state,
      [event.target.value]: event.target.checked,
    });
  };

  const togglePassword = () => {
    setPasswordType(!passwordType);
  };
  const toggleConfirmPassword = () => {
    setConfirmPasswordType(!confirmPasswordType);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Header page="new-project" />
      {isOpen ? (
        <CustomizedSnackbars
          className="popup"
          handleClose={() => {
            isOpen = false;
          }}
          severity={severity}
          state={isOpen}
          text={text}
        />
      ) : (
        <></>
      )}
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                backgroundColor: "#F5F5F5",
                height: 60,
                borderBottom: "1px solid #B9B9B9",
                borderTop: "1px solid #B9B9B9",
              }}
            >
              <Link to="/manage-user">
                <IconButton
                  sx={{
                    marginLeft: 2,
                    // fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <p
                style={{
                  marginLeft: 2,
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                {userId ? messages.EDIT_USER : messages.ADD_USER}
              </p>
            </Box>
            <form
              onSubmit={(event) => {
                return event.preventDefault(), handleSubmit();
              }}
            >
              <Grid
                container
                sx={{ marginTop: 5 }}
                id="user-form"
                className="user-form"
              >
                <Grid item md={4} sx={{ marginLeft: 20 }}>
                  <Typography>{messages.F_NAME}</Typography>
                  <OutlinedInput
                    type="text"
                    placeholder={messages.ENTER_F_NAME}
                    fullWidth
                    value={firstname}
                    onChange={(event) => setFirstName(event.target.value)}
                    required
                    onInvalid={(e) => (e.target.style.border = "1px solid red")}
                  />
                  <Typography>{messages.L_NAME}</Typography>
                  <OutlinedInput
                    className="firstname"
                    type="text"
                    value={lastname}
                    onChange={(event) => setLastName(event.target.value)}
                    placeholder={messages.ENTER_L_NAME}
                    fullWidth
                    required
                    onInvalid={(e) => (e.target.style.border = "1px solid red")}
                  />
                  <Typography>{messages.EMAIL}</Typography>
                  <OutlinedInput
                    className="firstname"
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    placeholder={messages.ENTER_EMAIL}
                    fullWidth
                    required
                    onInvalid={(e) => (e.target.style.border = "1px solid red")}
                  />
                </Grid>
                <Grid item md={4} sx={{ marginLeft: 15 }}>
                  <Typography>Username</Typography>
                  <OutlinedInput
                    className="firstname"
                    type="text"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    placeholder={messages.ENTER_USER_NAME}
                    fullWidth
                    required
                    onInvalid={(e) => (e.target.style.border = "1px solid red")}
                  />
                  {userId && (
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Update Password"
                        onChange={(event) => setIsPass(event.target.checked)}
                      />
                    </FormGroup>
                  )}
                  <Typography>Password</Typography>
                  <OutlinedInput
                    className="firstname"
                    type={passwordType ? "text" : "password"}
                    inputProps={{
                      pattern:
                        "^(?=.*[0-9])" +
                        "(?=.*[a-z])(?=.*[A-Z])" +
                        "(?=.*[@#$%^&+=])" +
                        "(?=\\S+$).{8,20}$",
                    }}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder="Password"
                    fullWidth
                    required
                    disabled={!isPass}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {passwordType ? (
                            <Visibility />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    onInvalid={(e) => (e.target.style.border = "1px solid red")}
                  />
                  <OutlinedInput
                    sx={{ marginTop: "2px" }}
                    className="firstname"
                    type={confirmPasswordType ? "text" : "password"}
                    value={confirmpass}
                    onChange={(event) => setConfirmPass(event.target.value)}
                    placeholder="Confirm password"
                    fullWidth
                    required
                    disabled={!isPass}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {confirmPasswordType ? (
                            <Visibility />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    onInvalid={(e) => (e.target.style.border = "1px solid red")}
                  />
                  <Typography sx={{ color: "red" }}>{message}</Typography>
                </Grid>
                <Grid md={4} sx={{ marginLeft: 20 }}>
                  <Box>
                    <FormControl
                      sx={{ m: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel component="legend">Group</FormLabel>
                      <FormGroup>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="group"
                          name="radio-buttons-group"
                          sx={{ height: "150px" }}
                        >
                          {groupData &&
                            groupData?.map((items) => {
                              return (
                                <FormControlLabel
                                  value={items.name}
                                  control={<Radio required />}
                                  checked={items.name == group}
                                  label={
                                    items.name.charAt(0).toUpperCase() +
                                    items.name.slice(1)
                                  }
                                  onChange={handleChange}
                                />
                              );
                            })}
                        </RadioGroup>
                      </FormGroup>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>

              <Box className="bottom-div">
                <Buttons
                  variant="contained"
                  size="large"
                  text="save"
                  type="submit"
                />
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
