import axios from "axios";

export default class AxiosServices {
  Post(url, data, header) {
    return axios.post(url, data, header);
  }

  Get(url, header) {
    return axios.get(url, header);
  }

  Delete(url, isHeaderRequired, header) {
    return axios.delete(url, isHeaderRequired && header);
  }

  Put(url, data, isHeaderRequired, header) {
    return axios.put(url, data, isHeaderRequired && header);
  }
}
