import { useState } from "react";
import { Box, Button, Divider, Popover, Typography } from "@mui/material";
import infoIcon from "../../../assets/info-svgrepo-com.svg";
import { messages } from "../../../constants/commonMessages";
import { fontSize } from "@mui/system";
import "./CellInfoMenu.scss";

const CellInfoMenu = ({ coordinatesValues, clearMappings }) => {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onClickClearMappings = () => {
    setOpen(null);
    clearMappings();
  };

  return (
    <>
      <button
        aria-label="more"
        aria-controls="long-menu"
        id="long-button"
        className="readMore-btn-cell-info"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <img
          // style={{ margin: "21px 3px 3px" }}
          src={infoIcon}
          alt="i"
        ></img>
      </button>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        className="info-popup"
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 140,
          },
        }}
      >
        <Box sx={{ py: 1, px: 1.4 }}>
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                color: "text.secondary",
                wordBreak: "break-all",
                fontSize: "14px",
                lineHeight: "14px",
              }}
            >
              {messages?.SELECTED_COORDINATES} -
              {coordinatesValues?.length >= 0 ? coordinatesValues?.length : 0}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />
        <Box sx={{ py: 1, px: 1.4 }}>
          <Box>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {coordinatesValues?.toString()}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1, fontSize: "12px", padding: 0 }}>
          <Button
            fullWidth
            disableRipple
            onClick={(e) => onClickClearMappings()}
          >
            {messages?.CLEAR_UPDATE_MAPPING}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default CellInfoMenu;
