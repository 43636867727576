import { Typography } from "@mui/material";
import React from "react";
import "./projectHeader.scss";
import "../../css/utility.scss";

export default function ProjectHeader(props) {
  const { text, headerColor, variant, position, textColor } = props;
  return (
    <div
      className="p-header"
      style={{
        display: "flex",
        background: headerColor,
        justifyContent: position,
      }}
    >
      <Typography
        className="project-title"
        variant="h6"
        style={{ color: textColor }}
      >
        {text}
      </Typography>
    </div>
  );
}
