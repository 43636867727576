import * as React from "react";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Header from "./header/Header";
import Label from "./label/Label";
import svg from "../assets/PageNotFound.jpg";
import { Grid } from "@mui/material";
import Buttons from "./Controls/buttons/Button";
import { useHistory } from "react-router";
import "./PageNotFound.scss";

const PageNotFount = () => {
  const history = useHistory();
  const handleHome = () => {
    if (localStorage.getItem("userRole") === "pre-processor") {
      history.push("pre-processor-dashboard");
    }
    if (localStorage.getItem("userRole") === "processor") {
      history.push("processor-dashboard");
    }
    if (localStorage.getItem("userRole") === "validator") {
      history.push("validator-dashboard");
    }
  };
  return (
    <React.Fragment>
      <Header />
      <>
        <Grid container className="main-pageNotFound-container">
          <Grid item xs={6}>
            <Box className="pageNotFound-data">
              <CardContent className="pageNotFound-card">
                <div className="card-info-wrap">
                  <Label
                    component="div"
                    text="404"
                    fontSize="200px"
                    fontWeight="600"
                    lineHeight="200px"
                    color="#000000"
                  />
                  <Label
                    fontSize="50px"
                    fontWeight="600"
                    lineHeight="78.02px"
                    color="#000000"
                    text="ooops..."
                  />
                  <Label
                    fontSize="40px"
                    fontWeight="300"
                    lineHeight="68px"
                    color="#000000"
                    text="Page Not Found"
                  />
                  <Label
                    fontWeight="600"
                    fontSize="18px"
                    padding="20px 0px 0px 0px"
                    color="#2D4642"
                    text="Sorry. The content you're looking for doesn't exist."
                  />
                  <Label
                    fontWeight="600"
                    fontSize="18px"
                    padding="0px 0px 20.94px 0px"
                    color="#2D4642"
                    text="Either it was removed, or you mistyped the link"
                  />
                </div>
                <div className="go-back-btn">
                  <Buttons
                    text="Go Back"
                    size="large"
                    margin="0px !important"
                    onClick={() => handleHome()}
                  />
                </div>
              </CardContent>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="pageNotFound-icon">
              <img src={svg} alt="Page Not Found"></img>
            </Box>
          </Grid>
        </Grid>
      </>
    </React.Fragment>
  );
};

export default PageNotFount;
