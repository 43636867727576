import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Buttons from "./Controls/buttons/Button";
import Label from "./label/Label";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const TimeOutModal = ({
  showModal,
  togglePopup,
  handleLogout,
  handleClose,
  remaining,
}) => {
  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  return (
    <div>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <div
            style={{
              display: "flex",
              justifyContent: " space-between",
            }}
          >
            <Label
              fontSize={20}
              fontWeight={600}
              text="You Have Been Idle!"
            ></Label>
            <Label
              fontSize={20}
              fontWeight={500}
              color="#1565c0"
              text={`${millisToMinutesAndSeconds(remaining)}`}
            >
              {" "}
            </Label>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Your session is about to expire in 2 minute due to inactivity. You
            will be redirected to the login page. You want to stay?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Buttons
            onClick={handleClose}
            color="primary"
            text="Stay Logged In"
          ></Buttons>
          <Buttons
            onClick={handleLogout}
            color="primary"
            text="Logout"
          ></Buttons>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TimeOutModal;
