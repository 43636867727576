import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Button,
  FormControl,
  Box,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

// import { cloneProject, getPreProProjects } from '../../../redux/pre_processor/actions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function PostProcessingModal({ open, onCloseClone, folderName, handlePostProcessing }) {

  const [selectedOption, setSelectedOption] = useState('');
  const dispatch = useDispatch();
  //const apiStatus = useSelector((state) => state?.prePro?.apiStatus);


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = () => {
    // Handle form submission here
    console.log('Selected Option:', selectedOption);
    handlePostProcessing(selectedOption)
    // You can perform any other actions here, such as sending data to a server
    onCloseClone(); // Close the modal after submission
  };

  return (
    <div>
      <Modal
        open={open}
      >
        <Box sx={style}>
          <CloseIcon
            // style={{float: "right"}}
            style={{ position: "absolute", right: "15px", top: "15px" }}
            onClick={() => onCloseClone()}
          />
          <Typography id="modal-modal-title"
            sx={{ mb: 2, mt: -2 }} variant="h8" component="h4">
            Select File
          </Typography>
          <FormControl fullWidth>
            <InputLabel>Select an option</InputLabel>
            <Select 
              endAdornment={<Button className="button-clone"
                onClick={handleSubmit}>Submit</Button>}
              value={selectedOption} onChange={handleOptionChange}>
              {folderName?.map((menuItem) => (
                <MenuItem key={menuItem.id} value={menuItem.id}>
                  {menuItem.name}
                </MenuItem>
              ))}             
            </Select>
          </FormControl>       
        </Box>
      </Modal>
    </div>
  );
}