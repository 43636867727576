import * as React from "react";
import moment from "moment";
import { useRef } from "react";
const MemoisedTextArea = React.memo(
  ({
    role,
    parametersData,
    mapping,
    mappingIndex,
    rowRejectionData,
    parameterIndex,
    value,
    onChange,
    onBlur,
    isDataRejectedByProcessor,
    reProcess,
    isDataRejectedByValidator,
    reValidate,
    onClickMapping,
    onClickCellToRedirect,
    clearMappings,
    entireBatchRejectedByPro,
    entireBatchRejectedByVali,
    reProcessEntireBatch,
  }) => {
    const textAreaRef = useRef(null);

    const styleRejectedCell = (
      mapping,
      mappingIndex,
      rowRejectionData,
      // valiRowRejectionDataInPro,
      // proRowRejectionDataInPre,
      // validatedData,
      parameterIndex
    ) => {
      let backgroundColor = "#FFFF";
      // if (role === "processor") {

      if (role === "preProcessor") {
        // color when row or cell rej by pro
        if (
          mapping?.processorCellRejection?.isRejected ||
          parametersData[parameterIndex]?.processorRowRejection?.isRejected
        ) {
          backgroundColor = "#fb040466";
        }

        // dark red color for both rejection in cell and in row
        if (
          mapping?.processorCellRejection?.isRejected &&
          parametersData[parameterIndex]?.processorRowRejection?.isRejected
        ) {
          // backgroundColor = "rgb(253 185 209)";
          backgroundColor = "rgb(253 97 71)";
        }

        // dark red color for both rejection in row
        if (
          rowRejectionData &&
          rowRejectionData.length > 0 &&
          rowRejectionData[mappingIndex]?.isRowRejectedByProcessor
        ) {
          // backgroundColor = "rgb(253 185 209)";
          backgroundColor = "rgb(253 97 71)";
        }
      }

      if (role === "processor") {
        // any one rejected in cell or row in both role
        if (
          mapping?.processorCellRejection?.isRejected ||
          mapping?.validatorCellRejection?.isRejected ||
          parametersData[parameterIndex]?.processorRowRejection?.isRejected ||
          parametersData[parameterIndex]?.validatorRowRejection?.isRejected
        ) {
          // backgroundColor = "#ff8686";
          backgroundColor = "rgb(255 137 116)";
        }

        // dark red color in cell for both role cell rejection only
        if (
          mapping?.processorCellRejection?.isRejected &&
          mapping?.validatorCellRejection?.isRejected
        ) {
          // backgroundColor = "rgb(253 185 209)";
          backgroundColor = "rgb(253 97 71)";
        }

        // dark red when cell and row rej by processor
        if (
          mapping?.processorCellRejection?.isRejected &&
          parametersData[parameterIndex]?.processorRowRejection?.isRejected
        ) {
          // backgroundColor = "rgb(253 185 209)";
          backgroundColor = "rgb(253 97 71)";
        }

        // dark red color for both role rejection in row
        if (
          parametersData[parameterIndex]?.processorRowRejection?.isRejected &&
          parametersData[parameterIndex]?.validatorRowRejection?.isRejected
        ) {
          // backgroundColor = "rgb(253 185 209)";
          backgroundColor = "rgb(253 97 71)";
        }

        // dark red if row rejected by vali and cell rej by pro
        if (
          parametersData[parameterIndex]?.validatorRowRejection?.isRejected &&
          // valiRowRejectionDataInPro[mappingIndex]?.isRowRejectedByValidator &&
          mapping?.processorCellRejection?.isRejected
        ) {
          backgroundColor = "rgb(253 97 71)";
        }

        // dark red if cell rejected by vali and row rej by pro
        if (
          mapping?.validatorCellRejection?.isRejected &&
          parametersData[parameterIndex]?.processorRowRejection?.isRejected
        ) {
          backgroundColor = "rgb(253 97 71)";
        }

        // dark red if cell rejected by vali and also row rej by vali
        if (
          mapping?.validatorCellRejection?.isRejected &&
          parametersData[parameterIndex]?.validatorRowRejection?.isRejected
        ) {
          backgroundColor = "rgb(253 97 71)";
        }
        // if cell is rej by vali and also row rejected and also cell rej by pro
        if (
          mapping?.validatorCellRejection?.isRejected &&
          parametersData[parameterIndex]?.validatorRowRejection?.isRejected &&
          mapping?.processorCellRejection?.isRejected
        ) {
          backgroundColor = "rgb(249 61 61)";
        }
        //  if cell is rej by vali and also row rejected and also row rej by pro
        if (
          mapping?.validatorCellRejection?.isRejected &&
          parametersData[parameterIndex]?.validatorRowRejection?.isRejected &&
          parametersData[parameterIndex]?.processorRowRejection?.isRejected
        ) {
          backgroundColor = "rgb(253 32 32)";
        }
        if (
          mapping?.processorCellRejection?.isRejected &&
          mapping?.validatorCellRejection?.isRejected &&
          parametersData[parameterIndex]?.validatorRowRejection?.isRejected &&
          parametersData[parameterIndex]?.processorRowRejection?.isRejected
        ) {
          backgroundColor = "rgb(235 44 109)";
        }
      }
      if (role === "validator") {
        if (
          mapping?.validatorCellRejection?.isRejected ||
          parametersData[parameterIndex]?.validatorRowRejection?.isRejected
        ) {
          // backgroundColor = "#ff8686";
          backgroundColor = "rgb(255 137 116)";
        }
        if (
          mapping?.validatorCellRejection?.isRejected &&
          parametersData[parameterIndex]?.validatorRowRejection?.isRejected
        ) {
          backgroundColor = "rgb(253 97 71)";
        }

        if (parametersData[parameterIndex]?.rowValidation?.isValidated) {
          backgroundColor = "#e5ffdb";
        }
      }

      return { backgroundColor };
    };

    const isDisable = (mapping, mappingIndex, parameterIndex) => {
      let isDisable = false;
      if (role === "preProcessor") {
        if (!entireBatchRejectedByPro) {
          if (isDataRejectedByProcessor) {
            isDisable =
              mapping?.processorCellRejection?.isRejected ||
              parametersData[parameterIndex]?.processorRowRejection?.isRejected
                ? false
                : true;
          }
        }
      }
      if (role === "processor") {
        if (!entireBatchRejectedByVali) {
          if (!reProcessEntireBatch) {
            if (reProcess) {
              isDisable = !(
                mapping?.processorCellRejection?.isResolved === true ||
                parametersData[parameterIndex]?.processorRowRejection
                  ?.isResolved
              );
            }
            if (isDataRejectedByValidator) {
              isDisable =
                mapping?.validatorCellRejection?.isRejected ||
                parametersData[parameterIndex]?.validatorRowRejection
                  ?.isRejected
                  ? false
                  : true;
            }

            if (reProcess && isDataRejectedByValidator) {
              // when data rejected by validator and processor and then resolved by pre processor at that time for both rejection we need to enable field
              let disableForProResolve = !(
                mapping?.processorCellRejection?.isResolved === true ||
                parametersData[parameterIndex]?.processorRowRejection
                  ?.isResolved
              );
              let disableForValidatorRejection =
                mapping?.validatorCellRejection?.isRejected ||
                parametersData[parameterIndex]?.validatorRowRejection
                  ?.isRejected
                  ? false
                  : true;

              if (
                disableForProResolve === false &&
                disableForValidatorRejection === true
              ) {
                isDisable = false;
              }
            }
          }
        }

        if (role === "validator") {
          if (reValidate) {
            isDisable = !(
              mapping?.validatorCellRejection?.isResolved === true ||
              parametersData[parameterIndex]?.validatorRowRejection?.isResolved
            );
          }
        }
      }
      return isDisable;
    };

    const disableText = isDisable(mapping, mappingIndex, parameterIndex)
      ? true
      : false;

    // const onChangeHandler = (event) => {
    //   onChange(event);
    // };

    const onCero = (e) => {
      if (e.keyCode === 8 || e.keyCode === 46) {
        // key code added for backspace and delete
        clearMappings();
      }
    };

    return (
      <textarea
        // onKeyDown={(e) => onCero(e)}
        ref={textAreaRef}
        className={
          disableText
            ? `disableCell`
            : role === "validator"
            ? "disable_opacity"
            : ""
        }
        id="outlined-basic"
        // variant="outlined"
        style={{
          ...styleRejectedCell(
            mapping,
            mappingIndex,
            rowRejectionData,
            parameterIndex
          ),
          ...{
            height:
              textAreaRef?.current?.scrollHeight <= 50
                ? 50
                : textAreaRef?.current?.scrollHeight,
            width: "99px",
          },
        }}
        value={value}
        onChange={
          role === "processor" &&
          !isDisable(mapping, mappingIndex, parameterIndex) &&
          onChange
        }
        onBlur={onBlur}
        onClick={(e) =>
          role === "preProcessor"
            ? !isDisable(mapping, mappingIndex, parameterIndex) &&
              onClickMapping(e, parameterIndex, mappingIndex)
            : onClickCellToRedirect(
                e,
                mapping?.tagName,
                mapping?.pageNo,
                mapping?.fileNo,
                parameterIndex,
                mappingIndex,
                // mapping?.indexesOfCoordinates,
                mapping?.coordinates,
                isDisable(mapping, mappingIndex, parameterIndex)
              )
        }
      />
    );
  }
);

const EditableCell = ({
  mapping,
  mappingIndex,
  parameterIndex,
  parametersData,
  setParametersData,
  styleRejectedCell,
  rowRejectionData,
  // valiRowRejectionDataInPro,
  // proRowRejectionDataInPre,
  role,
  onClickMapping,
  onClickCellToRedirect,
  isDisable,
  // validatedData,
  anyOperationPerformedOnEntirePage,
  reProcess,
  isDataRejectedByProcessor,
  isDataRejectedByValidator,
  reValidate,
  clearMappings,
  entireBatchRejectedByPro,
  entireBatchRejectedByVali,
  reProcessEntireBatch,
}) => {
  const textValue = (mapping) => {
    let value = "";

    if (role === "preProcessor") {
      value =
        mapping?.tagName && mapping.pageNo >= 0
          ? `${mapping?.tagName} - ${mapping.pageNo + 1}`
          : "";
    } else {
      value = mapping?.value;
    }
    return value;
  };
  const initialValue = textValue(mapping);
  const [value, setValue] = React.useState(initialValue);

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    // called only in pro role in vali cell is disabled so its not calling
    if (role !== "preProcessor") {
      if (
        parametersData[parameterIndex].mappings[mappingIndex]
        //  &&
        // parametersData[parameterIndex].mappings[mappingIndex]?.value !==
        //   undefined
      ) {
        parametersData[parameterIndex].mappings[mappingIndex] = {
          ...parametersData[parameterIndex].mappings[mappingIndex],
          value: value,
          ...(reProcess && {
            oldValue:
              parametersData[parameterIndex].mappings[mappingIndex]?.value,
          }),

          processor: localStorage?.userName,
          processedDate: moment(new Date()).format("DD-MMM-YYYY"),
        };
      }
      setParametersData([...parametersData]);
      if (!anyOperationPerformedOnEntirePage.current) {
        anyOperationPerformedOnEntirePage.current = true;
      }
    }
  };

  return (
    <MemoisedTextArea
      role={role}
      parametersData={parametersData}
      mapping={mapping}
      mappingIndex={mappingIndex}
      rowRejectionData={rowRejectionData}
      parameterIndex={parameterIndex}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      isDataRejectedByProcessor={isDataRejectedByProcessor}
      reProcess={reProcess}
      isDataRejectedByValidator={isDataRejectedByValidator}
      reValidate={reValidate}
      onClickMapping={onClickMapping}
      onClickCellToRedirect={onClickCellToRedirect}
      clearMappings={clearMappings}
      entireBatchRejectedByPro={entireBatchRejectedByPro}
      entireBatchRejectedByVali={entireBatchRejectedByVali}
      reProcessEntireBatch={reProcessEntireBatch}
    />
  );
};

export default EditableCell;
