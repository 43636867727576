import React, { useEffect, useState } from "react";
import Header from "../../../../components/header/Header";
import LeftSideBar from "../../../../components/LeftSideBar/LeftSideBar";
import { Grid, Box, Typography } from "@mui/material";
// import ProjectHeader from '../../../../components/header/ProjectHeader'
import AdministratorHeader from "../../../../components/header/AdministratorHeader";
import IconButton from "@mui/material/IconButton";
import { borderBottom } from "@mui/system";
import "./auditHistory.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ViewCarouselTwoTone } from "@mui/icons-material";

function Index() {
  const dateList = ["11 Feb 2011 | 11.30 pm", "12 Feb 2011 | 11.30 pm"];
  const [date, setDate] = useState();

  return (
    <>
      <Header page="new-project" />
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <AdministratorHeader text="History" isArrow="true" />
            <Grid item md={12}>
              <Box
                className="project-title"
                sx={{
                  marginTop: "20px",
                  paddingBottom: "10px",
                  borderBottom: "2px solid #B9B9B9",
                }}
              >
                <Box
                  sx={{
                    borderRight: "1px solid #202020",
                    height: "32px",
                    padding: "5px 15px",
                  }}
                >
                  <Typography sx={{ fontSize: "12px", marginTop: "-10px" }}>
                    Project Name
                  </Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                    Project 01
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderRight: "1px solid #202020",
                    height: "32px",
                    padding: "5px 10px",
                  }}
                >
                  <Typography sx={{ fontSize: "12px", marginTop: "-10px" }}>
                    Batch Name
                  </Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                    Batch 01
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderRight: "1px solid #202020",
                    height: "32px",
                    padding: "5px 10px",
                  }}
                >
                  <Typography sx={{ fontSize: "12px", marginTop: "-10px" }}>
                    PreProcessor
                  </Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                    Kripal
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderRight: "1px solid #202020",
                    height: "32px",
                    padding: "5px 10px",
                  }}
                >
                  <Typography sx={{ fontSize: "12px", marginTop: "-10px" }}>
                    Processor
                  </Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                    Diwakar
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderRight: "1px solid #202020",
                    height: "32px",
                    padding: "5px 10px",
                  }}
                >
                  <Typography sx={{ fontSize: "12px", marginTop: "-10px" }}>
                    Validator
                  </Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                    Priyanka
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ padding: "15px", display: "flex" }}>
                {dateList.map((e) => {
                  return (
                    <>
                      {
                        <Box
                          className={
                            date === e ? "date-time-div" : "date-time-div1"
                          }
                          onClick={() => {
                            setDate(e);
                          }}
                        >
                          {e}
                        </Box>
                      }
                    </>
                  );
                })}
              </Box>
              <Box className="auditHistory-wrap">
                <Table
                  // sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  {/* <TableHead>
                      <TableRow>
                        <TableCell>Dessert (100g serving)</TableCell>
                        <TableCell>Calories</TableCell>
                        <TableCell>Fat&nbsp;(g)</TableCell>
                        <TableCell>Carbs&nbsp;(g)</TableCell>
                        <TableCell>Protein&nbsp;(g)</TableCell>
                      </TableRow>
                    </TableHead> */}
                  <TableBody>
                    <TableRow>
                      <TableCell>Prepared On</TableCell>
                      <TableCell>test2</TableCell>
                      <TableCell>test3</TableCell>
                      <TableCell>test4</TableCell>
                      <TableCell>test5</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Wash Syringe Volume</TableCell>
                      <TableCell>test2</TableCell>
                      <TableCell>test3</TableCell>
                      <TableCell>test4</TableCell>
                      <TableCell>test5</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Dilution Factor For Cell Count</TableCell>
                      <TableCell>test2</TableCell>
                      <TableCell>test3</TableCell>
                      <TableCell>test4</TableCell>
                      <TableCell>test5</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Pre Dilution Factor</TableCell>
                      <TableCell>test2</TableCell>
                      <TableCell>test3</TableCell>
                      <TableCell>test4</TableCell>
                      <TableCell>test5</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total Cells/Ml</TableCell>
                      <TableCell>test2</TableCell>
                      <TableCell>test3</TableCell>
                      <TableCell>test4</TableCell>
                      <TableCell>test5</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Index;
