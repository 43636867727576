import React from "react";
import { useTable, usePagination } from "react-table";
import { Box } from "@mui/material";
import AddMoreColumnTH from "./AddMoreColumnTH";
import AddMoreRowTD from "./AddMoreRowTD";
import "./freeFrom.scss";
import EditableCell from "./EditableCell";
import FreeFormRowRejectAndReviewNew from "./FreeFormRowRejectAndReviewNew";
import ValidationButton from "./ValidationButton";
import Label from "../../label/Label";

const FixedHeader = ({ data, page, role }) => {
  const ShowValue = ({ cellValues }) => {
    let value = "";
    if (
      role === "preProcessor" &&
      cellValues?.tagName &&
      cellValues?.pageNo >= 0
    ) {
      value = `${cellValues?.tagName} - ${cellValues?.pageNo + 1}`; // pageno starting from 0 to show +1 is added
    }
    if (role !== "preProcessor") {
      value = cellValues?.value;
    }
    return value;
  };

  return Object.keys(page.values).map((key, index) => (
    <td>
      <div
        style={{
          backgroundColor: "inherit",
          padding: "4px",
        }}
        rows="1"
      >
        <ShowValue cellValues={data[key]} />
        {/* {data[key]?.tagName} - {data[key]?.tagName} */}
      </div>
    </td>
  ));
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

// Be sure to pass our updateMyData and the skipPageReset option
function Table({
  columns,
  data,
  updateMyData,
  setCellToUpdateToState,
  skipPageReset,
  addColInBetween,
  deleteColumn,
  addRowsInBetween,
  deleteRow,
  updateDataUsingManuallyContextMenu,
  anyOperationPerformedOnEntirePage,
  showPagination,
  showContextMenu,
  isDisableOnChangeInput,
  isDisableOnBlur,
  role,
  rejectCell,
  deleteRejection,
  handleRowRejection,
  deleteRowRejection,
  markAsResolveByProcessor,
  reProcess,
  reValidate,
  isDataRejectedByProcessor,
  isDataRejectedByValidator,
  markAsResolveRejection,
  // markRowAsResolve,
  onClickValidate,
  isFullView,
  clearMappings,
  entireBatchRejectedByPro,
  entireBatchRejectedByVali,
  reProcessEntireBatch,
}) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      setCellToUpdateToState,
      updateDataUsingManuallyContextMenu,
      anyOperationPerformedOnEntirePage,
      showContextMenu,
      isDisableOnChangeInput,
      isDisableOnBlur,
      role,
      rejectCell,
      deleteRejection,
      handleRowRejection,
      deleteRowRejection,
      markAsResolveByProcessor,
      reProcess,
      reValidate,
      isDataRejectedByProcessor,
      isDataRejectedByValidator,
      markAsResolveRejection,
      clearMappings,
      entireBatchRejectedByPro,
      entireBatchRejectedByVali,
      reProcessEntireBatch,
    },
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      <Box className={isFullView ? "create-table-wrap1" : "create-table-wrap"}>
        <table stickyHeader className="create-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {/* Left Side First row (Alphabets) First cell #Empty cell */}
                <th></th>

                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {/* {column.render("Header")} */}
                    <AddMoreColumnTH
                      column={column}
                      addColInBetween={addColInBetween}
                      deleteColumn={deleteColumn}
                      showColHeadersMenu={role !== "validator"}
                    />
                  </th>
                ))}

                {role === "processor" && (
                  <>
                    <th className="rejection-processor-label">
                      {" "}
                      <Label text="Pro. Rej." padding="0px 8px">
                        {" "}
                      </Label>{" "}
                    </th>
                    {role === "processor" && isDataRejectedByValidator && (
                      <th className="rejection-processor-label">
                        {" "}
                        <Label text="Vali. Rej." padding="0px 8px">
                          {" "}
                        </Label>
                      </th>
                    )}
                  </>
                )}

                {role === "preProcessor" && isDataRejectedByProcessor && (
                  <th className="rejection-pre-processor-label">
                    {" "}
                    <Label text="Pro. Rej." padding="0px 8px">
                      {" "}
                    </Label>{" "}
                  </th>
                )}
                {role === "validator" && (
                  <>
                    <th className="rejection-validator-label">
                      <Label text="Vali. Rej." padding="0px 8px">
                        {" "}
                      </Label>
                    </th>
                    <th className="rejection-validator-label">
                      <Label text="validatior" padding="0px 8px">
                        {" "}
                      </Label>
                    </th>
                  </>
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {/* Sticky Header Row From 2nd Page */}
            {pageIndex > 0 ? (
              <tr
                hover
                style={{
                  position: "sticky",
                  top: 43,
                  zIndex: 2,
                  backgroundColor: "#fff",
                  fontSize: "12px",
                }}
              >
                {/* Sticky deta 1st row 1st empty cell for pages onword 1st page*/}
                <td></td> {/* left side first empty cell */}
                {data[0] && (
                  <FixedHeader data={data[0]} page={page[0]} role={role} />
                )}
              </tr>
            ) : (
              ""
            )}

            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  {...(i == 0 && pageIndex === 0
                    ? {
                        style: {
                          position: "sticky",
                          top: 43,
                          backgroundColor:
                            i == 0 && pageIndex === 0
                              ? "rgb(248 249 250)"
                              : "white",
                          zIndex: 4,
                        },
                      }
                    : "")}
                >
                  <td>
                    <AddMoreRowTD
                      rowId={row?.id}
                      addRowsInBetween={addRowsInBetween}
                      deleteRow={deleteRow}
                      showRowHeadersMenu={role !== "validator"}
                    />
                  </td>

                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}

                  {/* last td from tr for rejection row wise */}

                  {(role === "processor" || role === "validator") && (
                    <td className="rejectCheckbox">
                      <FreeFormRowRejectAndReviewNew
                        // mappingsTotalLength={parametersData[0]?.mappings?.length} // taked length of first parameter because all other mapping length is same
                        handleRowRejection={handleRowRejection}
                        // rowRejectionData={rowRejectionData} // remove later not needed
                        parameterData={row?.original}
                        deleteRowRejection={deleteRowRejection}
                        role={role}
                        // isReadOnly={true}
                        // proRowRejectionData={proRowRejectionDataInPre}
                        markAsResolveRejection={markAsResolveRejection}
                        parameterIndex={row?.id}
                        isShowClearButton={false}
                        heading={"Do you want to reject the row?"}
                      />
                    </td>
                  )}
                  {/* 1st Checkbox in Pre processor after rejected by processor */}
                  {role === "preProcessor" && isDataRejectedByProcessor && (
                    <td className="rejectCheckbox">
                      <FreeFormRowRejectAndReviewNew
                        // mappingsTotalLength={parametersData[0]?.mappings?.length} // taked length of first parameter because all other mapping length is same
                        handleRowRejection={handleRowRejection}
                        // rowRejectionData={rowRejectionData}
                        parameterData={row?.original}
                        deleteRowRejection={deleteRowRejection}
                        role={role}
                        isReadOnly={true}
                        isDataRejectedByProcessor
                        // proRowRejectionData={proRowRejectionDataInPre}
                        markAsResolveRejection={markAsResolveRejection}
                        parameterIndex={row?.id}
                        isShowClearButton={false}
                        heading={"Row Rejection Remark."}
                      />
                    </td>
                  )}

                  {/* 2nd Checkbox in processor after rejected by vali */}
                  {role === "processor" && isDataRejectedByValidator && (
                    <td className="rejectCheckbox">
                      <FreeFormRowRejectAndReviewNew
                        // mappingsTotalLength={parametersData[0]?.mappings?.length} // taked length of first parameter because all other mapping length is same
                        handleRowRejection={handleRowRejection}
                        // rowRejectionData={rowRejectionData}
                        deleteRowRejection={deleteRowRejection}
                        role={role}
                        isReadOnly={true}
                        isDataRejectedByValidator
                        // validatorRowRejectionData={valiRowRejectionDataInPro}
                        markAsResolveRejection={markAsResolveRejection}
                        parameterData={row?.original}
                        parameterIndex={row?.id}
                        isShowClearButton={false}
                        heading={"Row rejection remark."}
                      />
                    </td>
                  )}

                  {/* Validation Button */}
                  {role === "validator" && (
                    <td className="border-remove">
                      <ValidationButton
                        // mappingsTotalLength={parametersData[0]?.mappings?.length} // taked length of first parameter because all other mapping length is same
                        onClickValidate={onClickValidate}
                        // rowRejectionData={rowRejectionData}
                        // validatedData={validatedData}
                        parameterData={row?.original}
                        parameterIndex={row?.id}
                      />
                    </td>
                  )}

                  {/* )} */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
      {showPagination && (
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span>
            | Go to page:{" "}
            <input
              className="gotoPage"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
          </span>{" "}
          <lable>Rows per page </lable>
          <select
            className="showsNoOfPage"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
}

Table.defaultProps = {
  showContextMenu: true,
  showPagination: true,
  isDisableOnChangeInput: false,
  isDisableOnBlur: false,
};

export default Table;
