import processorService from "../../services/processorService";
import { initialState } from "./initialState";
import { errorMessage } from "../../constants/responseMessages";
import {
  API_STATUS,
  GET_USER_LIST,
  IS_SIGNATURE_UPLOADED_STATUS,
  ASSIGN_USER_API_STATUS,
  VALIDATE_USER_STATUS,
  SET_PRO_PROJECTS,
  GET_PRO_LIST,
  GET_PRO_DATA,
  CLEAR_API_STATUS,
  PDF_AND_PAGE_DETAILS,
  SET_OCR_VALUE,
  UPDATE_VIEW_STATUS,
  SET_SELECTED_FILES_DATA_FOR_PRO_UPDATE,
} from "./types";

const service = new processorService();
export const getProjects = () => {
  return (dispatch) => {
    dispatch({ type: API_STATUS, payload: { message: "", loader: true } });
    service
      .getProjects()
      .then((res) => {
        let arr = res.data.data.processors_projects;
        let projectsList = arr.sort(
          (a, b) => new Date(b.updated_on) - new Date(a.updated_on)
        );
        dispatch({
          type: SET_PRO_PROJECTS,
          payload: arr,
        });
        dispatch({ type: API_STATUS, payload: { message: "", loader: false } });
      })
      .catch((error) => {
        dispatch({
          type: SET_PRO_PROJECTS,
          payload: [],
        });
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
          },
        });
      });
  };
};

export const getUserList = () => {
  return (dispatch) => {
    dispatch({
      type: GET_USER_LIST,
      payload: { data: [], loader: true, message: "" },
    });
    service
      .getUserList()
      .then((json) => {
        dispatch({
          type: GET_USER_LIST,
          payload: { data: json.data.data, loader: false, message: "" },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_USER_LIST,
          payload: { data: [], message: errorMessage(error), loader: false },
        });
      });
  };
};

export const clearIsSignatureUploadedStatus = () => {
  return (dispatch) => {
    dispatch({
      type: IS_SIGNATURE_UPLOADED_STATUS,
      payload: "",
    });
  };
};

export const apiStatusClear = () => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: {},
    });
  };
};
export const validateUserPermission = (
  userName,
  projectName,
  batch,
  next,
  end_date,
  subject,
  remark
) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { message: "", loader: true, next: next },
    });

    service
      .userPermission(userName, projectName, batch, end_date, subject, remark)
      .then((json) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            loading: false,
            next: true,
            message: json.data.message,
          },
        });
        dispatch({
          type: ASSIGN_USER_API_STATUS,
          payload: { message: "", loader: false, next: true },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
          },
        });
      });
  };
};
export const submitEsignBatch = (userName, userId, userRole, userPassword) => {
  return (dispatch) => {
    dispatch({
      type: VALIDATE_USER_STATUS,
      payload: { message: "", loader: true },
    });
    service
      .esignHandle(userName, userId, userRole, userPassword)
      .then((json) => {
        dispatch({
          type: VALIDATE_USER_STATUS,
          payload: { message: "", loader: false, next: true },
        });
      })
      .catch((error) => {
        dispatch({
          type: VALIDATE_USER_STATUS,
          payload: { message: errorMessage(error), loader: false },
        });
      });
  };
};
export const isSignatureUploaded = () => {
  return (dispatch) => {
    dispatch({
      type: IS_SIGNATURE_UPLOADED_STATUS,
    });
    service
      .isSignatureUploaded()
      .then((json) => {
        dispatch({
          type: IS_SIGNATURE_UPLOADED_STATUS,
          payload: {
            isSignatureUploaded: json?.data?.is_signature_uploaded,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: IS_SIGNATURE_UPLOADED_STATUS,
          payload: { message: errorMessage(error) },
        });
      });
  };
};

export const getProcessorList = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_PRO_LIST,
      payload: { ...initialState, loader: true },
    });
    service
      .getProcessorDashboard(data)
      .then((res) => {
        dispatch({
          type: GET_PRO_LIST,
          payload: { data: res.data.data, loader: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const getProData = (batchId, projectId) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    dispatch({
      type: GET_PRO_DATA,
      payload: [],
    });
    dispatch({
      type: PDF_AND_PAGE_DETAILS,
      payload: {},
    });
    service
      .getProData(batchId, projectId)
      .then((res) => {
        dispatch({
          type: API_STATUS,
          payload: { ...initialState?.apiStatus, loader: false },
        });

        dispatch({
          type: GET_PRO_DATA,
          payload: {
            ...res.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            loader: false,
            message: errorMessage(error),
          },
        });
      });
  };
};

export const clearApiStatus = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_API_STATUS,
      payload: {},
    });
  };
};

export const dataEntry = (data) => {
  // columnsCount used in free-form only
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .dataEntry(data)
      .then((res) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            loader: false,
            next: data?.next,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            loader: false,
            message: errorMessage(error),
          },
        });
      });
  };
};

export const pageRedirection = (data) => {
  return (dispatch) => {
    dispatch({
      type: PDF_AND_PAGE_DETAILS,
      payload: {
        data,
      },
    });
  };
};

export const setOCRValue = (value, coords) => {
  return (dispatch) => {
    dispatch({
      type: SET_OCR_VALUE,
      payload: { value, coords },
    });
  };
};

export const setSelectedFileDataForProUpdateMapping = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_FILES_DATA_FOR_PRO_UPDATE,
      payload: data,
    });
  };
};

export const rejectData = (data) => {
  // columnsCount used in free-form only
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true, isRejected: false },
    });
    service
      .rejectData(data)
      .then((res) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            loader: false,
            next: data?.next,
            isRejected: true,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            loader: false,
            message: errorMessage(error),
            isRejected: false,
          },
        });
      });
  };
};
export const validateUserClear = () => {
  return (dispatch) => {
    dispatch({
      type: VALIDATE_USER_STATUS,
      payload: {},
    });
  };
};

export const isProcessView = (batchId) => {
  return (dispatch) => {
    service
      .getProjectsViewed(batchId)
      .then((json) => {
        dispatch({
          type: UPDATE_VIEW_STATUS,
          payload: {},
        });
      })
      .catch((error) => {});
  };
};
