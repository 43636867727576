import React from "react";
import Header from "../../../components/header/Header";
import PdfCard from "../../../components/Card/PdfCard";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ProjectHeader from "../../../components/header/ProjectHeader";
import { styled } from "@mui/system";
import Footer from "../../../components/Footer";
import "./fileupload.css";
import Checkbox from "@mui/material/Checkbox";
import { ListItemText } from "@mui/material";
import * as acts from "../../../redux/pre_processor/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InputLabel from "@mui/material/InputLabel";
import ProgressBar from "../../../components/ProgressBar";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import ProjectStepper from "../../../components/Stepper/ProjectStepperCpvAuto";
import { notification } from "../../../constants/notificationMessages";
import ConstantValues from "../../../constants/ConstantValues";

const StyledSelect = styled(Select)({
  borderRadius: "10px",
  minWidth: "400px",
  height: "40px",
  marginBottom: "10px",
  border: 0,
});

class UploadBatchRecords extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      fileTagName: [],
      isFileUploaded: false,
      isErrorOccured: false,
      isLoading: false,
      isTagnameExist: false,
      // progressBar: false,
      fileNames: [],
      selectedFiles: [],
      preProFiles: [],
      isDataMapped: false,
      uploadPdfLoader: false,
      actualPdf: "",
      folderType: "",
      fileType: "actual_pdfs",
      subFolder: localStorage.drugType,
      invalidValidationErrorMessage: "",
      filesAddedToState: false,
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.props.acts.apiStatusClear();
    this.props.acts.getUploadedPdfsWithTags(localStorage.projectId);
    this.props.acts.getS3Files(this.state.fileType, this.state.subFolder);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.apiStatus && nextProps.apiStatus !== undefined) {
      if (nextProps.apiStatus.loader === false) {
        if (
          nextProps.apiStatus.message !== undefined ||
          nextProps.apiStatus.message !== ""
        ) {
          this.setState({
            uploadPdfLoader: false,
            errorMessage: nextProps.apiStatus.message,
          });
          if (nextProps.apiStatus.next === true) {
            this.setState({
              isFileUploaded: true,
            });
            if (localStorage.isCloned === "true"){
              setTimeout(() => {
                this.props.history.push("/map-data");
              }, 1000);
            }else{
              setTimeout(() => {
                this.props.history.push("/upload-template-data");
              }, 1000);
            }
            
            this?.props?.acts?.clearGetS3Files();
            this.props.acts.apiStatusClear();
          }
        }
        if (nextProps?.apiStatus.next === false && nextProps?.apiStatus.message != null) {
          this.setState({
            errorMessage: nextProps?.apiStatus.message,
            isErrorOccured: true,
           
          });
        }
      }
    }
    if (nextProps?.drugType && nextProps?.drugType.length) {
      this.setState({
        preProFiles: nextProps?.drugType,
      });
    }

    if (
      nextProps?.uploadedPdfAndTags &&
      nextProps?.uploadedPdfAndTags.length &&
      this?.state?.filesAddedToState === false
    ) {
      let fileNames = [];
      let fileData = [];
      nextProps?.uploadedPdfAndTags.map((file) => {
        fileNames.push(file?.original_filename);

        fileData.push({
          fileName: file?.original_filename,
          filePath: file?.pdfpath,
          tagName: file?.tagname,
          isChecked: true,
        });
      });
      this.setState({
        selectedFiles: fileData,
        fileNames: fileNames,
        filesAddedToState: true,
      });
    }
    if (
      nextProps?.uploadedPdfAndTags?.uploadedPdfAndTags?.data_entry === true
    ) {
      this.setState({
        isDataMapped: true,
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleMenuClick = (e, fileName, filePath, isRemove) => {
    let importedFileNames = this.state.fileNames;
    const isFileNameExist = importedFileNames.includes(fileName);
    // if () {
    if (!isRemove && !isFileNameExist) {
      this.state.selectedFiles.push({
        fileName,
        filePath,
      });
    } else if (isRemove) {
      const index = this.state.selectedFiles.findIndex(
        (x) => x.fileName === fileName
      );
      if (index > -1) {
        this.state.selectedFiles.splice(index, 1);
      }
    }
  };
  addTagName = async (fileNameWithTag) => {
    let selectedFiles = this.state.selectedFiles;
    let notContainSpecialCharacter = true;
    if (/[^a-zA-Z0-9\_]/.test(fileNameWithTag.tagName)) {
      notContainSpecialCharacter = false;
      this.setState({
        invalidValidationErrorMessage: notification.invalidValidationErrMsgTag,
      });
    }
    if (notContainSpecialCharacter) {
      if (selectedFiles.length > 0) {
        let isExist = false;

        selectedFiles.map((item) => {
          if (
            item.tagName &&
            item.tagName === fileNameWithTag.tagName &&
            !(item.fileName === fileNameWithTag.fileName)
          ) {
            this.setState({
              isErrorOccured: true,
              errorMessage: notification.tagAlreadyExistErr,
              isTagnameExist: true,
            });
            isExist = true;
          }
        });
        if (!isExist) {
          const selectedFilesWithTagName = selectedFiles.map((item, index) => {
            if (item.fileName === fileNameWithTag.fileName) {
              return {
                ...item,
                tagName: fileNameWithTag.tagName,
                isChecked: true,
              };
            } else {
              return { ...item };
            }
          });
          this.setState({ selectedFiles: selectedFilesWithTagName });
        }
      }
    }
  };

  handleEditTagName = (fileName) => {
    let selectedFiles = this.state.selectedFiles;
    this.setState({ invalidValidationErrorMessage: "" });
    if (selectedFiles.length > 0) {
      const selectedFilesWithTagName = selectedFiles.map((item, index) => {
        if (item.fileName === fileName) {
          return { ...item, isChecked: false };
        } else {
          return { ...item };
        }
      });
      this.setState({ selectedFiles: selectedFilesWithTagName });
    }
  };

  onRemovePdfAndTag = (fileName) => {
    const index = this.state.selectedFiles.findIndex(
      (x) => x.fileName === fileName
    );
    if (index > -1) {
      this.state.selectedFiles.splice(index, 1);
    }
    this.setState({ selectedFiles: this.state.selectedFiles });

    const index2 = this.state.fileNames.findIndex((x) => x === fileName);
    if (index2 > -1) {
      this.state.fileNames.splice(index, 1);
    }
  };
  handleDeleteFile = (fileName) => {
    var { selectedFiles, fileNames } = this.state;

    const deleteFile = selectedFiles.filter(
      (file) => file.fileName !== fileName
    );
    this.setState({
      selectedFiles: deleteFile,
    });

    const deleteFileName = fileNames.filter((file) => file !== fileName);
    this.setState({
      fileNames: deleteFileName,
    });
  };

  handleChange = (event) => {
    this.setState({
      fileNames: event.target.value,
    });
  };
  handleBtnClick = (event, action) => {
    const { selectedFiles } = this.state;
    this.setState({
      uploadPdfLoader: true,
    });

    var isTagExistForAll = selectedFiles.every(
      (file) => file.tagName && file?.isChecked
    );

    if (isTagExistForAll && this.state.invalidValidationErrorMessage === "") {
      if (action === "continue") {
        this.props.acts.apiStatusClear();
        this.props.acts.uploadFilesToServer(selectedFiles, true);
      }

      if (action === "save") {
        this.props.acts.apiStatusClear();
       
        this.props.acts.uploadFilesToServer(selectedFiles, true);
      }
      if (action === "previous") {
        this.props.history.push("/pre-processor-dashboard");
      }
    } else {
      this.setState({
        isErrorOccured: true,
        isFileUploaded: false,
        errorMessage: notification.tagNotSelectErr,
        isLoading: false,
        uploadPdfLoader: false,
      });
    }
  };

  handleTagNameChange = () => {
    this.setState({ invalidValidationErrorMessage: "" });
  };

  render() {
    const { UPLOAD_SAMPLE_BATCH_RECORD } = ConstantValues;
    return (
      <div>
        <Header />
        <ProjectStepper currentStep={1} />
        <ProjectHeader
          headerColor={"#F5F5F5"}
          text={localStorage.projectName + "-" + localStorage.drugType}
          position="center"
        />
        <Box sx={{ padding: "0 40px" }}>
          <Typography sx={{ m: 2, mb: 1 }} variant="subtitle2">
            {UPLOAD_SAMPLE_BATCH_RECORD}
          </Typography>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel
              id="demo-multiple-name-label"
              sx={{ margin: "-7px", paddingLeft: "10px" }}
            ></InputLabel>
            <StyledSelect
              displayEmpty
              multiple
              disabled={(localStorage.mapData === "true") && (localStorage.isCloned === "false") ? true : false}
              value={this.state.fileNames}
              onChange={this.handleChange}
              fullWidth
              sx={{
                background: "#4096FC",
                color: "#fff",
                border: "1px solid #4096FC",
              }}
              labelId="batch_files_upload"
              // label="Select"
              id="batch_files_upload"
              // renderValue={(selected) => selected.join(", ")}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select Sample Batch Records</em>;
                }

                return selected.join(", ");
              }}
              //inputProps={{ readOnly: this.state.isDataMapped }}
            >
              {this.state?.preProFiles?.map((list) => (
                <MenuItem
                  key={list?.file_name}
                  value={list?.file_name}
                  onClick={(e) =>
                    this.handleMenuClick(
                      e,
                      list.file_name,
                      list.file_url,
                      this.state.fileNames.indexOf(list.file_name) > -1
                    )
                  }
                >
                  <Checkbox
                    checked={this.state.fileNames.indexOf(list.file_name) > -1}
                  />
                  <ListItemText primary={list.file_name} />
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Box>
        {this.state.selectedFiles.length > 0 ? (
          <Box className="pdf-card-container"
           
          >
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {this.state.selectedFiles.map((file, index) => {
                return (
                  <Grid item xs={2} sm={4} md={3}>
                    <PdfCard
                      file={file}
                      onClickDeleteFile={(value) =>
                        this.handleDeleteFile(value)
                      }
                      addTagName={(fileNameWithTag) =>
                        this.addTagName(fileNameWithTag)
                      }
                      onRemovePdfAndTag={(value) =>
                        this.onRemovePdfAndTag(value)
                      }
                      // progressBar={this.progressBarEnd}
                      isProgressBarLoading={false}
                      isDataMapped={this.state.isDataMapped}
                      handleEditTagName={this.handleEditTagName}
                      handleTagNameChange={this?.handleTagNameChange}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ) : (
          ""
          // <div />
        )}
        {this.state.invalidValidationErrorMessage && (
          <label className="tag-name-error">
            {this.state.invalidValidationErrorMessage}
          </label>
        )}
        {/* useClear methode in further snackbar implementation which can auto clear those isErrorOccured and errorMessage message state */}
        <Footer
          onHandleClick={(event, action) => this.handleBtnClick(event, action)}
          isPrevious={false}
          isPreviousDisable={true}
          footerNextBtnText={this.props.footerNextBtnText}
          isNextDisable={
            (this.state.selectedFiles.length > 0 && !this.state.isDataMapped 
              ? false
              : true) || (localStorage?.mapData === "true" && localStorage?.isCloned === "false")
          }
          isSaveDisable={
            this.state.selectedFiles.length > 0 && !this.state.isDataMapped
              ? false
              : true
          }
          isLoading={this.state.isLoading}
        />
        {this.state.uploadPdfLoader && <ProgressBar />}

        {this.state.isLoading ? (
          <div className="ac-fullscreen-loader">
            <ProgressBar />
          </div>
        ) : (
          ""
        )}
        {this.state.isFileUploaded && (
          <CustomizedSnackbars
            severity="success"
            state={this.state.isFileUploaded}
            text={this.state.errorMessage}
            handleClose={() => this.setState({ isFileUploaded: false })}
          />
        )}
        {this.state.isErrorOccured && (
          <CustomizedSnackbars
            severity="error"
            state={this.state.isErrorOccured}
            text={this.state.errorMessage}
            handleClose={() => this.setState({ isErrorOccured: false })}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiStatus: state.prePro.apiStatus,
    uploadedPdfAndTags: state.prePro.uploadedPdfAndTags,
    drugType: state.prePro.drugType,
  };
};

// redux dispatch method
const mapDispatchToProps = (dispatch) => {
  return {
    acts: bindActionCreators(acts, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadBatchRecords);
