import AxiosService from "./axiosServices";
const axiosService = new AxiosService();
const configUrl = process.env.REACT_APP_APIBASE + "/";
class Service {
  Login(body) {
    const header = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    return axiosService.Post(`${configUrl}api/login/`, body, header);
  }

  userLogout = () => {
    const header = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.token,
      },
    };
    return axiosService.Get(`${configUrl}api/logout/`, header);
  };

  forgotPasswordLink = (userName, passwordResetLink) => {
    const data = {
      body: { username: userName, password_reset_link: passwordResetLink },
    };
    return axiosService.Post(`${configUrl}api/send-reset-password-link/`, data);
  };
  forgotPassword = (userName, newPassword) => {
    const data = {
      body: { username: userName, password: newPassword },
    };
    return axiosService.Post(`${configUrl}api/update-password/`, data);
  };
  saveUserSignature(filePath) {
    const header = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.token,
      },
    };

    const data = {
      body: { signature_path: filePath },
    };
    return axiosService.Post(
      `${configUrl}api/upload-e-signature/`,
      data,
      header
    );
  }
}

export default Service;
