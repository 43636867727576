import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import "./mapData.scss";

export default function MapInput(props) {
  const { label, addMappingRowCallback } = props;
  const [count, setCount] = useState("");

  const onHandleCheckbox = () => {
    addMappingRowCallback(count);
    setCount("");
  };

  const onChangeInput = (value) => {
    setCount(value);
  };
  return (
    <Box className="AddRow-wrap">
      <h6>{label}</h6>
      <Box className="addRow-inputField">
        <OutlinedInput
          sx={{ textAlign: "center" }}
          id="outlined-adornment-weight"
          value={count}
          placeholder="Add Columns"
          onChange={(e) => onChangeInput(e.target.value)}
          aria-describedby="outlined-weight-helper-text"
        />
        <IconButton
          className="rightClick-icon"
          onClick={(e) => onHandleCheckbox()}
        >
          <CheckOutlinedIcon></CheckOutlinedIcon>
        </IconButton>
      </Box>
    </Box>
  );
}
