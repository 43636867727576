import {
  Box,
  Select,
  Grid,
  MenuItem,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import Header from "../../../components/header/Header";
import Footer from "../../../components/Footer";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CloseIcon from "@mui/icons-material/Close";
import excelIcon from "../../../assets/excel.png";
import IconButton from "@mui/material/IconButton";
import "./uploadTemplateData.scss";
import { useDispatch } from "react-redux";
import * as acts from "../../../redux/pre_processor/actions";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import ProgressBar from "../../../components/ProgressBar";
import ProjectHeader from "../../../components/header/ProjectHeader";
import ProjectStepper from "../../../components/Stepper/ProjectStepperCpvAuto";
import constants from "../../../constants/ConstantValues";

const UploadTemplateData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const uploadedTemplateAndParameters = useSelector(
    (state) => state?.prePro?.uploadedTemplateAndParameters
  );
  const apiStatus = useSelector((state) => state?.prePro?.apiStatus);
  const { DATA_PARAMETER, DATA_FORMAT } = constants;
  const [message, setMessage] = useState("");
  const [templateUploadSuccess, setTemplateUploadSuccess] = useState(false);
  const [templateUploadError, setTemplateUploadError] = useState(false);
  const [opDataParameters, setOpDataParameters] = useState({
    loading: false,
    error: false,
    errorMsg: "",
    data: [],
  });
  const [opDataFormat, setOpDataFormat] = useState({
    loading: false,
    error: false,
    errorMsg: "",
    data: [],
  });

  const [selectedData, setSelectedData] = useState({
    templateName: "",
    templatePath: "",
    opFormatName: "",
    opFormatPath: "",
  });
  const fileType = "templates";
  const subFolder = localStorage.drugType;
  const drugType = useSelector((state) => state?.prePro?.drugType);
  const outpuFormat = useSelector((state) => state?.prePro?.outpuFormat);
  const [actionType, setActionType] = useState("");
  const StyledCard = styled(Card)({
    maxWidth: "600px ",
    background: "#D9F5D0",
    border: "1px solid #ECECEC",
    borderRadius: "10px",
    marginTop: "15px",
    height: "50px",
    paddingBottom: "20px",
    paddingRight: "10px",
    paddingLeft: "10px",
  });

  useEffect(() => {
    dispatch(acts.getUploadedTemplateAndOpFormat(localStorage.projectId));
    dispatch(acts.clearApiStatus());
    dispatch(acts.getS3Files(fileType, subFolder));
    dispatch(acts.getOutputformat(subFolder));
  }, [dispatch]);

  useEffect(() => {
    if (uploadedTemplateAndParameters) {
      setSelectedData({
        ...selectedData,
        templateName: uploadedTemplateAndParameters?.template,
        templatePath: uploadedTemplateAndParameters?.template_path,
        opFormatName: uploadedTemplateAndParameters?.output_format,
        opFormatPath: uploadedTemplateAndParameters?.op_file_path,
      });
    }
  }, [uploadedTemplateAndParameters]);

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus.loader === false) {
        if (apiStatus.message != undefined || apiStatus.message != "") {
          if (apiStatus.next === true) {
            setTemplateUploadSuccess(true);
            setMessage(apiStatus.message);
            setTimeout(() => {
              history.push("/map-data");
            }, 2000);
          } else if (apiStatus?.next === false && apiStatus?.message != null) {
            setMessage(apiStatus.message);
            setTemplateUploadError(true);
          }
        }
      }
    }
    if (drugType && drugType.length) {
      setOpDataParameters({
        loading: false,
        error: false,
        errorMsg: "",
        data: drugType,
      });
    }
    if (outpuFormat && outpuFormat.length) {
      setOpDataFormat({
        loading: false,
        error: false,
        errorMsg: "",
        data: outpuFormat,
      });
    }
  }, [apiStatus, drugType, outpuFormat]);

  const handleTemplateMenuClick = (templateName, templatePath) => {
    setSelectedData({
      ...selectedData,
      templateName: templateName,
      templatePath: templatePath,
      opFormatName: "",
      opFormatPath: "",
    });
  };

  const handleOpFormatMenuClick = (opFormatName, opFormatPath) => {
    setSelectedData({
      ...selectedData,
      opFormatName: opFormatName,
      opFormatPath: opFormatPath,
    });
  };

  const onClick = (event, type) => {
    setActionType(type);
    if (type === "save") {
      dispatch(
        acts.uploadTemplateAndOpFormat({
          ...selectedData,
          projectId: localStorage.projectId,
          next: false,
        })
      );
    } else if (type === "continue") {
      dispatch(
        acts.uploadTemplateAndOpFormat({
          ...selectedData,
          projectId: localStorage.projectId,
          next: true,
        })
      );
    } else if (type === "previous") {
      history.push("/upload-batch-record");
    }
  };
  return (
    <div>
      <Header></Header>
      <ProjectStepper currentStep={2} />
      <ProjectHeader
        position="center"
        headerColor="#F5F5F5"
        text={localStorage.projectName + "-" + localStorage.drugType}
      />
      <Box>
        <Grid container className="upload-template-wrap">
          <Grid item className="upload-template-info">
            <h6>{DATA_PARAMETER}</h6>
            <FormControl
              fullWidth={true}
              disabled={localStorage.mapData ? true : false}
            >
              <Select
                disabled={localStorage?.mapData === "false" ? false : true}
                className="upload-select-dropdown"
                labelId="data_param_upload"
                id="data_param_upload"
                placeholder="select"
                value={selectedData?.templateName}
                input={<OutlinedInput />}
                label="Select File"
              >
                {opDataParameters &&
                  opDataParameters?.data?.map((name, index) => (
                    <MenuItem
                      key={name?.file_name}
                      value={name?.file_name}
                      onClick={(e) =>
                        handleTemplateMenuClick(name.file_name, name.file_url)
                      }
                    >
                      {name?.file_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {selectedData?.templateName && (
              <StyledCard>
                <CardHeader
                  sx={{ margin: "-7px" }}
                  avatar={<img alt="xlsx" src={excelIcon}></img>}
                  action={
                    <IconButton
                      aria-label="settings"
                      disabled={
                        localStorage?.mapData === "false" ? false : true
                      }
                    >
                      <CloseIcon
                        style={{ color: "#525863" }}
                        onClick={() =>
                          setSelectedData({
                            ...selectedData,
                            templateName: "",
                            templatePath: "",
                            opFormatName: "",
                            opFormatPath: "",
                          })
                        }
                      />
                    </IconButton>
                  }
                  subheader={selectedData?.templateName}
                />
                <CardActions disableSpacing></CardActions>
              </StyledCard>
            )}
          </Grid>
          <Grid item className="upload-template-info">
            <h6>{DATA_FORMAT}</h6>
            <FormControl fullWidth={true}>
              <Select
                labelId="data_format_upload"
                disabled={
                  selectedData?.templateName === "" ||
                    selectedData?.templateName === null ||
                    localStorage?.mapData === "true"
                    ? true
                    : false
                }
                id="data_format_upload"
                placeholder="select"
                value={selectedData?.opFormatName}
                label="Age"
                input={<OutlinedInput />}
                className="upload-select-dropdown"
              >
                {opDataFormat &&
                  opDataFormat?.data?.map((name, index) => (
                    <MenuItem
                      key={name?.file_name}
                      value={name?.file_name}
                      onClick={(e) =>
                        handleOpFormatMenuClick(name.file_name, name.file_url)
                      }
                    >
                      {name?.file_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {selectedData?.opFormatName && (
              <StyledCard>
                <CardHeader
                  sx={{ margin: "-7px" }}
                  avatar={<img alt="xlsx" src={excelIcon}></img>}
                  action={
                    <IconButton
                      aria-label="settings"
                      disabled={
                        localStorage?.mapData === "false" ? false : true
                      }
                    >
                      <CloseIcon
                        style={{ color: "#525863" }}
                        onClick={() =>
                          setSelectedData({
                            ...selectedData,
                            opFormatName: "",
                          })
                        }
                      />
                    </IconButton>
                  }
                  subheader={selectedData?.opFormatName}
                />
                <CardActions disableSpacing></CardActions>
              </StyledCard>
            )}
          </Grid>
        </Grid>
        <div>
          {apiStatus && apiStatus.loader ? <ProgressBar /> : ""}

          {templateUploadSuccess && (
            <CustomizedSnackbars
              severity="success"
              state={templateUploadSuccess}
              text={message}
              handleClose={() => setTemplateUploadSuccess(false)}
            />
          )}

          {templateUploadError && (
            <CustomizedSnackbars
              severity="error"
              state={templateUploadError}
              text={message}
              handleClose={() => setTemplateUploadError(false)}
            />
          )}
        </div>
      </Box>
      <Footer
        isSaveDisable={
          selectedData?.templateName !== "" && selectedData?.opFormatName === ""
        }
        isNextDisable={
          (selectedData?.templateName !== "" && selectedData?.opFormatName === "") || localStorage?.mapData === "true"
        }
        isPrevious={true}
        onHandleClick={onClick}
      />
    </div>
  );
};
export default UploadTemplateData;
