import React from "react";
import { dataTypes } from "../../dataTypes";

const Selector = React.memo(
  ({ dataType, role, onHandleChangeDatatype, disabled }) => {
    return (
      <select
        style={{ width: "100%" }}
        value={dataType ? dataType : ""}
        onChange={onHandleChangeDatatype}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        disabled={role !== "preProcessor" || disabled}
      >
        <option value="">
          <em>Datatype</em>
        </option>
        {dataTypes.map((item, index) => {
          return (
            <option value={item?.value} key={item?.value}>
              {item.type}
            </option>
          );
        })}
      </select>
    );
  }
);

const DataTypeSelector = ({
  parameterData,
  onHandleChangeDatatype,
  parameterIndex,
  role,
  isDataRejectedByProcessor,
  entireBatchRejectedByPro,
}) => {
  const isDisable = () => {
    let isDisable = false;
    if (!entireBatchRejectedByPro) {
      if (isDataRejectedByProcessor) {
        let isAnyCellRejected = parameterData?.mappings?.some(
          (ele) => ele?.processorCellRejection?.isRejected
        );

        isDisable =
          isAnyCellRejected || parameterData?.processorRowRejection?.isRejected
            ? false
            : true;
      }
    }

    return isDisable;
  };

  return (
    <Selector
      dataType={parameterData?.dataType}
      onHandleChangeDatatype={(e) =>
        onHandleChangeDatatype(
          e.target.value,
          parameterData?.parameter,
          parameterIndex
        )
      }
      parameter={parameterData?.parameterData}
      role={role}
      parameterIndex={parameterIndex}
      disabled={isDisable()}
    />
  );
};

export default DataTypeSelector;
