import React from "react";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import Carousel from "react-multi-carousel";
import PdfICon from "../../../components/icons/PdfIcon";
import MapToolBar from "../../../components/mapData/MapToolBar";
import PrismaZoom from "react-prismazoom";
import ImageMapper from "react-img-mapper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as acts from "../../../redux/validator/actions";
import Label from "../../../components/label/Label";
import AngleArrowRight from "../../../assets/angle-circle-right-icon.svg";
import AngleArrowLeft from "../../../assets/angle-circle-left-icon.svg";

const styles = {
  margin: 0,
  fontSize: "10px",
  color: "#202020",
  fontfamily: "Montserrat",
};

const CustomLeftArrow = ({ onClick }) => (
  <button
    onClick={() => onClick()}
    className="slick-prev"
    variant="contained"
    size="medium"
    color="primary"
  >
    Prev
  </button>
);
const CustomRightArrow = ({ onClick }) => {
  return (
    <button
      className="slick-next"
      onClick={() => onClick()}
      variant="contained"
      size="medium"
      color="primary"
    >
      Next
    </button>
  );
};

class ImageViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coorData: [],
      selectedFile: {
        tagName: "",
        fileNo: 0, // index is considered as file
        pageNo: 0,
      },

      zoom: 1,
      prismaZoom: React.createRef(),
      allowZoom: true,
      responsive: {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
      },
      pageRotateAngle: 0,
      imageLoaded: false,
      selectedArea: {},
      data: [],
      // page: 0,
      originalMapperWidth: 600,
      originalMapperHeight: 768,
      imageMapperWidth: 600,
      imageMapperHeight: 768,
    };
    // Create the child instance using react createRef
    this.child = React.createRef();
    this.checkIsImageLoadable = this.checkIsImageLoadable.bind(this);
  }

  componentDidMount() {
    this.props.acts.clearApiStatus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // used this to set first pdf selected in initial state  and temp setting some initialn data
    if (nextProps?.coorData.length > 0) {
      const initialFileIndex = 0;
      this.setState({
        selectedFile: {
          tagName: nextProps?.coorData[0]?.pdfName,
          fileNo: 0,
          pageNo: 0,
        },
      });

      if (nextProps?.coorData?.length) {
        let sortedCoorData = nextProps?.coorData;

        sortedCoorData.map((fileData, index) => {
          const sortedPagesData = fileData.pages.sort((a, b) => {
            return parseInt(a.pageNo) - parseInt(b.pageNo);
          });
          sortedCoorData[index]["pages"] = sortedPagesData;
        });

        this.setState({ data: { ...sortedCoorData[0]?.pages[0] } }); // initial page setting
      }

      this.checkIsImageLoadable(initialFileIndex, 0); // the second argument is current page number
    }

    if (
      nextProps?.pdfAndPageDetails &&
      Object.keys(nextProps?.pdfAndPageDetails).length !== 0 &&
      nextProps?.pdfAndPageDetails?.fileNo >= 0 &&
      nextProps?.pdfAndPageDetails?.pageNo >= 0 &&
      nextProps?.pdfAndPageDetails?.tagName
    ) {
      const dataToSet =
        this?.props?.coorData[nextProps?.pdfAndPageDetails?.fileNo]?.pages[
          nextProps?.pdfAndPageDetails?.pageNo
        ];
      this.setState({
        selectedFile: {
          // ...this.state.selectedFile,
          tagName: nextProps?.pdfAndPageDetails?.tagName,
          fileNo: nextProps?.pdfAndPageDetails?.fileNo,
          pageNo: nextProps?.pdfAndPageDetails?.pageNo,
        },
        pageRotateAngle: 0,
      });

      if (Object.keys(dataToSet).length !== 0) {
        let newAreas = [];
        nextProps?.pdfAndPageDetails?.coordinates?.map(
          (coords, indexOfCoodinates) => {
            const newAreaObj = {
              shape: "rect",
              coords: coords,
              preFillColor: "#ff00002e",
              fillColor: "#ff00002e",
            };
            newAreas.push(newAreaObj);
          }
        );

        this.setState({
          data: { ...dataToSet, areas: [...newAreas] },
        });
      }
    }
  }

  clicked = (area, index, e) => {
    this.setState({
      selectedArea: area,
    });

    const dataToUpdate = this.state.data;
    dataToUpdate.areas[index] = {
      ...this.state.data.areas[index],
      preFillColor: "#ff00002e",
      fillColor: "#ff00002e",
    };
    this.setState({
      data: { ...dataToUpdate },
    });
  };

  checkIsImageLoadable = (fileIndex, pageNo) => {
    this.setState({ imageLoaded: false });
    if (this.props?.coorData[fileIndex]?.pages[pageNo]?.imageUrl) {
      const objImg = new Image();
      objImg.src = this.props?.coorData[fileIndex]?.pages[pageNo]?.imageUrl;
      objImg.onload = () => {
        // setTimeout(function () {
        this.setState({ imageLoaded: true });
        // }, 2000);
      };
      objImg.onerror = () => {
        this.setState({ imageLoaded: true });
      };
    } else {
      this.setState({ imageLoaded: true });
    }
  };

  fitToScreenClick = () => {
    this.state.prismaZoom.current.reset();
    this.setState({
      pageRotateAngle: 0,
      imageMapperWidth: this?.state?.originalMapperWidth,
      imageMapperHeight: this?.state?.originalMapperHeight,
    });
  };

  rotateClick = (value) => {
    if (value === "right") {
      this.setState({ pageRotateAngle: this.state.pageRotateAngle + 90 });
    }
    if (value === "left") {
      this.setState({ pageRotateAngle: this.state.pageRotateAngle - 90 });
    }
  };

  onZoomChange = (zoom) => {
    this.setState({
      zoom: zoom,
    });
  };

  onClickZoom = (value) => {
    if (value === "out") {
      this.state.prismaZoom.current.zoomOut(1);
    } else {
      this.state.prismaZoom.current.zoomIn(1);
    }
  };

  searchPageClick = (value) => {
    if (
      value <
        this.props?.coorData[this.state.selectedFile?.fileNo].pages.length &&
      value >= 0
    ) {
      this.setState({
        selectedFile: {
          ...this.state.selectedFile,
          pageNo: Number(value),
        },
        pageRotateAngle: 0,
        data: this.props?.coorData[this.state?.selectedFile?.fileNo]?.pages[
          Number(value)
        ],
        imageMapperWidth: this?.state?.originalMapperWidth,
        imageMapperHeight: this?.state?.originalMapperHeight,
      });

      this.state.prismaZoom.current.reset();
      this.checkIsImageLoadable(this.state.selectedFile.fileNo, Number(value));
    }
  };

  handlePageChange = (value) => {
    if (value === "prev" && this.state.selectedFile?.pageNo !== 0) {
      const prevPageNo = this.state.selectedFile?.pageNo - 1;
      this.setState({
        selectedFile: {
          ...this.state.selectedFile,
          pageNo: prevPageNo,
        },
        pageRotateAngle: 0,
        data: this.props?.coorData[this.state?.selectedFile?.fileNo]?.pages[
          prevPageNo
        ],
      });

      this.state.prismaZoom.current.reset();
      this.checkIsImageLoadable(this.state.selectedFile.fileNo, prevPageNo);
    } else if (value === "next") {
      const nextPageNo = this.state.selectedFile?.pageNo + 1;

      this.setState({
        selectedFile: {
          ...this.state.selectedFile,
          pageNo: nextPageNo,
        },
        pageRotateAngle: 0,
        data: this.props?.coorData[this.state?.selectedFile?.fileNo]?.pages[
          nextPageNo
        ],
      });
      this.state.prismaZoom.current.reset();
      this.checkIsImageLoadable(this.state.selectedFile.fileNo, nextPageNo);
    }
    this.setState({
      imageMapperWidth: this?.state?.originalMapperWidth,
      imageMapperHeight: this?.state?.originalMapperHeight,
    });
  };

  handleFileChange(tagName, index) {
    this.setState({
      selectedFile: {
        tagName: tagName,
        fileNo: index,
        pageNo: 0,
      },
      pageRotateAngle: 0,
      data: this?.props?.coorData[index]?.pages[0],
      imageMapperWidth: this?.state?.originalMapperWidth,
      imageMapperHeight: this?.state?.originalMapperHeight,
    });

    this.state.prismaZoom.current.reset();
    this.checkIsImageLoadable(index, 0);
  }

  onClickCellToRedirect = (tagName, pageNo, fileNo) => {
    if (tagName && !isNaN(pageNo) && !isNaN(fileNo)) {
      this.setState({
        selectedFile: {
          tagName: tagName,
          fileNo: fileNo,
          pageNo: pageNo,
        },
        pageRotateAngle: 0,
        data: this?.props?.coorData[fileNo]?.pages[pageNo],
      });
    }

    this.state.prismaZoom.current.reset();
    this.checkIsImageLoadable(fileNo, 0);
  };

  onClickChangeOrientation = () => {
    this.setState({
      imageMapperWidth: this?.state?.imageMapperHeight,
      imageMapperHeight: this?.state?.imageMapperWidth,
    });
  };

  render() {
    return (
      <Box className={this?.props?.isFullView ? "container-2" : "container-1"}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={10}>
            <div className="ac-processor-pdf-slider-body">
              <section>
                <div>
                  <Carousel
                    ssr
                    partialVisible
                    deviceType={this.props.deviceType}
                    itemClass="image-item"
                    responsive={this.state.responsive}
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                  >
                    {this.props?.coorData?.map((file, index) => {
                      return (
                        <div
                          className="ac-processor-slider-content"
                          onClick={() =>
                            this.handleFileChange(file.pdfName, index)
                          }
                          // key={index}
                        >
                          <PdfICon
                            fillColor={
                              this.state.selectedFile?.fileNo === index
                                ? "#E52222"
                                : "#5d5d5d"
                            }
                            height={
                              this.state.selectedFile?.fileNo === index
                                ? "25px"
                                : "25px"
                            }
                            width={
                              this.state.selectedFile?.fileNo === index
                                ? "25px"
                                : "25px"
                            }
                          />

                          <Label
                            text={file.pdfName}
                            fontSize="10px"
                            color="#373D4B"
                          ></Label>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </section>
            </div>
          </Grid>
          <Grid item xs={2} style={{ display: "flex", padding: "5px 0px" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  sx={{
                    padding: "4px",
                    ...(this.state.selectedFile?.pageNo + 1 <= 1
                      ? {
                          opacity: "0.5",
                          cursor: "not-allowed",
                          pointerEvents: "none !important",
                        }
                      : ""),
                  }}
                  // disabled={true}
                  onClick={(e) =>
                    this.state.selectedFile?.pageNo !== 0 &&
                    this?.handlePageChange("prev")
                  }
                >
                  <img
                    style={{ color: "#e72626" }}
                    src={AngleArrowLeft}
                    alt="prev page"
                  />
                </IconButton>

                <IconButton
                  sx={{
                    padding: "4px",
                    ...(this.state.selectedFile?.pageNo + 1 >=
                    this.props?.coorData[this.state.selectedFile?.fileNo]?.pages
                      ?.length
                      ? {
                          opacity: "0.5",
                          cursor: "not-allowed",
                          pointerEvents: "none !important",
                        }
                      : ""),
                  }}
                  onClick={(e) =>
                    this.state.selectedFile?.pageNo + 1 !==
                      this.props?.coorData[this.state.selectedFile?.fileNo]
                        ?.pages?.length && this?.handlePageChange("next")
                  }
                >
                  <img
                    style={{ color: "#ECECEC" }}
                    src={AngleArrowRight}
                    alt="next page"
                  />
                </IconButton>
              </div>

              <>
                <h1 style={{ ...styles }}>{`Page ${
                  this.state.selectedFile?.pageNo + 1
                }/ ${
                  this.props?.coorData[this.state.selectedFile?.fileNo]?.pages
                    ?.length
                }`}</h1>
              </>
            </div>{" "}
            <MapToolBar
              file={this.props?.coorData[this.state.selectedFile?.fileNo]}
              handlePageChange={this.handlePageChange}
              selectedFile={this.state.selectedFile}
              searchPageClick={this.searchPageClick}
              onClickZoom={this.onClickZoom}
              zoom={this.state.zoom}
              rotateClick={this.rotateClick}
              fitToScreenClick={this.fitToScreenClick}
              onClickChangeOrientation={this.onClickChangeOrientation}
            />
          </Grid>
        </Grid>
        <Grid className="view_pdf" md={12}>
          <div
            id="container"
            style={{
              transform: `rotate(${this.state.pageRotateAngle}deg)`,
              transition: "transform 150ms ease", // smooth transition
              cursor: "grab",
              left: [
                90, 270, 450, 630, 810, 990, 1170, 1350, 1530, 1710, 1890,
              ].includes(this.state.pageRotateAngle)
                ? "14%"
                : 0,
              position: "relative",
            }}
          >
            <PrismaZoom
              maxZoom={10}
              animDuration={0.5}
              allowZoom={this.state.allowZoom}
              ref={this.state.prismaZoom}
              onZoomChange={this.onZoomChange}
            >
              {this.state?.imageLoaded ? (
                <ImageMapper
                  src={this.state?.data?.imageUrl}
                  map={this.state?.data}
                  // width={600}
                  // height={768}
                  width={this?.state?.imageMapperWidth}
                  height={this?.state?.imageMapperHeight}
                  onClick={(area, index, e) => this.clicked(area, index, e)}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 20,
                    height: 768,
                  }}
                >
                  <CircularProgress />
                  <span
                    style={{
                      paddingLeft: "15px",
                      paddingTop: 10,
                    }}
                  >
                    Loading..
                  </span>
                </Box>
              )}
              )
            </PrismaZoom>
          </div>
        </Grid>
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    acts: bindActionCreators(acts, dispatch),
  };
};

function mapStateToProps(state) {
  return {
    coorData: state.vali?.coorData?.coordinatesDetails || [],
    apiStatus: state.vali?.apiStatus,
    pdfAndPageDetails: state.vali?.pdfAndPageDetails,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewer);
