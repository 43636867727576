import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import DateRangePicker from "../DateRangePicker";
import "../AuditTable.scss";
import { IconButton } from "@mui/material";
import DropDownSearch from "../Controls/searchBar/DropDownSearch";
import DescriptionIcon from "@mui/icons-material/Description";
import JsonViewerModal from "../Modal/jsonmodal/JsonViewerModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getUserActivity,
  getManageGroup,
  getUsersByGroup,
} from "../../redux/administrator/actions";
import moment from "moment";
import TablePagination from "@mui/material/TablePagination";
import { useHistory } from "react-router-dom";
import CustomizedSnackbars from "../snackbars/CustomizedSnackbars";

export default function AuditActivityTable(props) {
  const dispatch = useDispatch();
  const activity = [
    {
      id: "1",
      name: "User Activity",
    },
    { id: "2", name: "Data History" },
  ];
  const userActivityList = useSelector((state) => state?.admin.userActivity);
  const groups = useSelector((state) => state?.admin?.groupDetails);
  const userByGroup = useSelector((state) => state?.admin?.userNameByGroup);
  const [activityList, setActivityList] = useState({});
  const [userGroup, setUserGroup] = useState([]);
  const [userName, setUserName] = useState([]);
  const [filteredGroup, setFilteredGroup] = useState("");
  const [filteredName, setFilteredName] = useState("");
  const [open, setOpen] = useState(false);
  const [pageCount, setPageCount] = useState(
    userActivityList?.total_count || 0
  );
  const apiStatus = useSelector((state) => state?.admin?.uploadData);
  console.log(apiStatus);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getUserActivity({
        page: page + 1,
        page_size: rowsPerPage,
        user_name: filteredName,
        group_name: filteredGroup,
      })
    );
    dispatch(getManageGroup());
    setUserGroup(groups);
  }, [dispatch, rowsPerPage]);

  useEffect(() => {
    if (userActivityList?.data?.length > 0) {
      setActivityList(userActivityList?.data);
    }
    setRowsPerPage(rowsPerPage);
    setPageCount(userActivityList?.total_count);
  }, [userActivityList?.data, pageCount, activityList]);

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.message != null && apiStatus?.next === false) {
        setMessage(apiStatus?.message);
        setErr(true);
      }
    }
  }, [apiStatus]);

  useEffect(() => {
    setUserName(filterGroupData(userByGroup));
  }, [userByGroup]);

  const showAuditDetails = (value) => {
    if (value === "Data History") {
      history.push("/audit-list");
    }
  };

  const handleGroupSelection = (value) => {
    setPage(0);
    dispatch(getUsersByGroup(value));
    setFilteredGroup(value);
  };

  const handleGroupName = (value) => {
    setPage(0);
    setFilteredName(value);
  };

  const filterGroupData = (data) => {
    let arr = data?.map((group) => ({
      name: group?.username,
    }));
    return arr;
  };

  const filterByDate = (before, after, status) => {
    const formattedStartDate =
      before !== "" ? moment(before).format("YYYY-MM-DD") : "";
    const formattedEndDate =
      after !== "" ? moment(after).format("YYYY-MM-DD") : "";
    setPage(0);
    setRowsPerPage(15);
    dispatch(
      getUserActivity({
        page: page + 1,
        before: formattedEndDate,
        after: formattedStartDate,
        user_name: filteredName,
        group_name: filteredGroup,
        page_size: rowsPerPage,
      })
    );
    setActivityList(userActivityList?.data);
    setPageCount(userActivityList?.total_count);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    dispatch(
      getUserActivity({
        page_size: rowsPerPage,
        page: value + 1,
        user_name: filteredName,
        group_name: filteredGroup,
      })
    );
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const showJsonModal = () => {
    setOpen(true);
  };

  function Row(props) {
    const { list, i } = props;
    const closeModal = () => {
      setOpen(false);
    };
    return (
      <React.Fragment>
        <JsonViewerModal
          isOpen={open}
          handleClose={closeModal}
        ></JsonViewerModal>
        <Grid
          container
          className="table-data"
          sx={{
            "& > *": { borderBottom: "unset" },
            background: "white",
            borderTop: "7px solid #F8FBFF",
            padding: "2px",
            alignItems: "center",
          }}
        >
          <Grid item sx={{ paddingLeft: "10px",fontWeight:500,textAlign: "left" }} sm={3}>
            {list?.activity_name}
          </Grid>
          <Grid item sx={{ padding: "5px", fontWeight:500,textAlign: "left" }} sm={3}>
            {list?.date}
          </Grid>
          <Grid item sx={{ padding: "5px",fontWeight:500, textAlign: "left" }} sm={2}>
            {list?.user_group?.charAt(0).toUpperCase() +
              list?.user_group?.slice(1)}
          </Grid>
          <Grid item sx={{ padding: "5px",fontWeight:500, textAlign: "left" }} sm={2}>
            {list?.user_name}
          </Grid>
          <Grid item sx={{ padding: "5px",fontWeight:500, textAlign: "center" }} sm={2}>
            <IconButton onClick={() => showJsonModal()}>
              <DescriptionIcon />
            </IconButton>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <div style={{ background: "#F8FBFF", height: "calc(100vh - 130px)" }}>
      <Grid container className="action-wrap">
        <Grid sx={{ marginRight: "5px" }} item md={2}>
          <DropDownSearch
            placeholder="User Activity"
            option={activity}
            handleChange={showAuditDetails}
          />
        </Grid>
        <Grid sx={{ marginRight: "5px" }} item md={2}>
          <DropDownSearch
            placeholder="User Role"
            option={userGroup}
            handleChange={(event) => handleGroupSelection(event)}
            // handleClearClick={()=>clearSelectedValue()}
          />
        </Grid>
        <Grid sx={{ marginRight: "5px" }} item md={2}>
          <DropDownSearch
            placeholder="User Name"
            option={userName}
            handleChange={(event) => handleGroupName(event)}
            //  handleClearClick={()=>clearSelectedValue()}
          />
        </Grid>

        <Grid
          item
          md={5}
          sx={{
            margin: "3px",
            background: "#F8FBFF",
          }}
        >
          <DateRangePicker filterByDate={filterByDate} />
        </Grid>
      </Grid>
      <Box
        sx={{
          paddingLeft: 3,
          background: "#F8FBFF",
          height: "62vh",
          overflow: "scroll",
          overflowX: "hidden",
          position: "relative",
        }}
        // className={classes.tcontainer}
      >
        <Grid
          container
          className="tableHeader"
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#202020",
            padding: "5px",
            alignItems: "center",
            position: "sticky",
            top: 0,
            zIndex: 21,
          }}
        >
          <Grid sx={{ padding: "9px",  textAlign: "left" }} item xs={3}>
            Action
          </Grid>
          <Grid sx={{ padding: "9px", textAlign: "left" }} item xs={3}>
            Date & Time
          </Grid>
          <Grid sx={{ padding: "9px", textAlign: "left" }} item xs={2}>
            User Role
          </Grid>
          <Grid sx={{ padding: "9px", textAlign: "left" }} item xs={2}>
            User Name
          </Grid>
          <Grid sx={{ padding: "9px", textAlign: "center" }} item xs={2}>
            Data
          </Grid>
        </Grid>
        <Grid>
          {activityList &&
            activityList.length > 0 &&
            activityList?.map((list, i) => (
              <Row key={list?.id} list={list} i={i} props={props} />
            ))}
        </Grid>
      </Box>
      {err && (
        <CustomizedSnackbars
          severity="error"
          state={err}
          text={message}
          handleClose={() => setErr(false)}
        />
      )}
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "7px",
          background: "#F8FBFF",
        }}
      >
        <TablePagination
          component="div"
          rowsPerPageOptions={[15, 25, 50, 100]}
          count={parseInt(pageCount)}
          page={parseInt(page)}
          onPageChange={handlePageChange}
          rowsPerPage={parseInt(rowsPerPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </div>
  );
}
