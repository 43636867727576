import { Box } from "@mui/material";
import moment from "moment";
import ActionBar from "../../../components/actionbar/ActionBars";
import Header from "../../../components/header/Header";
import ProjectHeader from "../../../components/header/ProjectHeader";
import DashboardTable from "../../../components/Table/DashboardTable";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "../../../components/DataTable.scss";
import * as val from "../../../redux/validator/actions";
import { clearApiStatus } from "../../../redux/pre_processor/actions";
import ProgressBar from "../../../components/ProgressBar";
import constant from "../../../constants/ConstantValues";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";

const Index = () => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const fromState = useSelector((state) => state?.vali?.valProject);
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState("");
  const apiStatus = useSelector((state) => state?.prePro?.apiStatus)
  const projectFromState = useSelector(
    (state) => state?.vali?.valProject?.data?.validator_projects
  );
  const type = useSelector((state) => state?.prePro?.type);
  const { PROJECT_HEADER,
    PROJECT_NAME,
    BATCH_NAME,
    COUNT,
    END_DATE,
    STATUS,
    LAST_UPDATED,
    ACTION,
  } = constant

  useEffect(() => {
    setProjects(projectFromState);
  }, [type]);
  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.loading === false && apiStatus.message != null) {
        setMessage(apiStatus.message);
        setNotification(true)
      }
    }
  }, [apiStatus]);
  useEffect(() => {
    setProjects(projectFromState);
  }, [projectFromState]);

  useEffect(() => {
    dispatch(clearApiStatus());
    dispatch(
      val.getValidatorList({
        type: "",
        formattedStartDate: "",
        formattedEndDate: "",
        status: "",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (projectFromState?.length > 0) {
      setProjects(projectFromState);
    }
  }, [projectFromState]);

  const onSearch = (word, type) => {
    let result;
    let filteredProjects = [];

    if (type === "project") {
      if (word) {
        result = projectFromState?.filter(function (el) {
          return (
            el?.project_name
              .toString()
              .toLowerCase()
              .indexOf(word.toLowerCase()) !== -1
          );
        });
      } else {
        result = projectFromState;
      }
    } else {
      if (word) {
        const res = projectFromState?.map((item, index) => {
          const filteredBatches = item?.batch_info?.filter(function (el) {
            return (
              el?.batch_name
                .toString()
                .toLowerCase()
                .indexOf(word.toLowerCase()) !== -1
            );
          });
          if (filteredBatches.length > 0) {
            filteredProjects.push({
              ...projectFromState[index],
              batch_info: filteredBatches,
            });
          } else {
          }
        });
        result = filteredProjects;
      } else {
        result = projectFromState;
      }
    }
    setProjects(result);
  };

  const filterByDate = (startDate, endDate, status) => {
    const formattedStartDate =
      startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
    const formattedEndDate =
      endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";

    dispatch(
      val.getValidatorList({
        type,
        formattedStartDate,
        formattedEndDate,
        status,
      })
    );
  };

  let header = [
    PROJECT_NAME,
    BATCH_NAME,
    COUNT,
    END_DATE,
    STATUS,
    LAST_UPDATED,
    ACTION,
  ];

  return (
    <>
      <Header />
      <ActionBar
        onSearch={(value, type) => onSearch(value, type)}
        filterByDate={filterByDate}
      />
      <ProjectHeader
        text={PROJECT_HEADER}
        textColor="black"
        headerColor="white"
      />
      {fromState?.loader ? (
        <ProgressBar />
      ) : (
        <Box>
          <DashboardTable header={header} projects={projects} />
        </Box>
      )}
      {
        notification && (
          <CustomizedSnackbars
            severity="error"
            state={notification}
            text={message}
            handleClose={() => setNotification(false)} />
        )
      }
    </>
  );
};

export default Index;
