import React from "react";
import Header from "../../../components/header/Header";
import ProjectHeader from "../../../components/header/ProjectHeader";
import {
  DialogContent,
  DialogActions,
  IconButton,
  Dialog,
} from "@mui/material";
import "./mapData.scss";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MapFooter from "../../../components/Footer/MapFooter";
import { bindActionCreators } from "redux";
import * as acts from "../../../redux/validator/actions";
import { connect } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import ProgressBar from "../../../components/ProgressBar";
import ModalProcessor from "../../../components/Modal/rejectModal/ModalProcessor";
import Authentication from "../../../components/Modal/PopupModal";
import CustomizedSnackbar from "../../../components/snackbars/CustomizedSnackbars";
import DataValidationContainer from "./DataValidationContainer";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import Buttons from "../../../components/Controls/buttons/Button";
import ModalSign from "../../../components/Modal/signature/ModalSign";
import ValidatorStepper from "../../../components/Stepper/ValidatorStepper";
import CloseIcon from "@mui/icons-material/Close";
import "./mapData.scss";
import { notification } from "../../../constants/notificationMessages";

class MapData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coorData: [],
      isFullView: false,
      leftgrid: 6,
      rightgrid: 6,
      isFullScreen: true,
      hideScreen: true,
      openAuth: false,
      openSignPad: false,
      signUploaded: false,
      action: "",
      batchRejection: false,
      rejectionRemark: "",
    };
    // Create the child instance using react createRef
    this.child = React.createRef();
  }

  componentDidMount() {
    this.props.acts.clearApiStatus();
    this.props.acts.getValiData(localStorage.projectId, localStorage.batchId);
    this.props.acts.isSignatureUploaded();
    this.props.acts.validateUserClear();
    this.props.acts.clearIsSignatureUploadedStatus();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps?.entireBatchRejectedByVali &&
      nextProps?.entireBatchRejectedByVali
    ) {
      this.setState({ batchRejection: nextProps?.entireBatchRejectedByVali });
    }
    if (nextProps?.apiStatus?.loader === true) {
      this.setState({
        loader: true,
      });
    }
    if (
      nextProps?.apiStatus?.loader === false &&
      (nextProps?.apiStatus?.messages === "error" ||
        nextProps?.apiStatus?.messages === "Server Error Please Try Again...")
    ) {
      this.setState({
        loader: false,
        sucessNotification: false,
        errorNotification: true,
        message: notification.somethingWentWrong,
      });
    }
    if (
      nextProps?.apiStatus.loader === false &&
      nextProps?.apiStatus.next === false &&
      nextProps?.apiStatus.message !== "" &&
      nextProps?.apiStatus.message !== null
    ) {
      this.setState({
        // loader: false,
        sucessNotification: true,
        // errorNotification: false,
        message: notification.successMessage,
      });
    }
    if (
      nextProps?.apiStatus.loader === false &&
      nextProps?.apiStatus.next === true
    ) {
      this.setState({
        loader: false,
        sucessNotification: true,
        errorNotification: false,
        message: notification.successMessage,
      });

      setTimeout(() => {
        this.setState({
          sucessNotification: false,
          message: "",
        });
        // if (this.state.action === "saveAndNext") {
        this.props?.history?.push("/validator-dashboard");
        this.props.acts.clearApiStatus();
        // }
      }, 2000);
    }
    if (
      nextProps?.apiStatus.loader === false &&
      nextProps?.apiStatus.message != null &&
      nextProps?.apiStatus?.isError === true
    ) {
      this.setState({
        loader: false,
        sucessNotification: false,
        errorNotification: true,
        message: nextProps?.apiStatus.message,
      });
    }
    if (
      nextProps?.apiStatus?.loader === false &&
      nextProps?.apiStatus?.next === false &&
      nextProps?.apiStatus?.isRejected === true
    ) {
      this.setState({
        loader: false,
        sucessNotification: true,
        errorNotification: false,
        message: notification.successMessage,
      });

      setTimeout(() => {
        this.setState({
          sucessNotification: false,
          message: "",
        });
        //this.props?.history?.push("/validator_dashboard");
      }, 2000);
    }

    if (
      nextProps?.signUploaded?.isSignatureUploaded != "" &&
      nextProps?.signUploaded?.isSignatureUploaded === true
    ) {
      this.setState({
        signUploaded: true,
      });
    }
  }

  onClickFooterAction = (action, e) => {
    // this.setState({
    //   action: action,
    // });
    if (action === "cancel") {
      this.props.history.push("/validator-dashboard");
    } else if (action === "save") {
      // if (this?.state?.batchRejection
      //   // && this?.state?.rejectionRemark
      //   ) {
      //   // this.setState({
      //   //   batchRejection: false,
      //   //   rejectionRemark: "",
      //   // });
      //   this.child.current.mapData(
      //     "reject",
      //     this?.state?.rejectionRemark,
      //     this?.state?.batchRejection,
      //   );
      // } else this.child.current.mapData(action);
      this.child.current.mapData(action);
    } else if (action === "saveAndNext") {
      if (
        this?.state?.batchRejection
        // && this?.state?.rejectionRemark
      ) {
        // this.setState({
        //   batchRejection: false,
        //   rejectionRemark: "",
        // });
        this.child.current.mapData(
          "reject",
          this?.state?.rejectionRemark,
          this?.state?.batchRejection
          // this?.state?.batchRejection
        );
      } else if (this.state.signUploaded === true) {
        this.setState({
          openAuth: true,
        });
      } else if (this.state.signUploaded === false) {
        this.setState({
          openSignPad: true,
        });
      }
    } else if (action === "reject") {
      if (!e.target.checked) {
        this.setState({
          batchRejection: false,
          rejectionRemark: "",
        });
      } else if (e.target.checked) {
        this.setState({
          openRejectionModal: true,
        });
      }

      // for checkbox only
      // this?.state?.batchRejection && this?.state?.rejectionRemark // when checkbx is true then remove remark and set checkbox to false
      //   ? this.setState({
      //       batchRejection: false,
      //       rejectionRemark: "",
      //     })
      //   : this.setState({
      //       openRejectionModal: true,
      //     });
    }
  };

  handleCloseModal = () => {
    this.setState({
      openRejectionModal: false,
      batchRejection: false,
    });
  };

  handleReject = (remark) => {
    // this.child.current.mapData("reject", remark);
    this.setState({
      openRejectionModal: false,
      batchRejection: true,
      rejectionRemark: remark,
    });
  };

  handleCloseFullView = () => {
    this.setState({ isFullView: false });
  };

  handleOpenFullView = () => {
    this.setState({ isFullView: true });
  };
  handleCloseSignPad = () => {
    this.setState({ openSignPad: false });
    this.props.acts.isSignatureUploaded();
  };
  handleClose = () => {
    this.setState({ openAuth: false });
  };
  handleUserPermission = () => {
    this.child.current.mapData("saveAndNext"); //
  };

  slideScreentoMaximize = () => {
    this.setState({ rightgrid: 12 });
    this.setState({ isFullScreen: false });
    this.setState({ hideScreen: false });
  };
  slideScreentoMinimize = () => {
    this.setState({ leftgrid: 6 });
    this.setState({ rightgrid: 6 });
    this.setState({ isFullScreen: true });
    this.setState({ hideScreen: true });
  };

  render() {
    return (
      <div>
        <>
          <Header />
          <ValidatorStepper currentStep={3} />
          <div style={{ position: "relative" }}>
            {this.state.isFullScreen ? (
              <div className="slide-button">
                <Buttons
                  size="small"
                  onClick={() => this.slideScreentoMaximize()}
                  text="Hide Pdf"
                  startIcon={<ArrowLeftIcon />}
                />
              </div>
            ) : (
              <div className="slide-button">
                <Buttons
                  size="small"
                  onClick={() => this.slideScreentoMinimize()}
                  text="Show Pdf"
                  startIcon={<ArrowRightIcon />}
                />
              </div>
            )}
            <ProjectHeader
              text={localStorage.projectName + "-" + localStorage.batchName}
              position="center"
              headerColor="#F5F5F5"
            />
            <div
              style={{
                display: "flex",
                position: "absolute",
                right: "35px",
                color: "#000",
                top: "-1px",
              }}
            >
              <IconButton
                aria-label="fullView"
                onClick={(e) => this.handleOpenFullView()}
              >
                <FullscreenOutlinedIcon />
              </IconButton>
            </div>
          </div>
          {this?.state?.isFullView ? (
            <Dialog
              fullScreen={true}
              open={this?.state?.isFullView}
              aria-labelledby="responsive-dialog-title"
              keepMounted={true}
            >
              <DialogActions className="dialog-style">
                {this.state.isFullScreen ? (
                  <div className="slide-button">
                    <Buttons
                      size="small"
                      onClick={() => this.slideScreentoMaximize()}
                      text="Hide Pdf"
                      startIcon={<ArrowLeftIcon />}
                    />
                  </div>
                ) : (
                  <div className="slide-button">
                    <Buttons
                      size="small"
                      onClick={() => this.slideScreentoMinimize()}
                      text="Show Pdf"
                      startIcon={<ArrowRightIcon />}
                    />
                  </div>
                )}
                <IconButton
                  className="fullview-close"
                  aria-label="close"
                  onClick={this.handleCloseFullView}
                  sx={{
                    position: "inherit",
                    margin: "0px 10px",
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogActions>

              <DialogContent className="fullscreen-table-wrap">
                <DataValidationContainer
                  child={this.child}
                  isFullView={this?.state?.isFullView}
                  hideScreen={this?.state?.hideScreen}
                  leftgrid={this?.state?.leftgrid}
                  rightgrid={this?.state?.rightgrid}
                />
              </DialogContent>
            </Dialog>
          ) : (
            <DataValidationContainer
              child={this?.child}
              isFullView={this?.state?.isFullView}
              hideScreen={this?.state?.hideScreen}
              leftgrid={this?.state?.leftgrid}
              rightgrid={this?.state?.rightgrid}
            />
          )}

          <MapFooter
            pdfCount={this.props?.coorData.length}
            isDisableMultipageMappingLable={true}
            onClickFooterAction={this.onClickFooterAction}
            batchRejection={this.state.batchRejection}
          />

          {this.props?.apiStatus?.loader ? <ProgressBar /> : ""}
          {this.state.openRejectionModal && (
            <ModalProcessor
              modalOpen={this.state.openRejectionModal}
              handleClose={this.handleCloseModal}
              handleReject={this.handleReject}
              isShowClearButton={false}
              disableDeleteRejection={true}
              isShowDeleteButton={false}
              heading={"Do you want to reject the batch?"}
            />
          )}
          {this.state?.sucessNotification && (
            <CustomizedSnackbar
              severity="success"
              state={this.state?.sucessNotification}
              text={this.state?.message}
              handleClose={() =>
                this.setState({
                  sucessNotification: false,
                })
              }
            />
          )}
          {this.state?.errorNotification && (
            <CustomizedSnackbar
              severity="error"
              state={this.state?.errorNotification}
              text={this.state.message}
              handleClose={() =>
                this.setState({
                  errorNotification: false,
                })
              }
            />
          )}
          {this.state.openAuth && (
            <Authentication
              openAuth={this.state.openAuth}
              handleClose={this.handleClose}
              handleUserPermission={this.handleUserPermission}
            />
          )}
          {this.state.openSignPad && (
            <ModalSign
              openSignPad={this.state.openSignPad}
              handleEsignDialogBoxClose={this.handleCloseSignPad}
            ></ModalSign>
          )}
        </>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    acts: bindActionCreators(acts, dispatch),
  };
};

function mapStateToProps(state) {
  return {
    coorData: state.vali?.coorData?.coordinatesDetails || [],
    apiStatus: state.vali?.apiStatus,
    signUploaded: state.vali?.signUploaded,
    entireBatchRejectedByVali:
      state.vali?.coorData?.mapping_details?.entireBatchRejectedByVali,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapData);
