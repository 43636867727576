import DatePicker from "react-datepicker";
import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import Buttons from "./Controls/buttons/Button";
import "../components/Controls/dateRange/basicDateRangePicker.scss";

export default function DateRangePicker({ filterByDate }) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = React.useState("");

  return (
    <>
      <Grid container spacing={1} className="BasicDatePiker-wrap">
        <Grid item md={10}>
          <Box className="box">
            <DatePicker
              placeholderText="MM/DD/YYYY"
              className="date-picker"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
            />
            <div className="datePikerTo">To</div>
            <DatePicker
              placeholderText="MM/DD/YYYY"
              className="date-picker"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={new Date()}
            />
          </Box>
        </Grid>
        <Grid item className="admin-search" md={2}>
          <Buttons
            className="btn-search"
            text="Search"
            size="small"
            onClick={(e) => filterByDate(startDate, endDate, status)}
          />
        </Grid>
      </Grid>
    </>
  );
}
