import AxiosService from "./axiosServices";
const axiosService = new AxiosService();
const configUrl = process.env.REACT_APP_APIBASE + "/";

export default class AdministratorService {
  getHeaders = () => {
    return {
      headers: {
        "Content-Type": "Application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    };
  };

  getManageGroupList = () => {
    return axiosService.Get(
      `${configUrl}api/manage-groups/`,
      this.getHeaders()
    );
  };
  getUsersByGroup = (groupName) => {
    const data = {
      group_name: groupName,
    };
    return axiosService.Post(
      `${configUrl}api/get-user-by-group/`,
      data,
      this.getHeaders()
    );
  };
  addNewGroup = (data) => {
    return axiosService.Post(
      `${configUrl}api/manage-groups/`,
      data,
      this.getHeaders()
    );
  };
  deleteGroup = (data) => {
    return axiosService.Delete(
      `${configUrl}api/group-detail/${data}/`,
      true,
      this.getHeaders()
    );
  };
  updateGroup = (data, id) => {
    return axiosService.Put(
      `${configUrl}api/group-detail/${id}/`,
      data,
      true,
      this.getHeaders()
    );
  };

  //FOR MANAGE USER
  getUserList = () => {
    return axiosService.Get(`${configUrl}api/manage-users/`, this.getHeaders());
  };
  addNewUser = (data) => {
    return axiosService.Post(
      `${configUrl}api/manage-users/`,
      data,
      this.getHeaders()
    );
  };
  deleteUser = (data) => {
    return axiosService.Delete(
      `${configUrl}api/user-detail/${data}/`,
      true,
      this.getHeaders()
    );
  };
  editUser = (data, id) => {
    return axiosService.Put(
      `${configUrl}api/user-detail/${id}/`,
      data,
      true,
      this.getHeaders()
    );
  };
  getDeletedData = (obj) => {
    const { formattedStartDate, formattedEndDate } = obj;
    const params = {
      deleted_after: formattedStartDate,
      deleted_before: formattedEndDate,
    };
    return axiosService.Get(`${configUrl}api/get-deleted-batches/`, {
      ...this.getHeaders(),
      params: params,
    });
  };
  getManageOutput = (data) => {
    const params = {
      page_size: data?.page_size,
      page: data?.page,
    };
    return axiosService.Get(`${configUrl}api/list-output-files/`, {
      ...this.getHeaders(),
      params: params,
    });
  };
  getManageOutputUrl = (data) => {
    return axiosService.Get(
      `${configUrl}api/preview-output-file/${data}/`,
      this.getHeaders()
    );
  };
  submitOutputUpload = (id) => {
    const data = { body: { id: id } };
    return axiosService.Post(
      `${configUrl}api/approve-data-extraction/ `,
      data,
      this.getHeaders()
    );
  };

  rejectoutputFileBatch = (data) => {
    const data1 = {
      body: {
        batch_id: data.batch_id,
        projectId: data.projectId,
        rejection_remark: data.rejection_remark,
        id: data.id,
      },
    };
    return axiosService.Post(
      `${configUrl}api/reject-batch/`,
      data1,
      this.getHeaders()
    );
  };

  submitOutput = (data) => {
    return axiosService.Get(
      `${configUrl}api/approve-data-extraction/${data}/ `,
      this.getHeaders()
    );
  };
  getBatchRecordType = () => {
    return axiosService.Get(
      `${configUrl}api/list-box-dir/ `,
      this.getHeaders()
    );
  };

  uploadClientData = (data) => {
    const { folder_name, folder_id, file_type, client_file_details } =
      data.body;
    let params = { file_type: file_type };
    return axiosService.Post(
      `${configUrl}api/upload-client-data/?file_type=${file_type}`,

      data,
      this.getHeaders()
    );
  };
  getDrugTypeList = () => {
    return axiosService.Get(
      `${configUrl}api/get-drug-types/`,
      this.getHeaders()
    );
  };
  getProductTypeSubFolder = (data) => {
    return axiosService.Get(
      `${configUrl}api/list-box-dir/?folder_id=${data}`,
      this.getHeaders()
    );
  };
  getBatchDetailsList = (data) => {
    const params = {
      page_size: data?.page_size,
      page: data?.page,
    };
    return axiosService.Get(`${configUrl}api/get-batch-status/`, {
      ...this.getHeaders(),
      params: params,
    });
  };

  getBatchRecordList = (data) => {
    const params = {
      page_size: data?.page_size,
      page: data?.page,
    };
    return axiosService.Get(`${configUrl}api/list-batch-records/`, {
      ...this.getHeaders(),
      params: params,
    });
  };

  //audit trail list....
  getAuditTrailList = (data) => {
    const params = {
      page_size: data?.page_size,
      page: data?.page,
    };
    return axiosService.Get(`${configUrl}api/audit-trail-list/`, {
      ...this.getHeaders(),
      params: params,
    });
  };

  getAuditDetails = (project_id, batch_id) => {
    const params = {
      project_id: project_id,
      batch_id: batch_id,
    };
    return axiosService.Get(`${configUrl}api/get-audit-trails/`, {
      ...this.getHeaders(),
      params: params,
    });
  };

  getManageGroup = () => {
    return axiosService.Get(
      `${configUrl}api/manage-groups/`,
      this.getHeaders()
    );
  };

  getUserActivity = (data) => {
    const params = {
      page_size: data?.page_size,
      page: data?.page,
      before: data?.before,
      after: data?.after,
      user_name: data?.user_name,
      group_name: data?.group_name,
    };
    return axiosService.Get(`${configUrl}api/get-user-activities/`, {
      ...this.getHeaders(),
      params: params,
    });
  };

  getDataCalculation = (id) => {
    return axiosService.Get(`${configUrl}api/get-data-to-calculate-dp/${id}/`, {
      ...this.getHeaders(),
    });
  };

  calculateDataPoints = (data) => {
    const data1 = {
      body: {
        id: data?.id,
        capture_effort: data?.fileName,
        capture_and_copy_effort: data?.fileName1,
        underscores_used: data?.fileName2,
      },
    };
    return axiosService.Post(
      `${configUrl}api/calculate-data-points/`,
      data1,
      this.getHeaders()
    );
  };
  getDpDetails = (data) => {
    const params = {
      page_size: data?.page_size,
      page: data?.page,
      updated_after: data?.formattedStartDate,
      updated_before: data?.formattedEndDate,
      file_type: data?.file,
    };
    return axiosService.Get(`${configUrl}api/get-data-point-details/`, {
      ...this.getHeaders(),
      params: params,
    });
  };

  getMergeProjectList = () => {
    return axiosService.Get(`${configUrl}api/get-validated-projects/`, {
      ...this.getHeaders(),
    });
  };

  getMergeProjectBatches = (id) => {
    return axiosService.Get(`${configUrl}api/get-project-batches/${id}/`, {
      ...this.getHeaders(),
    });
  };

  submitFinalOpFilesData = (data) => {
    const body = [];

    data.data.map((batch) => {
      let idOfBatches = [];

      batch?.batches.map((batchDetails, i) => {
        idOfBatches = [...idOfBatches, batchDetails?.id];
      });
      body.push({
        sheet_name: batch?.projName,
        files: idOfBatches,
      });
    });

    const dataToSubmit = {
      body: body,
      master_file_name: data?.masterFileName,
    };
    return axiosService.Post(
      `${configUrl}api/merge-batch-files/`,
      dataToSubmit,
      this.getHeaders()
    );
  };

  mergePdfFiles = (data) => {
    return axiosService.Post(
      `${configUrl}api/merge-pdfs/`,
      data,
      this.getHeaders()
    );
  };
  getMergedFiles = () => {
    return axiosService.Get(`${configUrl}api/merged-files-list/`, this.getHeaders());
  };
  getVisualizationTableName = () => {

    return axiosService.Get(`${configUrl}api/list-renaming-guides/`, this.getHeaders());
  }

  submitForPostProcessing = (data) => {
  
    return axiosService.Post(`${configUrl}api/submit-for-post-processing/`, data, this.getHeaders());
  }
  
}
