import * as React from "react";
import "./DataMapTable.scss";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Grid, Box } from "@mui/material";
import MapInput from "../../mapData/MapInput";
import Buttons from "../../Controls/buttons/Button";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mapData } from "../../../redux/pre_processor/actions";
import CellMenu from "./CellMenu";
import RangeSelector from "./RangeSelector";
import DataTypeSelector from "./DataTypeSelector";
import NewParameter from "./NewParameter";
import {
  dataEntry,
  pageRedirection,
  setOCRValue,
  rejectData,
} from "../../../redux/processor/actions";
import Validation from "./Validation";
import {
  rejectValidatorData,
  validateData,
} from "../../../redux/validator/actions";
import ValidationButton from "./ValidationButton";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import AlertTitle from "@mui/material/AlertTitle";
import { Prompt } from "react-router";
import RowRejectAndReviewNew from "./RowRejectAndReviewNew";
import Label from "../../label/Label";
import EditableCell from "./EditableCell";
import { action } from "../../../constants/action";
import CellInfoMenu from "./CellInfoMenu";
import AddMoreColumnTH from "./AddMoreColumnTH";
import ParameterName from "./ParameterName";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MapDataTable = React.forwardRef((props, ref) => {
  const {
    selectedFile,
    parametersData: parametersDataFromState,
    selectedArea,
    role,
    reProcess,
    reValidate,
    isDataRejectedByValidator,
    isDataRejectedByProcessor,
    isFullView,
    resetPdfHeighlights,
    entireBatchRejectedByPro,
    entireBatchRejectedByVali,
    reProcessEntireBatch,
  } = props;

  const dispatch = useDispatch();
  const [parametersData, setParametersData] = useState(parametersDataFromState);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [cellToUpdate, setCellToUpdate] = useState({
    parameterIndex: null,
    mappingIndex: null,
  });
  const [rowRejectionData, setRowRejectionData] = useState([]);
  const [dyanamicWidth, setDyanamicWidth] = useState("100%");
  const [isAlert, setAlert] = useState(false);
  // below bindOcrValuesAndCoords is used for pro role used for cnc
  const bindOcrValuesAndCoords = useSelector(
    (state) => state?.pro?.bindOcrValuesAndCoords
  );
  const selectedFilesDataForProUpdate = useSelector(
    (state) => state?.pro?.selectedFilesDataForProUpdate
  );
  const anyOperationPerformedOnEntirePage = React.useRef(false);
  const [isUpdateParameters, setIsUpdateParameters] = useState(false);
  // const [isSaveCalled, setIsSaveCalled] = useState({
  //   isCalled: false,
  //   action: "",
  //   remark: "",
  //   batchRejection: false,
  // });

  useEffect(() => {
    window.addEventListener("beforeunload", beforeunloadFunc);

    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener("beforeunload", beforeunloadFunc);
    };
  }, []);

  const beforeunloadFunc = (e) => {
    if (anyOperationPerformedOnEntirePage.current) {
      e.preventDefault();
      e.returnValue = true;
    }
  };

  React.useImperativeHandle(ref, () => ({
    mapData(action, remark, batchRejection) {
      anyOperationPerformedOnEntirePage.current = false;
      console.log(rejectedCount);
      if (action !== "save") {
        if (rejectedCount >= 1) {
          action = "reject";
        }
      }
      // setIsSaveCalled({
      //   isCalled: true,
      //   action: action,
      //   remark: remark,
      //   batchRejection: batchRejection ? batchRejection : false,
      // });
      // },
      // }));

      // useEffect(() => {
      // if (isSaveCalled?.isCalled) {
      const next = action === "saveAndNext" ? true : false;
      const extractionType = "parameter-driven";
      if (role === "preProcessor") {
        if (action === "saveAndNext") {
          dispatch(
            mapData({
              parametersData: parametersData,
              projectId: localStorage.projectId,
              next: next,
              extractionType: extractionType,
              isUpdateParameters: isUpdateParameters,
              // ...(isDataRejectedByProcessor && {
              //   proRowRejectionData: proRowRejectionDataInPre,
              // }),
              ...(isDataRejectedByProcessor && {
                isDataRejectedByProcessor: true,
              }),
              ...(isDataRejectedByProcessor && {
                reProcess: true,
              }),
              ...(isDataRejectedByValidator && {
                isDataRejectedByValidator: true,
                // validatorRowRejectionData: valiRowRejectionDataInPro,
              }),
              entireBatchRejectedByPro: false,
              ...(entireBatchRejectedByPro && {
                reProcessEntireBatch: true,
              }),
            })
          );
        } else if (action === "save") {
          dispatch(
            mapData({
              parametersData: parametersData,
              projectId: localStorage.projectId,
              next: next,
              extractionType: extractionType,
              isUpdateParameters: isUpdateParameters,
              ...(isDataRejectedByProcessor && {
                isDataRejectedByProcessor: true,
              }),
              ...(isDataRejectedByValidator && {
                isDataRejectedByValidator: true,
              }),
              ...(entireBatchRejectedByPro && {
                entireBatchRejectedByPro: true,
              }),
            })
          );
        }
      }

      if (role === "processor") {
        if (action === "reject") {
          dispatch(
            rejectData({
              parametersData: parametersData,
              batch_id: localStorage.batchId,
              next: next,
              extractionType: extractionType,
              ...(isDataRejectedByValidator && {
                isDataRejectedByValidator: true,
              }),
              rejection_remark: remark,
              isDataRejectedByProcessor: true,
              projectId: localStorage.projectId,
              entireBatchRejectedByPro: batchRejection === true ? true : false,
              ...(entireBatchRejectedByVali && {
                entireBatchRejectedByVali: true,
              }),
            })
          );
        } else if (action === "save") {
          dispatch(
            dataEntry({
              parametersData: parametersData,
              batch_id: localStorage.batchId,
              next: next,
              extractionType: extractionType,
              ...(isDataRejectedByValidator && {
                reValidate: true,
              }),
              ...(isDataRejectedByValidator && {
                isDataRejectedByValidator: true,
              }),
              partially_validated: true,
              ...(entireBatchRejectedByVali && {
                entireBatchRejectedByVali: true,
              }),
              //  newly added
              ...(isDataRejectedByProcessor && {
                isDataRejectedByProcessor: true,
              }),
              ...(isDataRejectedByProcessor && {
                reProcess: true,
              }),
              // ...(entireBatchRejectedByPro && {
              //   entireBatchRejectedByPro: true,
              // }),
              ...(reProcessEntireBatch && {
                reProcessEntireBatch: true,
              }),
              // reProcessEntireBatch: true,
            })
          );
        } else if (action === "saveAndNext") {
          dispatch(
            dataEntry({
              parametersData: parametersData,
              batch_id: localStorage.batchId,
              next: next,
              extractionType: extractionType,
              ...(isDataRejectedByValidator && {
                reValidate: true,
              }),
              ...(isDataRejectedByValidator && {
                isDataRejectedByValidator: true,
              }),
              partially_validated: false,
              entireBatchRejectedByVali: false,
              ...(entireBatchRejectedByVali && {
                reValidateEntireBatch: true,
              }),
            })
          );
        }
      }

      if (role === "validator") {
        if (action === "reject") {
          dispatch(
            rejectValidatorData({
              parametersData: parametersData,
              batch_id: localStorage.batchId,
              next: next,
              extractionType: extractionType,
              rejection_remark: remark,
              isDataRejectedByValidator: true,
              projectId: localStorage.projectId,
              entireBatchRejectedByVali: batchRejection === true ? true : false,
            })
          );
        } else if (action === "saveAndNext") {
          let allValidatedData;
          if (parametersData) {
            allValidatedData = parametersData.every(
              (item) => item?.rowValidation?.isValidated
            );
          } else {
            allValidatedData = false;
          }

          // To add is_out_of_range flag used Validation() function which is declared for range heighlight
          if (allValidatedData) {
            let isOutOfRange = false;
            parametersData.forEach((parameter, parameterIndex) => {
              isOutOfRange = parameter?.mappings?.some(
                (mapping) =>
                  Validation(
                    parameter?.dataType,
                    parameter?.range,
                    mapping?.value
                  )?.error
              );
              parametersData[parameterIndex]["is_out_of_range"] = isOutOfRange;
            });

            dispatch(
              validateData({
                parametersData: parametersData,
                batch_id: localStorage.batchId,
                projectId: localStorage.projectId,
                next: next,
                extractionType: extractionType,
                partially_validated: false,
              })
            );
          } else {
            setAlert(true);
          }
        } else if (action === "save") {
          dispatch(
            validateData({
              parametersData: parametersData,
              batch_id: localStorage.batchId,
              projectId: localStorage.projectId,
              next: next,
              extractionType: extractionType,
              partially_validated: true,
            })
          );
        }
      }
      // }
      // return setIsSaveCalled({
      //   isCalled: false,
      //   action: "",
      //   remark: "",
      //   batchRejection: false,
      // });
      // }, [isSaveCalled?.isCalled]);
    },
  }));

  useEffect(() => {
    // This logic is used for pre pro role to add coordinates in cell wise when mapping data
    const coordinatesToAdd = selectedArea?.coords;
    const parameterIndex = cellToUpdate?.parameterIndex;
    const mappingIndex = cellToUpdate?.mappingIndex;
    if (
      selectedArea?.coords &&
      parameterIndex !== null &&
      parameterIndex >= 0 &&
      mappingIndex !== null &&
      mappingIndex >= 0 &&
      parametersData
    ) {
      parametersData[parameterIndex].mappings[mappingIndex].coordinates = [
        ...parametersData[parameterIndex].mappings[mappingIndex].coordinates,
        coordinatesToAdd,
      ];

      if (
        parametersData[parameterIndex].mappings[mappingIndex] &&
        parametersData[parameterIndex].mappings[mappingIndex]?.tagName &&
        parametersData[parameterIndex].mappings[mappingIndex]?.tagName !== "" &&
        parametersData[parameterIndex].mappings[mappingIndex]?.pageNo >= 0
      ) {
        // from be getting defficult to change payload so creating seperate array for values to show in cell menu
        parametersData[parameterIndex].mappings[
          mappingIndex
        ].coordinatesValues = parametersData[parameterIndex].mappings[
          mappingIndex
        ].coordinatesValues
          ? [
              ...parametersData[parameterIndex].mappings[mappingIndex]
                .coordinatesValues,
              selectedArea?.name,
            ]
          : [selectedArea?.name];
      }

      setParametersData([...parametersData]);
      if (!anyOperationPerformedOnEntirePage.current) {
        anyOperationPerformedOnEntirePage.current = true;
      }
    }
  }, [selectedArea]);

  // the below logic is used in pro role to acheive cnc
  useEffect(() => {
    if (bindOcrValuesAndCoords?.value && bindOcrValuesAndCoords?.coords) {
      updateCell(bindOcrValuesAndCoords);
      dispatch(setOCRValue());

      if (!anyOperationPerformedOnEntirePage.current) {
        anyOperationPerformedOnEntirePage.current = true;
      }
    }
  }, [bindOcrValuesAndCoords]);

  const updateCell = (bindOcrValuesAndCoords) => {
    const parameterIndex = cellToUpdate?.parameterIndex;
    const mappingIndex = cellToUpdate?.mappingIndex;
    if (
      bindOcrValuesAndCoords?.value &&
      bindOcrValuesAndCoords?.coords &&
      parameterIndex !== null &&
      parameterIndex >= 0 &&
      mappingIndex !== null &&
      mappingIndex >= 0 &&
      parametersData
    ) {
      var transformedHashToNumber = bindOcrValuesAndCoords?.value?.replace(
        "#",
        "Number"
      );

      if (
        parametersData[parameterIndex].mappings[mappingIndex]
        //  &&
        // parametersData[parameterIndex].mappings[mappingIndex]?.value !==
        //   undefined
      ) {
        var transformedValue =
          `${parametersData[parameterIndex].mappings[mappingIndex]?.value} ${transformedHashToNumber}`.trim();
      } else {
        transformedValue = `${transformedHashToNumber}`.trim();
      }

      parametersData[parameterIndex].mappings[mappingIndex] = {
        ...parametersData[parameterIndex].mappings[mappingIndex],
        value: transformedValue,
        coordinates:
          parametersData[parameterIndex].mappings[mappingIndex]?.coordinates &&
          parametersData[parameterIndex].mappings[mappingIndex]?.coordinates
            ?.length >= 0
            ? [
                ...parametersData[parameterIndex].mappings[mappingIndex]
                  ?.coordinates,
                bindOcrValuesAndCoords?.coords,
              ]
            : [bindOcrValuesAndCoords?.coords],

        coordinatesValues:
          parametersData[parameterIndex].mappings[mappingIndex]
            ?.coordinatesValues &&
          parametersData[parameterIndex].mappings[mappingIndex]
            ?.coordinatesValues?.length >= 0
            ? [
                ...parametersData[parameterIndex].mappings[mappingIndex]
                  ?.coordinatesValues,
                bindOcrValuesAndCoords?.value,
              ]
            : [bindOcrValuesAndCoords?.value],

        processor: localStorage.userRole,
        processedDate: moment(new Date()).format("DD-MMM-YYYY"),
      };

      setParametersData([...parametersData]);
    }
  };

  const onHandleChangeDatatype = (dataType, parameter, parameterIndex) => {
    parametersData[parameterIndex].range = { min: "", max: "" };
    parametersData[parameterIndex].dataType = dataType;
    setParametersData([...parametersData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const handleRange = (value, parameterIndex, type) => {
    parametersData[parameterIndex].range[type] = value;
    setParametersData([...parametersData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const onClickMapping = (e, parameterIndex, mappingIndex) => {
    e.preventDefault();
    setCellToUpdate({
      parameterIndex: parameterIndex,
      mappingIndex: mappingIndex,
    });

    let cellData = parametersData[parameterIndex].mappings[mappingIndex];

    if (
      cellData?.tagName &&
      cellData?.pageNo >= 0 &&
      (cellData?.tagName !== selectedFile?.tagName ||
        cellData?.pageNo !== selectedFile?.pageNo)
    ) {
      cellData = {
        ...cellData,
        coordinates: [],
        coordinatesValues: [],
        // indexesOfCoordinates: [],
        tagName: selectedFile?.tagName,
        pageNo: selectedFile?.pageNo,
        fileNo: selectedFile?.fileNo,
      };
    } else {
      cellData = {
        ...cellData,
        tagName: selectedFile?.tagName,
        pageNo: selectedFile?.pageNo,
        fileNo: selectedFile?.fileNo,
      };
      cellData["preProcessor"] = localStorage?.userName;
      cellData["preProcessedDate"] = moment(new Date()).format("DD-MMM-YYYY");
    }

    parametersData[parameterIndex].mappings[mappingIndex] = cellData;
    setParametersData([...parametersData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  useEffect(() => {
    if (isFullView) {
      calculateWidth(0);
    }
  }, []);

  const calculateWidth = (count) => {
    let Totalcount = Number(localStorage.getItem("count")) + Number(count);
    localStorage.setItem("count", Totalcount);
    let total = Totalcount * 15;
    setDyanamicWidth(100 + total + "%");
  };
  const addMappingRowCallback = (count) => {
    calculateWidth(count);
    parametersData.map((parameter, index) => {
      for (let i = 0; i < count; i++) {
        parameter?.mappings?.push({
          tagName: "",
          pageNo: "",
          fileNo: "",
          coordinates: [],
          coordinatesValues: [],
          value: "",
          // indexesOfCoordinates: [],
        });
      }
    });
    setParametersData([...parametersData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const onClickResetButton = () => {
    const emptyData = [];
    parametersData.map((parameterData) => {
      emptyData.push({
        parameter: parameterData?.parameter,
        dataType: "",
        range: { min: "", max: "" },
        mappings: [
          {
            tagName: "",
            pageNo: "",
            fileNo: "",
            value: "",
            coordinates: [],
            coordinatesValues: [],
          },
          {
            tagName: "",
            pageNo: "",
            fileNo: "",
            value: "",
            coordinates: [],
            coordinatesValues: [],
          },
          {
            tagName: "",
            pageNo: "",
            fileNo: "",
            value: "",
            coordinates: [],
            coordinatesValues: [],
          },
        ],
      });
    });
    resetPdfHeighlights();
    setParametersData([...emptyData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const rejectCell = (rejectionRemark, parameterIndex, mappingIndex) => {
    if (role === "processor") {
      parametersData[parameterIndex].mappings[mappingIndex] = {
        ...parametersData[parameterIndex].mappings[mappingIndex],
        processorCellRejection: {
          isRejected: true,
          remark: rejectionRemark,
          date: moment(new Date()).format("DD-MMM-YYYY"),
          rejectedBy: localStorage?.userName,
        },
      };
    }
    if (role === "validator") {
      parametersData[parameterIndex].mappings[mappingIndex] = {
        ...parametersData[parameterIndex].mappings[mappingIndex],
        validatorCellRejection: {
          isRejected: true,
          remark: rejectionRemark,
          date: moment(new Date()).format("DD-MMM-YYYY"),
          rejectedBy: localStorage?.userName,
        },
      };
    }

    setRejectedCount((prevState) => (prevState >= 0 ? prevState + 1 : 0)); // increament counter used this to toggle api(save / reject) based on rejection in row or cell happened or not
    setParametersData([...parametersData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const deleteRejection = (parameterIndex, mappingIndex) => {
    if (role === "processor") {
      delete parametersData[parameterIndex].mappings[mappingIndex][
        "processorCellRejection"
      ];
    } else if (role === "validator") {
      delete parametersData[parameterIndex].mappings[mappingIndex][
        "validatorCellRejection"
      ];
    }
    setRejectedCount((prevState) => (prevState > 0 ? prevState - 1 : 0)); // decrement   counter used this to toggle api(save / reject) based on rejection in row or cell happened or not
    setParametersData([...parametersData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const markAsResolveByProcessor = (parameterIndex, mappingIndex) => {
    // changed below logic pre can mark as resolve to pros rjection and pro can mark valis rejection
    if (role === "preProcessor") {
      parametersData[parameterIndex].mappings[
        mappingIndex
      ].processorCellRejection = {
        isResolved: true,
        resolvedDate: moment(new Date()).format("DD-MMM-YYYY"),
        resolvedBy: localStorage?.userName,
      };
    }

    if (role === "processor") {
      parametersData[parameterIndex].mappings[
        mappingIndex
      ].validatorCellRejection = {
        isResolved: true,
        resolvedDate: moment(new Date()).format("DD-MMM-YYYY"),
        resolvedBy: localStorage?.userName,
      };
    }

    setParametersData([...parametersData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const handleAddParameter = (newParameters) => {
    setIsUpdateParameters(true);
    // add new parameter in to old existed parametersData retrived from template
    let emptyMappingsForNewParameter = [];

    // parametersData[0]?.mappings.length.map((no) => {
    for (let i = 0; i < parametersData[0]?.mappings.length; i++) {
      emptyMappingsForNewParameter.push({
        tagName: "",
        pageNo: "",
        fileNo: "",
        coordinates: [],
        value: "",
        coordinatesValues: [],
        // indexesOfCoordinates: [],
      });
    }

    for (let i = 0; i < newParameters.length; i++) {
      parametersData.push({
        parameter: newParameters[i]?.parameter,
        dataType: "",
        range: { min: "", max: "" },
        mappings: [...emptyMappingsForNewParameter],
        sheetName: newParameters[i]?.sheetName,
      });
    }

    setParametersData([...parametersData]);

    // delete new parameter after added in to table
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  //below two functions is for row rejection in pro role
  // initially  rowRejectionData is empty but when we reject any row then only entry get added

  const handleRowRejection = (parameterIndex, remark) => {
    if (role === "processor") {
      parametersData[parameterIndex] = {
        ...parametersData[parameterIndex],
        processorRowRejection: {
          isRejected: true,
          remark: remark,
          date: moment(new Date()).format("DD-MMM-YYYY"),
          rejectedBy: localStorage?.userName,
        },
      };
    } else if (role === "validator") {
      parametersData[parameterIndex] = {
        ...parametersData[parameterIndex],
        validatorRowRejection: {
          isRejected: true,
          remark: remark,
          date: moment(new Date()).format("DD-MMM-YYYY"),
          rejectedBy: localStorage?.userName,
        },
      };
    }
    setRejectedCount((prevState) => (prevState >= 0 ? prevState + 1 : 0)); // increament counter used this to toggle api(save / reject) based on rejection in row or cell happened or not
    setParametersData([...parametersData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const deleteRowRejection = (parameterIndex) => {
    if (role === "processor") {
      parametersData[parameterIndex] = {
        ...parametersData[parameterIndex],
        processorRowRejection: {},
      };
    } else if (role === "validator") {
      parametersData[parameterIndex] = {
        ...parametersData[parameterIndex],
        validatorRowRejection: {},
      };
    }
    setRejectedCount((prevState) => (prevState > 0 ? prevState - 1 : 0)); // decrement counter used this to toggle api(save / reject) based on rejection in row or cell happened or not
    setParametersData([...parametersData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const markAsResolveRejection = (parameterIndex, index) => {
    // In Pro ROle - mark rejection did by validator
    if (role === "processor") {
      parametersData[parameterIndex].validatorRowRejection = {
        isResolved: true,
        date: moment(new Date()).format("DD-MMM-YYYY"),
        resolvedBy: localStorage?.userName,
      };
      setParametersData([...parametersData]);
    }
    if (role === "preProcessor") {
      parametersData[parameterIndex].processorRowRejection = {
        isResolved: true,
        resolvedDate: moment(new Date()).format("DD-MMM-YYYY"),
        resolvedBy: localStorage?.userName,
      };
      setParametersData([...parametersData]);
    }
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const onClickCellToRedirect = (
    e,
    tagName,
    pageNo,
    fileNo,
    parameterIndex,
    mappingIndex,
    coordinates,
    isDisabled
  ) => {
    e.preventDefault();
    if (tagName && pageNo >= 0 && fileNo >= 0) {
      dispatch(
        pageRedirection({
          fileNo,
          tagName,
          pageNo,
          coordinates,
        })
      );
    }

    if (
      isDisabled &&
      (cellToUpdate?.parameterIndex !== null ||
        cellToUpdate?.mappingIndex !== null)
    ) {
      setCellToUpdate({
        parameterIndex: null,
        mappingIndex: null,
      });
    } else if (
      isDisabled &&
      (cellToUpdate?.parameterIndex == null ||
        cellToUpdate?.mappingIndex == null)
    ) {
      setCellToUpdate({
        parameterIndex: null,
        mappingIndex: null,
      });
    } else {
      setCellToUpdate({
        parameterIndex: parameterIndex,
        mappingIndex: mappingIndex,
      });
    }
  };

  const onClickValidate = (action, parameterIndex) => {
    if (action === "validate") {
      parametersData[parameterIndex]["rowValidation"] = {
        ...parametersData[parameterIndex]["rowValidation"],
        isValidated: true,
        validator: localStorage?.userName,
        date: moment(new Date()).format("DD-MMM-YYYY HH:mm:ss"),
      };
    } else {
      parametersData[parameterIndex]["rowValidation"] = {};
    }
    setParametersData([...parametersData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setAlert(false);
    }

    setAlert(false);
  };

  const clearMappings = (parameterIndex, mappingIndex) => {
    let cellData = {
      ...parametersData[parameterIndex].mappings[mappingIndex],
      value: "",
      // updating tagName in pro only because in pre whwnever we click in to cell the new/existed tagging get applier
      // tagName: "",
      tagName:
        role === "preProcessor"
          ? ""
          : selectedFilesDataForProUpdate?.tagName // selectedFilesDataForProUpdate contains data only in pro role
          ? selectedFilesDataForProUpdate?.tagName
          : "",
      // updating pageNo in pro only because in pre whwnever we click in to cell the new/existed tagging get applier
      // pageNo: "",
      pageNo:
        role === "preProcessor"
          ? ""
          : selectedFilesDataForProUpdate?.pageNo >= 0
          ? selectedFilesDataForProUpdate?.pageNo
          : "",
      // updating fileNo in pro only because in pre whwnever we click in to cell the new/existed tagging get applier
      // fileNo: "",
      fileNo:
        role === "preProcessor"
          ? ""
          : selectedFilesDataForProUpdate?.fileNo >= 0
          ? selectedFilesDataForProUpdate?.fileNo
          : "",
      coordinates: [],
      coordinatesValues: [],
      // preProcessor: localStorage.userName,
      // preProcessedDate: moment(new Date()).format("DD-MMM-YYYY"),
      ...(role === "preProcessor" && {
        preProcessor: localStorage.userName,
        preProcessedDate: moment(new Date()).format("DD-MMM-YYYY"),
      }),
      ...(role === "processor" && {
        processor: localStorage.userName,
        processedDate: moment(new Date()).format("DD-MMM-YYYY"),
      }),
    };
    parametersData[parameterIndex].mappings[mappingIndex] = cellData;
    setParametersData([...parametersData]);
  };

  const headerAlphabetString = (num) => {
    let s = "";
    let t = null;

    while (num > 0) {
      t = (num - 1) % 26;
      s = String.fromCharCode(65 + t) + s;
      num = ((num - t) / 26) | 0;
    }
    return s || "";
  };

  const addColInBetween = (indexOfColToBeAdd, pos) => {
    // add new parameter in to old existed parametersData retrived from template
    let emptyMappingsForNewParameter = {
      tagName: "",
      pageNo: "",
      fileNo: "",
      coordinates: [],
      value: "",
      coordinatesValues: [],
      // indexesOfCoordinates: [],
    };
    const positionWhereToAdd =
      pos === "before" ? indexOfColToBeAdd : indexOfColToBeAdd + 1;

    parametersData?.map((parameter, parameterIndex) => {
      parameter?.mappings?.splice(
        positionWhereToAdd,
        0,
        emptyMappingsForNewParameter
      );
    });

    setParametersData([...parametersData]);

    // delete new parameter after added in to table
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }

    console.log(indexOfColToBeAdd, pos);
  };

  const deleteColumn = (indexOfColToBeRemove) => {
    parametersData?.map((parameter, parameterIndex) => {
      parameter?.mappings?.splice(indexOfColToBeRemove, 1);
    });

    setParametersData([...parametersData]);

    // delete new parameter after added in to table
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const onBlurParameterNameChangeInput = (value, parameterIndex) => {
    parametersData[parameterIndex].parameter = value;
    setParametersData([...parametersData]);
  };

  return (
    <Box
      className={isFullView ? "container-2" : "container-1"}
      noValidate
      autoComplete="off"
      sx={{ height: "100%" }}
    >
      <Prompt
        when={anyOperationPerformedOnEntirePage.current}
        message="Changes that you made may not be saved."
      />
      <Box className="table-grid" sx={{ height: "100%" }}>
        {/* Add Row/Col */}
        <Grid container>
          {!isFullView && role === "preProcessor" && (
            <Grid item xs={12} className="addRow-dataMap">
              <>
                <MapInput addMappingRowCallback={addMappingRowCallback} />
                <NewParameter
                  role={role}
                  handleAddParameter={handleAddParameter}
                />
                <Buttons
                  onClick={onClickResetButton}
                  className="reset-data"
                  text="Reset Data"
                  variant="outlined"
                  size="medium"
                />
              </>
            </Grid>
          )}

          {/* Add new parameters in Processor */}
          {!isFullView && role === "processor" && (
            <Grid item xs={12} className="addRow-dataMap">
              <>
                <NewParameter
                  role={role}
                  handleAddParameter={handleAddParameter}
                />
              </>
            </Grid>
          )}
        </Grid>

        {/* Paremeters Table */}
        <Grid
          container
          className={
            role === "preProcessor"
              ? "prePocessor DataMapTable-wrap"
              : "DataMapTable-wrap"
          }
        >
          {/*heading block  */}
          <Grid item className="DataMapTable" style={{ width: dyanamicWidth }}>
            <Item className="DataMap-header">
              <Label text="Parameters">Parameter</Label>
            </Item>

            <Item className="datatype-wrap">
              <Label text="Data Type">Data Type</Label>
            </Item>

            <Item className="rangeSelect-wrap">
              <Label text="Range" />
            </Item>

            {parametersData[0]?.mappings?.map((mapping, mappingIndex) => (
              <Item className="datatype-wrap">
                <AddMoreColumnTH
                  column={headerAlphabetString(mappingIndex + 1)}
                  addColInBetween={addColInBetween}
                  deleteColumn={deleteColumn}
                  showColHeadersMenu={role !== "validator"}
                  mappingIndex={mappingIndex}
                />
              </Item>
            ))}

            {/* 1st Common Checkbox in all processor n validator  */}
            {role === "processor" && (
              <Grid item>
                <Label text="Pro. Rej." padding="0px 8px"></Label>
              </Grid>
            )}
            {role === "validator" && (
              <Grid item>
                <Label text="Vali. Rej. " padding="0px 8px"></Label>
              </Grid>
            )}
            {/* 1st Checkbox in Pre processor after rejected by processor */}
            {role === "preProcessor" && isDataRejectedByProcessor && (
              <Grid item>
                <Label text="Pro. Rej." padding="0px 8px"></Label>
              </Grid>
            )}

            {/* 2nd Checkbox in processor after rejected by vali */}
            {role === "processor" && isDataRejectedByValidator && (
              <Grid item>
                <Label text="Vali. Rej." padding="0px 8px"></Label>
              </Grid>
            )}

            {/* Validation Button */}
            {role === "validator" && (
              <Grid item>
                <Label padding="16px" text="Validate">
                  Validate
                </Label>
              </Grid>
            )}
          </Grid>
          {/*End heading block  */}
          {parametersData.map((parameterData, parameterIndex) => (
            <>
              <Grid
                item
                className="DataMapTable"
                style={{ width: dyanamicWidth }}
              >
                {/* <Item className="DataMap-header">
                  {parameterData?.parameter}
                </Item> */}

                <Item>
                  <ParameterName
                    name={parameterData?.parameter}
                    onBlurParameterNameChangeInput={(value) =>
                      onBlurParameterNameChangeInput(value, parameterIndex)
                    }
                    isNameEditable={role === "processor" ? true : false}
                  />
                </Item>

                {/* 8-9 -> 1-2*/}
                <Item className="datatype-wrap">
                  <DataTypeSelector
                    parameterData={parameterData}
                    onHandleChangeDatatype={onHandleChangeDatatype}
                    parameterIndex={parameterIndex}
                    isDataRejectedByProcessor={isDataRejectedByProcessor}
                    role={role}
                    entireBatchRejectedByPro={entireBatchRejectedByPro}
                  />
                </Item>

                {/* 31-32 -> 1-2*/}
                <Item className="range-selector">
                  <RangeSelector
                    handleRange={handleRange}
                    parameterIndex={parameterIndex}
                    dataType={parameterData?.dataType}
                    selectedRange={parameterData?.range}
                    role={role}
                    entireBatchRejectedByPro={entireBatchRejectedByPro}
                  />
                </Item>

                {parameterData?.mappings?.map((mapping, mappingIndex) => (
                  <Item
                    className="datatype-wrap"
                    sx={
                      role !== "preProcessor" && {
                        // style: {
                        ...(Validation(
                          parametersData[parameterIndex]?.dataType,
                          parametersData[parameterIndex]?.range,
                          mapping?.value
                        )?.error && {
                          // css: {
                          border: "2px solid!important",
                          borderRadius: "5px!important",
                          borderColor: "#ff9800!important",
                          // },
                        }),
                        // },
                      }
                    }
                  >
                    {/* 1.25 - 1.37, 1.43 */}
                    <EditableCell
                      mapping={mapping}
                      mappingIndex={mappingIndex}
                      parameterIndex={parameterIndex}
                      parametersData={parametersData}
                      setParametersData={setParametersData}
                      rowRejectionData={rowRejectionData}
                      role={role}
                      onClickMapping={onClickMapping}
                      onClickCellToRedirect={onClickCellToRedirect}
                      anyOperationPerformedOnEntirePage={
                        anyOperationPerformedOnEntirePage
                      }
                      reProcess={reProcess}
                      isDataRejectedByProcessor={isDataRejectedByProcessor}
                      isDataRejectedByValidator={isDataRejectedByValidator}
                      reValidate={reValidate}
                      clearMappings={() =>
                        clearMappings(parameterIndex, mappingIndex)
                      } // common function used for clear mapings from cell menu and using backspace and delete buttom
                      entireBatchRejectedByPro={entireBatchRejectedByPro}
                      entireBatchRejectedByVali={entireBatchRejectedByVali}
                      reProcessEntireBatch={reProcessEntireBatch}
                    />

                    {/* {isShowCellMenu && ( */}
                    {role === "preProcessor" &&
                    !mapping?.processorCellRejection?.isRejected ? (
                      mapping?.coordinatesValues &&
                      mapping?.coordinatesValues.length > 0 && (
                        <CellInfoMenu
                          coordinatesValues={mapping?.coordinatesValues}
                          clearMappings={() =>
                            clearMappings(parameterIndex, mappingIndex)
                          }
                        />
                      )
                    ) : (
                      <>
                        <CellMenu
                          isRejectedByProcessor={
                            mapping?.processorCellRejection?.isRejected
                          }
                          processorRejectionRemark={
                            mapping?.processorCellRejection?.remark
                          }
                          isRejectedByValidator={
                            mapping?.validatorCellRejection?.isRejected
                          }
                          validatorRejectionRemark={
                            mapping?.validatorCellRejection?.remark
                          }
                          rejectCell={(rejectionRemark) =>
                            rejectCell(
                              rejectionRemark,
                              parameterIndex,
                              mappingIndex
                            )
                          }
                          deleteRejection={() =>
                            deleteRejection(parameterIndex, mappingIndex)
                          }
                          role={role}
                          markAsResolveByProcessor={() =>
                            markAsResolveByProcessor(
                              parameterIndex,
                              mappingIndex
                            )
                          }
                          isRwoValidated={
                            parameterData?.rowValidation?.isValidated
                          }
                          heading={
                            role === "preProcessor"
                              ? action.REJECTION_REMARK
                              : action.REJECTION_PROMPT
                          }
                        />
                        {mapping?.coordinatesValues &&
                          mapping?.coordinatesValues.length > 0 &&
                          role !== "validator" && (
                            <CellInfoMenu
                              coordinatesValues={mapping?.coordinatesValues}
                              clearMappings={() =>
                                clearMappings(parameterIndex, mappingIndex)
                              } // common function used for clear mapings from cell menu and using backspace and delete buttom
                            />
                          )}
                      </>
                    )}
                  </Item>
                ))}

                {/* 1st Common Checkbox in  processor n validator  */}
                {(role === "processor" || role === "validator") && (
                  <Grid item>
                    <RowRejectAndReviewNew
                      handleRowRejection={handleRowRejection}
                      parameterData={parameterData}
                      deleteRowRejection={deleteRowRejection}
                      role={role}
                      markAsResolveRejection={markAsResolveRejection}
                      parameterIndex={parameterIndex}
                      heading={"Do you want to reject the row?"}
                    />
                  </Grid>
                )}

                {/* 1st Checkbox in Pre processor after rejected by processor */}
                {role === "preProcessor" && isDataRejectedByProcessor && (
                  <Grid item xs={2}>
                    <RowRejectAndReviewNew
                      handleRowRejection={handleRowRejection}
                      parameterData={parameterData}
                      deleteRowRejection={deleteRowRejection}
                      role={role}
                      isReadOnly={true}
                      isDataRejectedByProcessor
                      markAsResolveRejection={markAsResolveRejection}
                      parameterIndex={parameterIndex}
                      heading={"Row Rejection Remark."}
                    />
                  </Grid>
                )}

                {/* 2nd Checkbox for validator rejections in processor after rejected by vali */}
                {role === "processor" && isDataRejectedByValidator && (
                  <Grid item xs={2}>
                    <RowRejectAndReviewNew
                      handleRowRejection={handleRowRejection}
                      deleteRowRejection={deleteRowRejection}
                      role={role}
                      isReadOnly={true}
                      isDataRejectedByValidator
                      markAsResolveRejection={markAsResolveRejection}
                      parameterData={parameterData}
                      parameterIndex={parameterIndex}
                      heading={"Row rejection remark."}
                    />
                  </Grid>
                )}

                {/* Validation Button */}
                {role === "validator" && (
                  <Grid item xs={1}>
                    <ValidationButton
                      onClickValidate={onClickValidate}
                      parameterData={parameterData}
                      parameterIndex={parameterIndex}
                    />
                  </Grid>
                )}
              </Grid>
            </>
          ))}
        </Grid>

        {isAlert && (
          <Snackbar
            open={true}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            key={"top" + "center"}
          >
            <Alert
              onClose={handleClose}
              className="error-alert"
              severity="error"
            >
              <AlertTitle color="white">Validation Error</AlertTitle>
              Some field are not validated. please Validate them before
              continue.
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Box>
  );
});

MapDataTable.defaultProps = {
  role: "preProcessor",
};

export default MapDataTable;
