import {
  API_STATUS,
  DELETE_PDF,
  CLEAR_API_STATUS,
  PRE_PRO_PROJECTS,
  UPLOADED_TEMPLATE_AND_PARAMETERS,
  GET_TAGS,
  UPLOADED_BATCH_PDFS,
  GET_USER_LIST,
  IS_SIGNATURE_UPLOADED_STATUS,
  ASSIGN_USER_API_STATUS,
  VALIDATE_USER_STATUS,
  UPDATE_FILTER,
  GET_UPLOADED_PDF_TAGS,
  GET_COOR_DATA,
  GET_PROJECT_TYPE_LIST,
  GET_OUTPUT_FORMAT_LIST,
  GET_PROJECT_TYPE,
  GET_SHEETS_LIST,
  AUTO_REFRESH,
} from "./types";
import preProcessorService from "../../services/preProcessorService";
import { initialState } from "./initialState";
import { errorMessage } from "../../constants/responseMessages";
import { messages } from "../../constants/commonMessages";
const service = new preProcessorService();

export const getPreProProjects = (obj) => {
  return (dispatch) => {
    !obj?.autoRefresh &&
      dispatch({
        type: PRE_PRO_PROJECTS,
        payload: { ...initialState.apiStatus, loading: true },
      });
    service
      .getProjects(obj)
      .then((response) => {
        dispatch({
          type: PRE_PRO_PROJECTS,
          payload: {
            data: response.data?.data?.preprocessor_projects,
            loading: false,
          },
        });
        !obj?.autoRefresh &&
          dispatch({
            type: API_STATUS,
            payload: { ...initialState.apiStatus, loading: false },
          });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.payload,
            loading: false,
            message: errorMessage(error),
          },
        });
      });
  };
};
export const clearApiStatus = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_API_STATUS,
      payload: {},
    });
  };
};

export const createNewProject = (projectName, drugType, next) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus, loader: true },
    });

    service
      .createNewProject({ projectName, drugType })
      .then((json) => {
        localStorage.setItem(messages.PROJECT_ID, json?.data?.data?.project_id);
        localStorage.setItem(
          messages.PROJECT_NAME,
          json?.data?.data?.project_name
        );
        dispatch({
          type: API_STATUS,
          payload: { loader: false, next: next, message: json?.data?.message },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const apiStatusClear = () => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: {},
    });
  };
};
export const validateUserClear = () => {
  return (dispatch) => {
    dispatch({
      type: VALIDATE_USER_STATUS,
      payload: {},
    });
  };
};
export const uploadFilesToServer = (data, next) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { message: "", loader: true, next: false },
    });

    service
      .uploadMultipleFilesToServer(data)
      .then((response) => {
        dispatch({
          type: API_STATUS,
          payload: {
            message: response.data.message,
            loader: false,
            next: next,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            message: error.response,
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const deletePdf = (batchId) => {
  let token = localStorage.getItem("token");
  return (dispatch) => {
    dispatch({
      type: DELETE_PDF,
      payload: {},
    });
    service.deletePdfHandle(batchId, token);
  };
};

export const createBatch = (batchName, projectId) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus, loader: true },
    });
    service
      .createBatch(batchName, projectId)
      .then((response) => {
        localStorage.setItem(messages.BATCH_ID, response?.data?.data?.batch_id);
        localStorage.setItem(
          messages.BATCH_NAME,
          response?.data?.data?.batch_name
        );

        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            loader: false,
            next: true,
            message: response.data.message,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};
export const getUserList = () => {
  return (dispatch) => {
    dispatch({
      type: GET_USER_LIST,
      payload: { data: [], loader: true, message: "" },
    });
    service
      .getUserList()
      .then((json) => {
        dispatch({
          type: GET_USER_LIST,
          payload: { data: json.data.data, loader: false, message: "" },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_USER_LIST,
          payload: { data: [], message: errorMessage(error), loader: false },
        });
      });
  };
};

export const isSignatureUploaded = () => {
  return (dispatch) => {
    dispatch({
      type: IS_SIGNATURE_UPLOADED_STATUS,
    });
    service
      .isSignatureUploaded()
      .then((json) => {
        dispatch({
          type: IS_SIGNATURE_UPLOADED_STATUS,
          payload: {
            isSignatureUploaded: json?.data?.is_signature_uploaded,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: IS_SIGNATURE_UPLOADED_STATUS,
          payload: { message: errorMessage(error) },
        });
      });
  };
};

export const clearIsSignatureUploadedStatus = () => {
  return (dispatch) => {
    dispatch({
      type: IS_SIGNATURE_UPLOADED_STATUS,
      payload: "",
    });
  };
};
export const uploadedTagClear = () => {
  return (dispatch) => {
    dispatch({
      type: UPLOADED_BATCH_PDFS,
      payload: [],
    });
  };
};
export const validateUserPermission = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus },
    });

    service
      .userPermission(data)
      .then((json) => {
        dispatch({
          type: ASSIGN_USER_API_STATUS,
          payload: json.data.message,
        });
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            loading: false,
            next: true,
            message: json.data.message,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: ASSIGN_USER_API_STATUS,
          payload: { message: "", loader: false },
        });
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            message: errorMessage(error),
            loader: false,
          },
        });
      });
  };
};
export const submitEsignBatch = (userName, userId, userRole, userPassword) => {
  return (dispatch) => {
    dispatch({
      type: VALIDATE_USER_STATUS,
      payload: { message: "", loader: true },
    });
    service
      .esignHandle(userName, userId, userRole, userPassword)
      .then((json) => {
        dispatch({
          type: VALIDATE_USER_STATUS,
          payload: { message: "", loader: false, next: true },
        });
      })
      .catch((error) => {
        dispatch({
          type: VALIDATE_USER_STATUS,
          payload: { message: errorMessage(error), loader: false },
        });
      });
  };
};

export const getUploadedTemplateAndOpFormat = (projectId) => {
  return (dispatch) => {
    dispatch({
      type: UPLOADED_TEMPLATE_AND_PARAMETERS,
      payload: [],
    });
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus, loader: true },
    });
    service
      .getUploadedTemplateAndOpFormat(projectId)
      .then((response) => {
        dispatch({
          type: API_STATUS,
          payload: { ...initialState.apiStatus, loader: false },
        });
        dispatch({
          type: UPLOADED_TEMPLATE_AND_PARAMETERS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const uploadTemplateAndOpFormat = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus, loader: true },
    });
    service
      .uploadTemplateAndOpFormat(data)
      .then((response) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            loader: false,
            next: data.next,
            message: response.data.message,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const getTags = (projectId) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus, loader: true },
    });
    service
      .getTags(projectId)
      .then((response) => {
        dispatch({
          type: API_STATUS,
          payload: { ...initialState.apiStatus, loader: false },
        });
        dispatch({
          type: GET_TAGS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: { ...initialState.apiStatus, loader: false, message: errorMessage(error) },
        });
      });
  };
};

export const uploadBatchPdfs = (
  selectedFiles,
  projectId,
  batchId,
  checked,
  next
) => {
  return (dispatch) => {
    let redirectToDashboard = true;
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus, loader: true },
    });
    dispatch({
      type: API_STATUS,
      payload: {
        ...initialState.apiStatus,
        loader: false,
        next: true,
        messages: checked ? messages?.BATCH_OCR : messages?.BATCH_NON_OCR,
      },
    });
    service
      .uploadBatchPdfs(selectedFiles, projectId, batchId, checked, next)
      .then((response) => {
        // dispatch({
        //   type: API_STATUS,
        //   payload: {
        //     ...initialState,
        //     loader: false,
        //     next: true,
        //     messages: response.data.message,
        //   },
        // });
        dispatch({
          type: AUTO_REFRESH,
          payload: { isAutoRefresh: true },
        });
      })
      .catch((error) => {
        redirectToDashboard = false;
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            loader: false,
            message: errorMessage(error),
            next: false,
          },
        });
      });
    // setTimeout(() => {
    //   redirectToDashboard &&
    //     dispatch({
    //       type: API_STATUS,
    //       payload: { ...initialState, loader: false, next: next },
    //     });
    // }, 2000);
  };
};

export const getTagsAndPdf = (projectId, batchId) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus, loader: true },
    });
    service
      .getTagsAndPdf(projectId, batchId)
      .then((response) => {
        dispatch({
          type: API_STATUS,
          payload: { ...initialState.apiStatus, loader: false },
        });
        dispatch({
          type: UPLOADED_BATCH_PDFS,
          payload: response?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            loader: false,
            message: errorMessage(error),
          },
        });
      });
  };
};

export const updateType = (type) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FILTER,
      payload: type,
    });
  };
};

export const getUploadedPdfsWithTags = (projectId) => {
  return (dispatch) => {
    dispatch({
      type: GET_UPLOADED_PDF_TAGS,
      payload: [],
    });
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus, loader: true },
    });

    service
      .getUploadedPdfsWithTags(projectId)
      .then((response) => {
        dispatch({
          type: API_STATUS,
          payload: { ...initialState.apiStatus, loader: false },
        });
        dispatch({
          type: GET_UPLOADED_PDF_TAGS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            loader: false,
            next: false,
            messages: errorMessage(error),
          },
        });
      });
  };
};

export const getCoorData = (projectId) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus, loader: true },
    });
    dispatch({
      type: GET_COOR_DATA,
      payload: [],
    });
    service
      .getCoorData(projectId)
      .then((response) => {
        dispatch({
          type: API_STATUS,
          payload: { ...initialState.apiStatus, loader: false },
        });

        dispatch({
          type: GET_COOR_DATA,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            loader: false,
            message: errorMessage(error),
          },
        });
      });
  };
};

// common action for batch and template pdf uploads
export const getS3Files = (fileType, subFolder) => {
  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_TYPE_LIST,
      payload: { data: [], loader: true, message: "" },
    });
    service
      .getS3Files(fileType, subFolder)
      .then((json) => {
        dispatch({
          type: GET_PROJECT_TYPE_LIST,
          payload: { data: json.data.data, loader: false, message: "" },
        });
      })

      .catch((error) => {
        // dispatch({
        //   type: GET_PROJECT_TYPE_LIST,
        //   payload: { ...initialState, data: [],  message: errorMessage(error), loader: false },
        // });
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            loader: false,
            message: errorMessage(error),
          },
        });
      });
  };
};

export const clearGetS3Files = () => {
  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_TYPE_LIST,
      payload: { data: [], loader: false, message: "", next: true },
    });
  };
};

export const getOutputformat = (subFolder) => {
  return (dispatch) => {
    dispatch({
      type: GET_OUTPUT_FORMAT_LIST,
      payload: { data: [], loader: true, message: "" },
    });
    service
      .getOutputFormat(subFolder)
      .then((json) => {
        dispatch({
          type: GET_OUTPUT_FORMAT_LIST,
          payload: { data: json.data.data, loader: false, message: "" },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_OUTPUT_FORMAT_LIST,
          payload: { data: [], message: errorMessage(error), loader: false },
        });
      });
  };
};
export const getDrugType = () => {
  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_TYPE,
      payload: { data: [], loader: true, message: "" },
    });
    service
      .getProjectFiles()
      .then((json) => {
        dispatch({
          type: GET_PROJECT_TYPE,
          payload: { data: json.data.data, loader: false, message: "" },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PROJECT_TYPE,
          payload: { data: [], message: errorMessage(error), loader: false },
        });
      });
  };
};
export const mapData = (data) => {
  // columnsCount used in free-form only
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus, loader: true },
    });
    service
      .mapData(data)
      .then((response) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            loader: false,
            next: data?.next,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: { data: [], message: errorMessage(error), loader: false },
        });
      });
  };
};

export const getSheetsNames = (project_id) => {
  return (dispatch) => {
    service
      .getSheetsNames(project_id)
      .then((json) => {
        dispatch({
          type: GET_SHEETS_LIST,
          payload: json.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const clearAutoRefresh = () => {
  return (dispatch) => {
    dispatch({
      type: AUTO_REFRESH,
      payload: { isAutoRefresh: false },
    });
  };
};

export const cloneProject = (projectName) => {

  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus, loading: true },
    });
    service
      .clone(projectName)
      .then((response) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            message: response.data.message, loading: false, next: true
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: { message: errorMessage(error), loader: false, next: false },
        });
      });
  };
};