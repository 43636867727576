import React, { useState, useEffect } from "react";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import { Box, Grid, IconButton } from "@mui/material";
import { useHistory } from "react-router";
import FileViewer from "react-file-viewer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./ExcelOutput.scss";
import Buttons from "../../../components/Controls/buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import * as acts from "../../../redux/administrator/actions";
import ModalProcessor from "../../../components/Modal/rejectModal/ModalProcessor";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import Progressbar from "../../../components/ProgressBar";
import { Document, Page, pdfjs } from "react-pdf";
import { messages } from "../../../constants/commonMessages"
const Index = (props) => {
  const data = props?.location?.state;
  const [isOpen, setIsOpen] = useState(false);
  const [isLoader, setIsLoader] = useState();

  const Dispatch = useDispatch();
  const history = useHistory();
  const backToList = () => {
    history.goBack();
  };

  const urlData = useSelector((state) => state?.admin?.outPutData);
  const urlResponce = useSelector((state) => state?.admin?.outPutRes);
  const outPutUrl = useSelector((state) => state?.admin?.outPutUrl);
  const outPutUpload = useSelector((state) => state?.admin.outPutUpload);
  let isOpenSnakBar = false;

  let text = "";
  let severity = "";
  if (urlResponce?.message === "submitted") {
    isOpenSnakBar = true;
    text = urlResponce?.message;
    severity = messages.SUCCESS;
  } else if (urlResponce?.message === "Rejected") {
    isOpenSnakBar = true;
    text = urlResponce?.message;
    severity = messages.SUCCESS;
  }

  const handleReject = () => {
    return setIsOpen(true);
  };
  const handleRejctAfter = (message) => {
    let data = {
      batch_id: urlData?.batch_id,
      rejection_remark: message,
    };
    Dispatch(acts.rejectExcelOutput(data));
    setIsOpen(false);
  };

  const handleSubmit = (id) => {
    //Dispatch(acts.submitOutput(urlData?.batch_id))
    Dispatch(acts.submitOutputUpload(id));
    history.push("/output-list");
  };
  useEffect(() => {
    setIsLoader(true);
    Dispatch(acts.getOutPutUrl(data?.id));

    setTimeout(() => {
      setIsLoader(false);
    }, 2000);
  }, [outPutUrl?.filePath]);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(numPages);
  }
  return (
    <>
      <Header page="new-project" />
      {isOpenSnakBar ? (
        <CustomizedSnackbars
          className="popup"
          handleClose={() => {
            isOpenSnakBar = false;
          }}
          severity={severity}
          state={isOpenSnakBar}
          text={text}
        />
      ) : (
        <></>
      )}
      <Box>
        <ModalProcessor
          modalOpen={isOpen}
          handleClose={() => setIsOpen(false)}
          handleReject={handleRejctAfter}
          id={props.location.state.id}
        />
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <Box sx={{ backgroundColor: "#F5F5F5", height: "62px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  backgroundColor: "#F5F5F5",
                  height: 60,
                  borderBottom: "1px solid #B9B9B9",
                  borderTop: "1px solid #B9B9B9",
                }}
              >
                <IconButton onClick={backToList}>
                  <ArrowBackIcon />
                </IconButton>
                <p
                  style={{
                    marginLeft: 2,
                    // fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  {data?.project_name + " / " + data?.batch_name}
                </p>
              </Box>
            </Box>
            <Box sx={{ padding: "30px", width: "100%", height: "70vh" }}>
              {isLoader ? (
                <Progressbar />
              ) : (
                <>
                  {outPutUrl?.file_type == "xlsx" || outPutUrl?.file_type == "csv" ? (
                    <FileViewer
                      style={{ height: "100vh" }}
                      fileType={outPutUrl?.file_type}
                      filePath={outPutUrl?.file_path}
                      errorComponent={"error"}
                    // onError={this.onError}
                    />
                  ) : (
                    <Box className="doc-pdf">
                      <div class="pagination">
                        <label style={{ padding: 10 }}>
                          {" "}
                          Page {pageNumber}
                        </label>
                        <button
                          onClick={() =>
                            pageNumber == 1 ? "" : setPageNumber(pageNumber - 1)
                          }
                        >
                          ❮
                        </button>
                        <button onClick={() => setPageNumber(pageNumber + 1)}>
                          ❯
                        </button>
                      </div>

                      <Document
                        file={outPutUrl?.file_path}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </Box>
                  )}
                </>
              )}
            </Box>

            <Box className="bottom-div">
              <Buttons
                variant="contained"
                size="large"
                text="Reject"
                type="submit"
                onClick={() => handleReject()}
              />
              <Buttons
                variant="contained"
                size="large"
                text="Submit"
                type="submit"
                onClick={() => handleSubmit(props.location.state.id)}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
