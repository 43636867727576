export let action = {
  DPCALCULATION:"Data Point Calculation",
  EDIT: "Edit",
  ADD_NEW_BATCH: "Add New Batch",
  CREATE_PROJECT: "Create New Project",
  E_SIGN: "E-Sign",
  LOGOUT: "Logout",
  LOGO: "CPV - Auto Batch Processing Platform",
  SEARCH: "Search..",
  PROJECT: "Project",
  BATCH: "Batch",
  UPLOAD: "Upload",
  CLEAR: "Clear",
  DRAW: "Draw Signature",
  UPLOAD: "Uploaded Signature",
  REJECTION_PROMPT: "Do you want to reject the cell?",
  REJECTION_REMARK: "Cell Rejection Remark.",
};
