import {
  Box,
  Grid,
  TextField,
  Checkbox,
  Typography,
  Card,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Buttons from "../../../components/Controls/buttons/Button";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import * as acts from "../../../redux/administrator/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import DataEntry from "../../processor/DataEntry";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FolderIcon from "@mui/icons-material/Folder";
import AddIcon from "@mui/icons-material/Add";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import ProgressBar from "../../../components/ProgressBar";
import "./index.scss";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
export default function ListDirectory() {
  const Dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state?.admin?.batchTypes);
  const productType = useSelector((state) => state?.admin?.productType);
  let folderData = useSelector((state) => state?.admin?.subFolder);
  const [subFolder, setSubFolder] = React.useState(null);
  const [nestedFolder, setNestedFolder] = React.useState(null);
  useEffect(() => {
    setSubFolder(folderData?.data);
  }, [folderData]);
  useEffect(() => {
    if (data?.upload_in_progress == true) {
      history.push("/review-batch-records-details");
    }
  }, [data]);
  const [value, setValue] = React.useState(null);
  const [folderName, setFolderName] = React.useState("");
  const [folderNameFrom, setFolderNameFormList] = React.useState("");
  const [disableAdd, setDisableAdd] = React.useState();
  const [open, setOpen] = React.useState(null);
  const [openNested, setNestedOpen] = React.useState(null);
  const apiStatus = useSelector((state) => state?.admin?.uploadData);
  console.log(apiStatus)
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(false);
  const handleClickOpen = (data, index) => {
    setSubFolder([]);
    if (index == open) {
      setOpen(-1);
    } else {
      handleSubFolder(data);
      setOpen(index);
    }
  };

  const handleSubFolder = (data) => {
    Dispatch(acts.getProductTypeSubFolder(data?.id));
  };

  const nestedSubFolderOpen = (data, index) => {
    setSubFolder([]);
    if (index == openNested) {
      setNestedOpen(-1);
    } else {
      nestedSubFolder(data);
      setNestedOpen(index);
    }
  };

  const nestedSubFolder = (data) => {
    Dispatch(acts.getProductTypeSubFolder(data?.id));
  };

  useEffect(() => {
    Dispatch(acts.getBatchRecordType());
    Dispatch(acts.getDrugTypeList());
  }, [Dispatch]);

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.message != null && apiStatus?.next === false) {
        if (apiStatus?.message === "Invalid Token") {
          setMessage(apiStatus?.message)
          setErr(true)
        }

      }
    }
  }, [apiStatus]);

  const handleClick = (data) => {
    setValue(data?.id);
  };
  const handleAdd = (data) => {
    if (folderName == "") {
      return;
    } else {
      setDisableAdd(true);
    }
  };
  const handleContine = () => {
    if (disableAdd == false) {
      return;
    }
    if (!value || !folderName) {
      return;
    } else {
      let sendData = {
        value,
        folderName,
      };
      history.push("/upload-batch", sendData);
    }
  };

  const handleChange = (event) => {
    setFolderNameFormList(event.target.value);
    setFolderName(event.target.value);
    setDisableAdd(true);
  };
  return (
    <div>
      <Header />
      <Box className="list-directory">
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid className="header-select" item md={9} sm={8}>
            <AdministratorHeader
              position="right"
              text="Batch Records"
              isShow={true}
              to="/review-batch-records-details"
            />
            <Box className="Add-batch-records">
              <TextField
                placeholder="Enter Drug Type"
                disabled={disableAdd}
                onChange={(event) => setFolderName(event.target.value)}
                id="outlined-basic"
                variant="outlined"
              />
              <Buttons
                text="Add"
                onClick={handleAdd}
                startIcon={<AddIcon />}
                size="large"
                className="add-btn"
              />

              <Box>
                <FormControl className="ProductTypeList-wrap">
                  <InputLabel
                    className="ProductTypeList"
                    id="demo-simple-select-autowidth-label"
                  >
                    DrugTypeList
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={folderNameFrom}
                    onChange={handleChange}
                    autoWidth
                  // label="Age"
                  >
                    {productType?.map((list) => (
                      <MenuItem
                        key={list}
                        value={list.name}
                        className="batch-product-type"
                      >
                        {list.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Buttons
                text="Continue"
                disabled={!value || !disableAdd}
                onClick={handleContine}
                className="btn-continue"
              />
            </Box>
            <Grid
              container
              // spacing={{ xs: 2, md: 3 }}
              // columns={{ xs: 4, sm: 8, md: 12 }}
              className="list_directory-container"
            // sx={{ overflowY: "scroll", height: " 68vh" }}
            >
              {/* <Box > */}
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  {data?.data?.map((list, index) => {
                    return (
                      <Grid
                        className="card-wrap"
                        item
                        // xs={2}
                        // sm={4}
                        // md={4}
                        key={index}
                      >
                        <Card className="main-card" key={index}>
                          <div className="card-div" id={list.id} name="card">
                            {open === index ? (
                              <IconButton>
                                <ExpandLess
                                  onClick={(event) =>
                                    handleClickOpen(list, index)
                                  }
                                />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <ExpandMore
                                  onClick={(event) =>
                                    handleClickOpen(list, index)
                                  }
                                />
                              </IconButton>
                            )}
                            <Box className="wrapperClass">
                              <FormControlLabel
                                className="formlabel"
                                value={list.id}
                                checked={list.id === value}
                                control={
                                  <Radio
                                    id={list.id}
                                    onClick={() => {
                                      handleClick(list);
                                    }}
                                  />
                                }
                              />
                              <Box className="selected">
                                <FolderIcon className="folder-icon" />
                                {/* first level folder............ */}
                                <p>{list.name}</p>
                              </Box>
                            </Box>
                          </div>
                          <Collapse
                            in={open === index}
                            key={index}
                            timeout="auto"
                            unmountOnExit
                          >
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                              >
                                {subFolder?.map((item) => {
                                  return (
                                    <Box className="sub-folder">
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {openNested === index ? (
                                          <IconButton>
                                            <ExpandLess
                                              onClick={(event) =>
                                                nestedSubFolderOpen(
                                                  item,
                                                  index
                                                )
                                              }
                                            />
                                          </IconButton>
                                        ) : (
                                          <IconButton>
                                            <ExpandMore
                                              onClick={(event) =>
                                                nestedSubFolderOpen(
                                                  item,
                                                  index
                                                )
                                              }
                                            />
                                          </IconButton>
                                        )}
                                        <FormControlLabel
                                          className="formlabel"
                                          value={item.id}
                                          checked={item.id === value}
                                          control={
                                            <Radio
                                              id={item.id}
                                              onClick={() => {
                                                handleClick(item);
                                              }}
                                            />
                                          }
                                        />
                                        <Box
                                          id={1}
                                          className="folderNTiltle-wrap"
                                        >
                                          <FolderIcon className="folder-icon" />
                                          <p className="m-0">{item.name}</p>
                                        </Box>
                                      </div>
                                      {/* <div> */}
                                      <Collapse
                                        className="nested-folder"
                                        in={openNested === index}
                                        key={index}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box className="wrapperClass">
                                          <FormControlLabel
                                            className="formlabel"
                                            value={item.id}
                                            checked={item.id === value}
                                            control={
                                              <Radio
                                                id={item.id}
                                                onClick={() => {
                                                  handleClick(item);
                                                }}
                                              />
                                            }
                                          />
                                          <Box
                                            id={1}
                                            className="folderNTiltle-wrap"
                                          >
                                            <FolderIcon className="folder-icon" />
                                            <p className="m-0">{item.name}</p>
                                          </Box>
                                        </Box>
                                      </Collapse>
                                      {/* </div> */}
                                    </Box>
                                  );
                                })}
                              </RadioGroup>
                            </FormControl>
                          </Collapse>
                        </Card>
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </FormControl>
              {folderData?.loader === true ? <ProgressBar /> : ""}
              {/* </Box> */}
            </Grid>
            {err && (
              <CustomizedSnackbars
                severity="error"
                state={err}
                text={message}
                handleClose={() => setErr(false)}
              />
            )}
            {/* <Box
             className="list-directory-FBuuton"
            >
              <Buttons
                text="Continue"
                disabled={!value || !disableAdd}
                onClick={handleContine}
                size="large"
              />
            </Box> */}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
